import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import MagnifierIcon from '../../Assets/Svg/Magnifier';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { push } from 'connected-react-router'
import {Paths} from '../../Constants/Paths';
import { connect } from 'react-redux';
import { logout } from '../../Reducers/App';
import NewsBadge from '../Partials/NewsBadge';
import {getInitialsFromString} from '../../Helpers/StringFunctions'

const StyledMenu = withStyles({
    paper: {
        marginTop:13,
        border: 'none',
        backgroundColor:'#003165',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        color:'#ffffff',
        fontSize:'14px',
        minHeight:'39px',
        '&:hover':{
            backgroundColor:'#00468E'
        },
    },
}))(MenuItem);

const styles = theme => ({
    avatar: {
        margin: 0,
        marginRight:13,
        width:56,
        height:56
    },
    link:{
        color:'rgba(71,71,71,.4)',
        fontSize:'14px',
        textDecoration:'none'
    },
});

class Header extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl:null
        };

        this.userRef                = React.createRef();
        this.handleClick            = this.handleClick.bind(this);
        this.handleSettingsClick    = this.handleSettingsClick.bind(this);
        this.handleLogoutClick      = this.handleLogoutClick.bind(this);
    }

    handleClick(event) {

        this.setState(state => ({
            anchorEl:this.userRef.current
        }));
    }

    handleClose() {
        this.setState(state => ({
            anchorEl:null
        }));
    }

    handleSettingsClick(){
        this.props.goTo(Paths.EINSTELLUNGEN);
        this.handleClose();
    }

    handleLogoutClick(){
        this.props.handleLogout();
        this.handleClose();
    }

    render() {

        const {classes,user} = this.props;
        const {anchorEl} = this.state;

        const initials = getInitialsFromString(user.name);

        return (
            <Grid container spacing={0} justifyContent="space-between" style={{marginBottom:22}}>
                <Hidden xsDown>
                    <Grid style={{display:'flex',flexGrow:'1'}}>
                        <Grid container justifyContent="flex-start" spacing={3} style={{textAlign:'left'}} alignItems="center">
                            <Grid item>
                                <a className={classes.link} target="_blank" rel="noopener noreferrer" href="https://www.progarant.de/datenschutz">Datenschutz</a>
                            </Grid>
                            <Grid item>
                                <a className={classes.link} target="_blank" rel="noopener noreferrer" href="https://www.progarant.de/impressum">Impressum</a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm="auto">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Box style={{display:'flex',cursor:'pointer'}} onClick={this.handleClick}><Avatar ref={this.userRef} className={classes.avatar} >{initials}</Avatar> <Typography style={{color:'RGBA(71,71,72,.4)',alignSelf:'center'}} variant="body2">User: {user.login}</Typography></Box>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => this.handleClose()}
                            >
                                <StyledMenuItem onClick={this.handleSettingsClick}>
                                    <ListItemText disableTypography primary="Konto verwalten" />
                                </StyledMenuItem>
                                <StyledMenuItem onClick={this.handleLogoutClick}>
                                    <ListItemText disableTypography primary="Abmelden" />
                                </StyledMenuItem>
                            </StyledMenu>
                        </Grid>
                        {1 === 2 && <Grid item>
                            <MagnifierIcon style={{margin:'0 14px',opacity:'.4'}} />
                            <NewsBadge />
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

Header.propTypes = {
    classes:PropTypes.object.isRequired,
    goTo:PropTypes.func.isRequired,
    handleLogout:PropTypes.func.isRequired,
    user:PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const {user} = state.app;
    return {
        user:user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path)),
        handleLogout: () => dispatch(logout())
    }
};

Header.propTypes = {
    classes:PropTypes.object.isRequired
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Header));
