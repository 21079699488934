import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RemoveIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M10,20 C4.5,20 0,15.5 0,10 C0,4.5 4.5,0 10,0 C15.5,0 20,4.5 20,10 C20,15.5 15.5,20 10,20 L10,20 Z M15,6.4 L13.6,5 L10,8.6 L6.4,5 L5,6.4 L8.6,10 L5,13.6 L6.4,15 L10,11.4 L13.6,15 L15,13.6 L11.4,10 L15,6.4 L15,6.4 Z" fill="#B2B2B2" transform="translate(10.000000, 10.000000) scale(1, -1) translate(-10.000000, -10.000000) " />
            </g>
        </SvgIcon>
    );
}