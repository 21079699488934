import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GoIcon(props) {
    return (
        <SvgIcon viewBox="0 0 29 32" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g  transform="translate(-1.000000, 0.000000)">
                    <path d="M1.42423267,25.6666667 L4.04085507,25.6666667 C6.57269761,28.3367969 10.1425142,30 14.0974026,30 C21.7741315,30 28,23.7334396 28,16 C28,8.26656042 21.7741315,2 14.0974026,2 C9.82293514,2 5.99828613,3.94285706 3.4477674,7 L0.947348037,7 C3.80952419,2.77474222 8.63089666,0 14.0974026,0 C22.8816282,0 30,7.16489709 30,16 C30,24.8351029 22.8816282,32 14.0974026,32 C8.9238794,32 4.32818971,29.5147173 1.42423267,25.6666667 Z"  fill="#E00B1E" fillRule="nonzero"></path>
                    <polyline  stroke="#E00B1E" strokeWidth="2" points="11.7792208 11.6666667 17.0779221 16.2912809 11.7792208 21"></polyline>
                </g>
            </g>
        </SvgIcon>
    );
}