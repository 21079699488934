import React from 'react';
import MuiButton from '@material-ui/core/Button'
import {styled} from "@material-ui/core";


const StyledButton = styled(MuiButton)({
  '&:hover':{
    background:'none'
  },
  '& .MuiButton-label':{
    textTransform:'none'
  },
  '& span':{
    textDecoration:'underline'
  }
})

class TextButton extends React.PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {children,...rest} = this.props;

    return (
      <StyledButton {...rest}>{children}</StyledButton>
    )

  }

}

export default TextButton;
