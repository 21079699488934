import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import "./Assets/Css/Styles.css"
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootSaga from './Sagas/Index'
import { Provider } from 'react-redux'
import configureStore,{ history } from './ConfigureStore'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import initReactFastclick from 'react-fastclick';
import LogRocket from 'logrocket';

/* Init FastClick */
initReactFastclick();

/* Init LogRocket */
LogRocket.init('u8grzk/progarant');

// Configure Store
const store = configureStore(undefined,process.env.REACT_APP_VERSION);

// run the saga
store.runSaga(rootSaga);

ReactDOM.render(
    <Provider store={store.store}>
        <ConnectedRouter history={history}>
            <PersistGate loading={null} persistor={store.persistor}>
                <App />
            </PersistGate>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
