import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import NewsLatest from '../Templates/News/NewsLatest';
import NewsShow from '../Templates/News/NewsShow';

class Urteil extends React.PureComponent {

    render() {
        const {newsUid} = this.props;


        return (
            <Grid container spacing={5}>
                <Grid item xs={12} lg={8}>
                    <NewsShow newsUid={newsUid} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <NewsLatest />
                </Grid>
            </Grid>
        );
    }
}

Urteil.propTypes = {
    newsUid:PropTypes.string.isRequired
};


export default Urteil;