import React from 'react';
import Grid from '@material-ui/core/Grid';
import Home from '../Templates/Home';
import NewsLatest from '../Templates/News/NewsLatest';

class Overview extends React.PureComponent {

    render() {

        return (
            <Grid container spacing={5}>
                <Grid item xs={12} lg={8}>
                    <Home />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <NewsLatest />
                </Grid>
            </Grid>
        );
    }
}

export default Overview;

