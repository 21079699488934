import React from 'react';
import PropTypes from 'prop-types';
import {Typography,Box} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import CheckIcon from '../../../Assets/Svg/Check';
import { connect } from 'react-redux';
import {getMehrzahlFromFahrzeugart} from '../../../Helpers/StringFunctions';

const styles = {
    content:{
        backgroundColor:'#ffffff',
        borderRadius:'6px',
        padding:'30px 30px 43px 30px',
    },
    steps:{
        backgroundImage:'linear-gradient(to top,rgba(216,216,216,1) 50%, rgba(255,255,255,0) 0%)',
        backgroundPosition:'bottom',
        backgroundSize:'1px 11px',
        backgroundRepeat:'repeat-y',
    },
    step:{
        paddingTop:'10px',
        paddingBottom:'10px',
        marginBottom:'20px',
        backgroundColor:'#ffffff',
        '& svg .full-circle':{
            display:'none'
        },
        '&:last-child':{
            marginBottom:0,
            paddingBottom:0
        },

    },
    stepActive:{
        color:'#00468E',
        '& svg .full-circle':{
            display:'block'
        },
        '& svg .half-circle':{
            display:'none'
        },
        '& svg .stroke-check':{
            display:'none'
        }
    },
    stepCompleted:{
        '& svg':{
            color:'#00468E'
        }
    },
    stepInactive:{
        '& svg .full-circle':{
            display:'block'
        },
        '& svg .half-circle':{
            display:'none'
        },
        '& p':{
            opacity:'.4',
        },
        '& svg':{
            opacity:'.4',
        },
        '& svg .stroke-check':{
            display:'none'
        }
    }
};



class GarantieSteps extends React.PureComponent {

    render() {

        const {classes,currentStep,fahrzeugart,garantietarif,isPlusTariff,condition} = this.props;
        const fahrzeugartMehrzahl = getMehrzahlFromFahrzeugart(fahrzeugart);

        const steps = [
            {title:'Auswahl Fahrzeugart'},
            {title:'Auswahl Garantieart'},
            {title:'Bedingungen für PKW'},
            {title:'Eingabe Garantienehmer'},
            {title:'Eingabe Fahrzeugdaten'},
            {title:'Eingabe Garantiedaten'},
            {title:'Inspektionsnachweise / weitere Dokumente'},
            {title:'Ihre Zusammenfassung'},
            {title:'Abschluss/ PDF-Dokument'},
        ];


        return (
           <Box className={classes.content} >
               <Box className={classes.steps} >
               {steps.map((item,index) => {

                   let title = (index + 1)+". "+item.title;

                   if(
                       index === 0 &&
                       currentStep >= 1 &&
                       fahrzeugart.trim() !== ""
                   ){
                       title = 'Fahrzeugart: '+fahrzeugart;
                   }

                   if(
                       index === 1 &&
                       currentStep >=2 &&
                       garantietarif.hasOwnProperty('name')
                   ){
                       title = 'Garantieart: ' + !!isPlusTariff ? garantietarif.namePlus : garantietarif.name;
                   }

                   if(
                       (
                            index === 2 &&
                            currentStep < 3 &&
                            fahrzeugart.trim() !== ""
                       ) || (
                            index === 2 &&
                            currentStep >= 3 &&
                            fahrzeugart.trim() !== "" &&
                            !condition.hasOwnProperty('name')
                       )
                   ){
                       title = (index + 1)+". Bedingungen für "+fahrzeugartMehrzahl;
                   }

                   if(
                       index === 2 &&
                       currentStep >=3 &&
                       condition.hasOwnProperty('name')
                   ){
                       title = 'Garantieart: '+condition.name;
                   }

                   return (
                   <Box className={`${classes.step}`+(currentStep === index ? ` ${classes.stepActive}` : '')+( index < currentStep  ? ` ${classes.stepCompleted}` : '')+( index > currentStep ? ` ${classes.stepInactive}` : '')} textAlign="center" key={item.title}>
                       <CheckIcon style={{marginBottom:'5px'}}/>
                       <Typography style={{lineHeight:'normal'}}>{title}</Typography>
                   </Box>
               )})}
               </Box>
           </Box>
        );
    }
}

GarantieSteps.propTypes = {
    classes:PropTypes.object.isRequired,
    currentStep:PropTypes.number.isRequired,
    fahrzeugart:PropTypes.string.isRequired,
    garantietarif:PropTypes.object.isRequired,
    isPlusTariff:PropTypes.bool.isRequired,
    condition:PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const {page} = state.form;
    const garantieart = state.app.garantie.garantieart;
    const garantietyp = state.app.garantie.garantietyp;
    const isPlusTariff = !!state.app.garantie.isPlusTariff;
    const tarife = ((!!state.app.garantie.fahrzeugart && !!state.app.garantie.tarife && state.app.garantie.tarife.hasOwnProperty(state.app.garantie.fahrzeugart)) ? state.app.garantie.tarife[state.app.garantie.fahrzeugart] : []);
    const conditions = ((!!state.app.garantie.garantieart && !!state.app.garantie.conditions && state.app.garantie.conditions.hasOwnProperty(state.app.garantie.garantieart)) ? state.app.garantie.conditions[state.app.garantie.garantieart] : []);

    const garantietarif = tarife.reduce((result, item) => {
        if (item.identifier === garantieart) { result = item }
        return result
    }, {});
    const condition = conditions.reduce((result, item) => {
        if (parseInt(item.value,10) === parseInt(garantietyp,10)) { result = item }
        return result
    }, {});


    return {
        currentStep:page,
        garantietarif:garantietarif,
        isPlusTariff:isPlusTariff,
        condition:condition,
        fahrzeugart: (!!state.app.garantie && !!state.app.garantie.fahrzeugart) ? state.app.garantie.fahrzeugart : '',
    }
}

export default connect(mapStateToProps)(withStyles(styles)(GarantieSteps));