import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '../../Helpers/DefaultTextField';
import FordwardIcon from '../../Assets/Svg/Forward';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {Field } from 'react-final-form';
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

const required = value => (value ? undefined : 'Bitte angeben');
const styles = theme => ({
    cardContent: {
        padding:'96px 54px 33px 54px'
    },
    submitButton:{
        padding:0
    }
});

const LinkButton = withStyles(theme => ({
    root: {
        '&:hover':{
            backgroundColor:'transparent'
        }
    },
    label:{
        textTransform:'none',
        textDecoration:'underline'
    }

}))(Button);

function LoginForm(props){

        const {classes,submitting,handleSubmit,submitError,onClickforget,jwToken} = props;

        useEffect(() => {
            if(jwToken){
                //document.getElementById('loginForm').dispatchEvent(new Event('submit', { cancelable: true }))
                //props.doLogin() *DOES NOT WORK*/;
                handleSubmit();
            }
        },[jwToken,handleSubmit]);

        return (
            <form id="loginForm" onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography style={{textAlign:'center',textTransform:'uppercase',marginBottom:'11px',color:'#474748'}} variant="body1">User Login</Typography>
                        <Typography color="primary" style={{textAlign:'center'}} variant="h1"><strong style={{fontWeight:700}}>Daten eingeben</strong> und loslegen</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Field
                                fullWidth
                                required
                                validate={required}
                                name="username"
                                component={TextField}
                                type="text"
                                autoComplete="username"
                                label="Kundennummer"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Field
                                fullWidth
                                required
                                validate={required}
                                name="password"
                                autoComplete="current-password"
                                component={TextField}
                                type="password"
                                label="Passwort"
                            />
                        </FormControl>
                    </Grid>
                    {submitError && <Grid item style={{textAlign:'center',color:'#ff0000'}} xs={12}>{submitError}</Grid>}
                    <Grid item style={{textAlign:'right'}} xs={12}>
                        <Button className={classes.submitButton} disableRipple color="primary" type="submit" disabled={submitting}>
                            Anmelden<FordwardIcon/>
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LinkButton rel="noopener noreferrer" target="_self" title="zurück zur Webseite" href="https://www.progarant.de/" disableRipple disabled={submitting} type="button">
                            zurück zur Webseite
                        </LinkButton>
                    </Grid>
                    <Grid item style={{textAlign:'right'}} xs={12} md={6}>
                        <LinkButton onClick={onClickforget} disableRipple disabled={submitting} type="button">
                            Passwort vergessen?
                        </LinkButton>
                    </Grid>
                </Grid>
            </form>);

}

LoginForm.propTypes = {
    classes:PropTypes.object.isRequired,
    submitError:PropTypes.string,
    handleSubmit:PropTypes.func.isRequired,
    submitting:PropTypes.bool.isRequired,
    pristine:PropTypes.bool.isRequired,
    values:PropTypes.object.isRequired,
    jwToken:PropTypes.string,
    onSubmit:PropTypes.func.isRequired,
    onClickforget:PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const {jwToken} = state.app;
    return {
        jwToken:jwToken
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(LoginForm));