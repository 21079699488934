import appSaga from './App';
import networkSaga from './Network';
import {call,spawn,all} from 'redux-saga/effects'

export default function* rootSaga() {

    const sagas = [
        appSaga,
        networkSaga
    ];

    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga)
                    break
                } catch (e) {
                    console.log(e)
                }
            }
        }))
    );

}