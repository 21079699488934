import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {Paths} from '../../Constants/Paths';


const styles = theme => ({
    cardContent: {
        padding:'96px 54px 33px 54px'
    },
    submitButton:{
        padding:0
    }
});

const LinkButton = withStyles(theme => ({
    root: {
        '&:hover':{
            backgroundColor:'transparent'
        }
    },
    label:{
        textTransform:'none',
        textDecoration:'underline'
    }

}))(Button);

class ForgetSuccess extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handleSubmit      = this.handleSubmit.bind(this);
    }

    validate = values => {
        return this.props.validate ? this.props.validate(values) : {}
    };

    handleSubmit = values => {
        this.props.handleRefresh(values.username);
    };

    onClickBack = () => {
        this.props.goTo(Paths.LOGIN);
    };


    render() {

        const {classes} = this.props;

        const label = 'Passwort vergessen ?';

        return (
            <Grid container justifyContent="center" style={{marginTop:'0px'}} spacing={0}>
                <Grid item xs={12} sm={8}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign:'center',textTransform:'uppercase',marginBottom:'11px',color:'#474748'}} variant="body1">User Login</Typography>
                                    <Typography color="primary" style={{textAlign:'center'}} variant="h1">{label}</Typography>
                                    <Typography color="primary" style={{marginTop:'2em',textAlign:'center',color:'#474748'}} >Sie erhalten in wenigen Minuten eine E-Mail mit weiteren Informationen.</Typography>
                                    <Typography color="primary" style={{marginTop:'2em',textAlign:'center',color:'#474748'}}><strong>Wichtiger Hinweis:</strong> Sollten Sie keine E-Mail erhalten, wurde Ihr Konto oder die hinterlegte E-Mail-Adresse nicht gefunden. In diesem Fall wenden Sie sich bitte direkt an die PRO GARANT Zentrale unter der Telefonnummer: 02305 9779-7</Typography>
                                </Grid>
                                <Grid item style={{textAlign:'right'}} xs={12}>
                                    <LinkButton onClick={(e) => this.onClickBack(e)} disableRipple type="button">
                                        zurück zum Login
                                    </LinkButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

ForgetSuccess.propTypes = {
    classes:PropTypes.object.isRequired,
    goTo:PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ForgetSuccess));
