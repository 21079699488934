import React from 'react';
import Grid from '@material-ui/core/Grid';
import NewsList from '../Templates/News/NewsList';
import NewsLatest from '../Templates/News/NewsLatest';

class Urteile extends React.PureComponent {

    render() {

        return (
            <Grid container spacing={5}>
                <Grid item xs={12} lg={8}>
                    <NewsList />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <NewsLatest />
                </Grid>
            </Grid>
        );
    }
}


export default Urteile;