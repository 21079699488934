import { eventChannel } from "redux-saga"
import { call, put, take } from "redux-saga/effects"
import {actions} from '../Reducers/App'

const Status = {
    ONLINE: true,
    OFFLINE: false,
};


function createConnectionChannel() {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (!connection) {
        return eventChannel(emit => {
            function onlineHandler() {
                emit(Status.ONLINE);
            }

            function offlineHandler() {
                emit(Status.OFFLINE);
            }

            window.addEventListener('online', onlineHandler);
            window.addEventListener('offline', offlineHandler);

            return () => {
                window.removeEventListener('online', onlineHandler);
                window.removeEventListener('offline', offlineHandler);
            };
        });
    }

    return eventChannel(emit => {
        let prevStatus = null;

        function handleConnectionStatusChange() {
            const status = window.navigator.onLine ? Status.ONLINE : Status.OFFLINE;

            if (status !== prevStatus) {
                emit(status);
                prevStatus = status;
            }
        }

        connection.addEventListener('change', handleConnectionStatusChange);

        return () => connection.removeEventListener('change', handleConnectionStatusChange);
    });
}

/**
 * Constructs a Saga channel that allows users
 * to be notified whenever network state changes.
 *
 * @param syncActionName
 */
function * startChannel(syncActionName) {
    const channel = createConnectionChannel();

    // Listen for the changes and put action
    while (true) {
        const connectionInfo = yield take(channel);
        yield put({type: syncActionName, status: connectionInfo }); // blocking action
    }
}


/**
 * Redux Saga that handles synchronizing a React Native
 * app with the CodePush server at configurable events.
 *
 * @param options Options to configure when to call sync.
 */
export default function* networkSaga(options = {}) {


    try {
        yield call(startChannel, actions.CONNECTION_STATUS);
    } catch (e) {
        console.log(e);
    }
}
