import React from 'react';
import Grid from '@material-ui/core/Grid';
import DownloadPdfs from '../Templates/Download/DownloadPdfs';
import NewsLatest from "../Templates/News/NewsLatest";

class GarantieUndPreislistenDownload extends React.PureComponent {

    render() {

        return (
            <Grid container spacing={5}>
                <Grid item xs={12} lg={8}>
                    <DownloadPdfs selectedFahrzeugart={this.props.match.params.fahrzeugart} downloadType={this.props.match.params.downloadType}/>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <NewsLatest />
                </Grid>
            </Grid>
        );
    }
}

export default GarantieUndPreislistenDownload;
