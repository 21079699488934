import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Downloads(props) {
    return (
        <SvgIcon viewBox="0 0 25 22" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g  transform="translate(-18.000000, -290.000000)" stroke="currentColor" strokeWidth="1.5">
                    <g  transform="translate(19.000000, 291.000000)">
                        <g >
                            <g >
                                <polyline  points="22.5 12.5 22.5 20 0 20 0 12.5"></polyline>
                                <path d="M11.25,0 L11.25,13.75" ></path>
                            </g>
                            <polyline  points="6.875 10.625 11.25 15 15.625 10.625"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};