import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge';
import BadgeIcon from '../../Assets/Svg/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {loadNews,showNews} from '../../Reducers/App';

const styles = theme => ({
    margin: {
        margin: theme.spacing(2),
    },
});

const StyledButton = withStyles({
    root: {
        padding:0,
        margin:0,
        minWidth:0,
        '&:hover':{
            backgroundColor:'transparent'
        }
    },
    label:{
        '& svg':{
            width:23,
            height:28
        }
    }
})(Button);

const StyledMenu = withStyles({
    paper: {
        marginTop:13,
        maxWidth:360,
        border: 'none',
        backgroundColor:'#003165',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        color:'#ffffff',
        fontSize:'14px',
        minHeight:'39px',
        '&:hover':{
            backgroundColor:'#00468E'
        },
    },
}))(MenuItem);

class NewsBadge extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl:null
        };

        this.userRef                = React.createRef();
        this.handleClick            = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.setState(state => ({
            anchorEl:this.userRef.current
        }));
    }

    handleClose() {
        this.setState(state => ({
            anchorEl:null
        }));
    }


    render() {

        const {classes,news,newsLoading} = this.props;
        const {anchorEl} = this.state;

        const horizontal = 'right';
        const vertical = 'top';

        return (
            <>
            <Badge
                color="primary"
                ref={this.userRef}
                badgeContent={1}
                anchorOrigin={{
                    horizontal,
                    vertical,
                }}
                className={classes.margin}
            >
                <StyledButton disableRipple disableFocusRipple onClick={this.handleClick}><BadgeIcon  style={{margin:'0 5px',marginRight:'0',opacity:'.4'}}/></StyledButton>
            </Badge>
            <StyledMenu
                id="newbadge-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => this.handleClose()}
            >
                {(newsLoading ? Array.from(new Array(3)) : news).map((item,index) => (
                    <StyledMenuItem key={index}>
                        {item && <ListItemText disableTypography primary={item.title} secondary={item.intro} />}
                    </StyledMenuItem>
                ))}
            </StyledMenu>
            </>
        );
    }
}

NewsBadge.propTypes = {
    classes:PropTypes.object.isRequired,
    news:PropTypes.array.isRequired,
    loadNews:PropTypes.func.isRequired,
    newsLoading:PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const {news,newsLoading} = state.app;

    return {
        news:news,
        newsLoading:newsLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadNews:() => dispatch(loadNews()),
        showNews:(item) => dispatch(showNews(item)),
    }
};


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(NewsBadge));

