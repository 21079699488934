import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/esm/Typography/Typography";
import Wizard from "./Wizard";
import {Field} from "react-final-form";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import TextField from '../../Helpers/DefaultTextField';
import Grid from "@material-ui/core/es/Grid/Grid";
import * as EmailValidator from 'email-validator';
import { connect } from 'react-redux'

const required = value => (value ? undefined : 'Bitte angeben');
const requiredEmail = value => (value ? ((EmailValidator.validate(value) === true ) ? undefined : "ungültige E-Mail-Adresse") : "Bitte angeben");
const passwordValidator = value => {
    let result = undefined;

    if(!value){
        result = 'Bitte angeben';
    } else if(String(value).length <= 7){
        result = 'Ihr Passwort muss mindestens 8 Zeichen lang sein';
    }else if(
        !String(value).match(new RegExp("[A-Z]")) ||
        !String(value).match(new RegExp("[a-z]"))
    ){
        result = 'Ihr Passwort muss Groß- und Kleinbuchstaben enthalten';
    }else if(
        !String(value).match(new RegExp("[0-9]"))
    ){
        result = 'Ihr Passwort muss mindestens eine Zahl enthalten';
    }else if(
        !String(value).match(new RegExp("[?!@#\$%\^\&*\)\(+=._-]+"))
    ){
        result = 'Ihr Passwort muss mindestens eines der folgenden Sonderzeichen enthalten ? ! @ # $ % ^ & * ) ( + = . _ -';
    }

    return result
};

const Error = ({ name }) => (
    <Field
        name={name}
        subscribe={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
            touched && error ? <span>{error}</span> : null
        }
    />
)

class Approve extends React.PureComponent {

    render() {
        const {is_migrated} = this.props;

        return (
            <Wizard>
                <Wizard.Page>
                    <Typography color="primary" id="transition-modal-title" style={{marginBottom:'1rem',textAlign:'center'}} variant="h2">{is_migrated ? 'Schritt 1/2: ' : ''}<strong style={{fontWeight:'700'}}>Prüfung Kontaktdaten</strong></Typography>
                    <Typography id="transition-modal-description" style={{textAlign:'center'}} variant="body1">
                        Wir freuen uns, Sie im Händlerbereich der neuen Homepage der Pro Garant GmbH zu begrüßen! Bitte prüfen Sie Ihre hinterlegten Kontaktdaten auf Vollständigkeit und ergänzen Sie fehlende Angaben. Ihre E-Mail Adresse ist zwingend erforderlich.
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item xs={12} style={{paddingBottom:0,marginTop:'20px'}}>
                            <FormControl fullWidth>
                                <Field
                                    name="name"
                                    component={TextField}
                                    type="text"
                                    required
                                    label="Firma"
                                    validate={required}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="address"
                                    component={TextField}
                                    type="text"
                                    required
                                    label="Straße, Haus-Nr."
                                    validate={required}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="postcode"
                                    component={TextField}
                                    type="text"
                                    required
                                    label="PLZ"
                                    validate={required}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="city"
                                    component={TextField}
                                    type="text"
                                    required
                                    label="Ort"
                                    validate={required}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="phone"
                                    component={TextField}
                                    type="text"
                                    label="Telefon"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="mobile"
                                    component={TextField}
                                    type="text"
                                    label="Mobilnummer"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="ustid"
                                    component={TextField}
                                    type="text"
                                    label="USt.-ID"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="email"
                                    component={TextField}
                                    type="text"
                                    required
                                    validate={requiredEmail}
                                    label="E-Mail Adresse"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="email_repeat"
                                    component={TextField}
                                    validate={requiredEmail}
                                    required
                                    type="text"
                                    label="E-Mail Adresse bestätigen"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            * Pflichtfeld
                        </Grid>
                    </Grid>
                </Wizard.Page>
                <Wizard.Page >
                    <Typography color="primary" id="transition-modal-title" style={{marginBottom:'1rem',textAlign:'center'}} variant="h2">Schritt 2/2: <strong style={{fontWeight:'700'}}>Passwort ändern</strong></Typography>
                    <Typography id="transition-modal-description" style={{textAlign:'center'}} variant="body1">
                        Aus Sicherheitsgründen bitten wir Sie, Ihr Passwort zu ändern. Ein sicheres Passwort muss mindestens acht Zeichen lang sein und muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item xs={12} style={{paddingBottom:0,marginTop:'20px'}}>
                            <FormControl fullWidth>
                                <Field
                                    name="old_passwort"
                                    type="password"
                                    required
                                    label="altes Passwort"
                                    validate={required}
                                >
                                    {props => {
                                        return <TextField {...props} />
                                    }}
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:0,marginTop:'20px'}}>
                            <FormControl fullWidth>
                                <Field
                                    name="new_passwort"
                                    component={TextField}
                                    type="password"
                                    required
                                    label="neues Passwort"
                                    validate={passwordValidator}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:0}}>
                            <FormControl fullWidth>
                                <Field
                                    name="new_passwort_repeat"
                                    component={TextField}
                                    type="password"
                                    required
                                    label="neues Passwort bestätigen"
                                    validate={required}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            * Pflichtfeld
                        </Grid>
                    </Grid>
                </Wizard.Page>
                <Wizard.Page>
                    <Typography color="primary" id="transition-modal-title" style={{marginBottom:'1rem',textAlign:'center'}} variant="h2">Vielen Dank! Ihre Daten wurden aktualisiert.</Typography>
                    <Typography id="transition-modal-description" style={{textAlign:'center'}} variant="body1">
                        Sie können jetzt das neue Händler-Tool der PRO GARANT GmbH nutzen.<br />
                        Legen Sie gleich los!
                    </Typography>
                </Wizard.Page>
            </Wizard>
        );
    }
}

Approve.propTypes = {
    is_migrated:PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        is_migrated:((!!state.app.auth.is_migrated)),
    }
}

export default connect(mapStateToProps)(Approve);