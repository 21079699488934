import React from 'react';
import {TableSortLabel,TableRow,TableHead,TableCell} from '@material-ui/core';

export default function EnhancedTableHead(props) {
    const { classes, order, orderBy,onRequestSort,headCells} = props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableHeadCell}
                    >
                        {!!headCell.sortable && <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={() => onRequestSort(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                            ) : null}
                        </TableSortLabel>}
                        {!headCell.sortable && <>{headCell.label}</>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}