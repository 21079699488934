import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MotorradIcon(props) {
    return (
        <SvgIcon viewBox="0 0 65 37" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g  transform="translate(1.000000, 1.000000)" stroke="#FFFFFF" strokeWidth="1.5">
                    <path d="M18.0776342,25.9760219 C18.0776342,30.7890493 14.1759493,34.6915973 9.3620589,34.6915973 C4.54903151,34.6915973 0.647346575,30.7890493 0.647346575,25.9760219 C0.647346575,21.1629945 4.54903151,17.2604466 9.3620589,17.2604466 C14.1759493,17.2604466 18.0776342,21.1629945 18.0776342,25.9760219 Z" ></path>
                    <path d="M62.5936068,25.9760219 C62.5936068,30.7890493 58.6919219,34.6915973 53.8780315,34.6915973 C49.0641411,34.6915973 45.1633192,30.7890493 45.1633192,25.9760219 C45.1633192,21.1629945 49.0641411,17.2604466 53.8780315,17.2604466 C58.6919219,17.2604466 62.5936068,21.1629945 62.5936068,25.9760219 Z" ></path>
                    <path d="M13.1751123,25.9077575 C13.1751123,27.9755384 11.4991397,29.652374 9.43049589,29.652374 C7.36271507,29.652374 5.68587945,27.9755384 5.68587945,25.9077575 C5.68587945,23.8399767 7.36271507,22.1631411 9.43049589,22.1631411 C11.4991397,22.1631411 13.1751123,23.8399767 13.1751123,25.9077575 Z" ></path>
                    <path d="M57.6910849,25.9077575 C57.6910849,27.9755384 56.0142493,29.652374 53.9456055,29.652374 C51.8778247,29.652374 50.200989,27.9755384 50.200989,25.9077575 C50.200989,23.8399767 51.8778247,22.1631411 53.9456055,22.1631411 C56.0142493,22.1631411 57.6910849,23.8399767 57.6910849,25.9077575 Z" ></path>
                    <path d="M17.6688247,16.7158849 L17.6688247,17.5538712 C19.6891397,19.7329808 20.9379205,22.6965699 20.9379205,25.9682548 C20.9379205,27.4068986 20.6841945,28.7808164 20.2431945,30.0615288 L35.3433452,30.0615288 L40.3591808,16.7158849 L17.6688247,16.7158849 Z" ></path>
                    <path d="M32.8533781,8.54547534 C28.0774603,8.54547534 24.2051178,11.1975164 24.2051178,14.4692014 C24.2051178,15.2649 24.4381315,16.022626 24.8532411,16.715626 L40.3590082,16.715626 L39.9257753,17.8703384 C40.914789,16.9072151 41.5007753,15.7361055 41.5007753,14.4692014 C41.5007753,11.1975164 37.6284329,8.54547534 32.8533781,8.54547534 Z" ></path>
                    <path d="M18.9793973,32.6993301 C20.2152329,30.7739466 20.9384384,28.4593438 20.9384384,25.9686863 C20.9384384,19.2794671 15.7422329,13.856289 9.33349315,13.856289 C6.76947945,13.856289 4.40568493,14.7357 2.48547945,16.2054123" ></path>
                    <path d="M20.9371438,22.5715192 L26.2481301,22.5715192" ></path>
                    <path d="M29.5167945,21.6184068 L29.5167945,25.7039137" ></path>
                    <path d="M33.2780671,21.6184068 L33.2780671,25.7039137" ></path>
                    <path d="M48.1726479,4.59658356 L47.1681,4.59658356 C45.0985932,4.59658356 43.4070863,6.28895342 43.4070863,8.35846027 L43.4070863,8.46029589 C43.4070863,10.5298027 45.0985932,12.2221726 47.1681,12.2221726 L48.1726479,12.2221726 L48.1726479,4.59658356 Z" ></path>
                    <path d="M12.9029178,13.7201918 C12.9029178,13.7201918 12.9029178,10.9965205 16.0356575,8.95376712 C16.0356575,8.95376712 17.1247808,14.9456712 24.2058082,14.4692877" ></path>
                    <path d="M33.192974,0.647260274 C33.7383986,0.647260274 38.0957548,0.783616438 38.0957548,0.783616438 L39.7294397,7.72828767" ></path>
                    <path d="M45.7218616,15.8987836 L48.581026,18.6224548" ></path>
                    <polyline  points="38.3676904 9.49867397 41.364074 6.9113589 47.4914712 14.2650986 43.8150329 17.2606192 41.5004301 14.4687699"></polyline>
                </g>
            </g>
        </SvgIcon>
    );
}