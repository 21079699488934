import React from 'react';
import PropTypes from 'prop-types';
import {Typography,Box,Grid,CardContent,Card,Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import { Form,Field } from 'react-final-form'
import TextField from '../../../Helpers/DefaultTextField';
import MakeAsyncFunction from 'react-redux-promise-listener'
import { promiseListener } from '../../../ConfigureStore';
import {actions,openUserSettingsModalWindow,closeUserSettingsModalWindow,openPWResetModalWindow,closePWResetModalWindow,confirmPWResetModalWindow} from '../../../Reducers/App';
import createDecorator from 'final-form-focus';
import FordwardIcon from '../../../Assets/Svg/Forward';
import {getInitialsFromString} from '../../../Helpers/StringFunctions'
import CancelIcon from '../../../Assets/Svg/Cancel';
import { motion } from "framer-motion"

const required = value => (value ? undefined : 'Bitte angeben');
const focusOnError = createDecorator();

const styles = {
    editButton:{
        fontSize:'16px',
        color:'#00468E',
        textTransform:'none',
        padding:0,
        textDecoration:'underline',
        '&:hover':{
            backgroundColor:'transparent',
            textDecoration:'underline',
        },
        '& .MuiButton-label':{
            textTransform:'none',
        }
    },
    avatar: {
        margin: 0,
        marginRight:13,
        width:104,
        height:104
    },
    formWrap: {
        paddingTop:'17px',
        marginTop:'27px',
        backgroundImage:'linear-gradient(to right,rgba(216,216,216,1) 33%, rgba(255,255,255,0) 0%)',
        backgroundPosition:'top',
        backgroundSize:'8px 1px',
        backgroundRepeat:'repeat-x'
    },
    submitButton:{
        padding:0,
        marginTop:'4rem'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor:'#ffffff',
        width:'580px',
        maxWidth:'90%',
        padding:'68px 114px 60px 114px',
        borderRadius:'6px',
        position:'relative',
        '&:focus':{
            outline:'none'
        }
    },
    closemodal:{
        display:'block',
        borderRadius:'100%',
        backgroundColor:'#00468E',
        position:'absolute',
        width:'50px',
        height:'50px',
        zIndex:5,
        border:0,
        right:'-25px',
        top:'-25px',
        '&:focus':{
            outline:'none'
        },
        '&:before':{
            display:'block',
            height:'2px',
            width:'27px',
            backgroundColor:'#ffffff',
            content:"''",
            position:'absolute',
            top:'25px',
            left:'12px',
            transform:'rotate(-45deg)'
        },
        '&:after':{
            display:'block',
            height:'2px',
            width:'27px',
            backgroundColor:'#ffffff',
            content:"''",
            top:'25px',
            left:'12px',
            position:'absolute',
            transform:'rotate(45deg)'
        }
    }
};

class SettingsEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalTitle:null,
            modalFieldLable:null,
            modalFieldName:null,
            modalFieldValue:null
        };

        this.handleModalClose       = this.handleModalClose.bind(this);
        this.handleEditClick        = this.handleEditClick.bind(this);
        this.handlePwResetOpen      = this.handlePwResetOpen.bind(this);
        this.handlePwResetClose     = this.handlePwResetClose.bind(this);
    }

    handlePwResetOpen(){
        this.props.openPWResetModal();
    }

    handlePwResetClose(){
        this.props.closePWResetModal();
    }

    handleModalClose() {
        this.props.closeModal();
    }

    handleEditClick(options) {
        this.setState(state => ({
            modalTitle:options.title,
            modalFieldLable:options.label,
            modalFieldName:options.name,
            modalFieldValue:options.value
        }));

        this.props.openModal();
    }

    handleSubmit = values => {

    };

    validate = values => {
        return this.props.validate ? this.props.validate(values) : {}
    };

    render() {

        const {modalTitle,modalFieldLable,modalFieldName,modalFieldValue} = this.state;
        const {values,classes,modalOpen,modalPWResetOpen,username} = this.props;

        const fields = [
            {name:'name',modalTitle:'Firmenname ändern',lable:'Firma',editable:true},
            {name:'login',lable:'Kundennummer',editable:false},
            {name:'email',modalTitle:'E-Mail-Adresse ändern',lable:'E-Mail-Adresse',editable:true},
            {name:'address',modalTitle:'Adresse ändern',lable:'Adresse',editable:true},
            {name:'postcode',modalTitle:'Postleitzahl ändern',lable:'Postleitzahl',editable:true},
            {name:'city',modalTitle:'Ort ändern',lable:'Ort',editable:true},
            {name:'phone',modalTitle:'Telefonnummer ändern',lable:'Telefon',editable:true},
            {name:'mobile',modalTitle:'Mobilnummer ändern',lable:'Mobilnummer',editable:true},
            {name:'ustid',modalTitle:'Umsatzsteuernummer ändern',lable:'USt.-ID',editable:true}
        ];

        const initials = getInitialsFromString(values.name);

        return (
            <>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box textAlign="center">
                            <Typography color="primary" style={{marginTop:20}} variant="h2">Konto verwalten</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <motion.div
                            initial={{ opacity: 0,scale:.9,y:10 }}
                            animate={{ opacity: 1,scale:1,y:0 }}
                            exit={{ opacity: 0 }}
                            transition={{delay:0 }}
                        >
                        <Card>
                            <CardContent style={{padding:'26px 54px 42px 54px'}}>
                                <Grid container justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={4}>
                                        <Avatar className={classes.avatar}>{initials}</Avatar>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Button onClick={this.handlePwResetOpen} disableRipple disableFocusRipple style={{display:'block'}} className={classes.editButton}>Passwort ändern</Button>
                                    </Grid>
                                </Grid>
                                <Box className={classes.formWrap}>
                                    <Form
                                        initialValues={values}
                                        validate={this.validate}
                                        onSubmit={this.handleSubmit}
                                    >
                                        {({ handleSubmit, submitting, values }) => (
                                            <form id="garantieForm" onSubmit={handleSubmit}>
                                                {fields.map((field,index) => (
                                                    <Grid container key={index} style={{alignItems:'center'}} >
                                                        <Grid style={{paddingLeft:'0px',paddingTop:'10px',paddingBottom:'10px',fontSize:'18px'}} item xs={12} sm={6} md={6} lg={4}>
                                                            {field.lable}:
                                                        </Grid>
                                                        <Grid style={{paddingTop:'10px',paddingBottom:'10px',fontSize:'16px'}} item xs={12} sm={6} lg={4}>
                                                            {!!values[field.name] ? values[field.name] : '-'}
                                                        </Grid>
                                                        {!!field.editable && <Grid style={{textAlign:'right',paddingTop:'12px',paddingBottom:'12px',paddingRight:'0px',fontSize:'16px'}} item xs={12} sm={12} lg={4}>
                                                            <Button onClick={(e) => this.handleEditClick({value:values[field.name],name:field.name,title:field.modalTitle,label:field.lable})} disableRipple disableFocusRipple className={classes.editButton}>bearbeiten</Button>
                                                        </Grid>}
                                                    </Grid>
                                                ))}
                                            </form>
                                        )}
                                    </Form>
                                </Box>
                            </CardContent>
                        </Card>
                        </motion.div>
                    </Grid>
                </Grid>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={modalPWResetOpen}
                    onClose={(e) => this.handlePwResetClose(e)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                >
                    <Fade in={modalPWResetOpen}>
                        <div className={classes.paper}>
                            <button className={classes.closemodal} aria-label="schließen" onClick={(e) => this.handlePwResetClose(e)} title="schließen" />
                            <Typography color="primary" id="transition-modal-title" style={{marginBottom:'1rem'}} variant="h2">Sie möchten Ihr Passwort ändern?</Typography>
                            <Typography>Klicken Sie bitte auf den Button "Passwort ändern". Sie erhalten anschließend eine E-Mail mit weiteren Instruktionen.</Typography>
                            <Grid container style={{marginTop:'1rem'}} spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <Button className={classes.cancelButton} disableRipple color="secondary" type="button" onClick={this.handlePwResetClose}>
                                        <CancelIcon/>Abbrechen
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button disableRipple color="primary" onClick={(e) => this.props.confirmPWReset(username)} type="submit">Passwort ändern<FordwardIcon/></Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={modalOpen}
                    onClose={(e) => this.handleModalClose(e)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                >
                    <Fade in={modalOpen}>
                        <div className={classes.paper}>
                            <button className={classes.closemodal} aria-label="schließen" onClick={(e) => this.handleModalClose(e)} title="schließen" />
                            {modalTitle && <Typography color="primary" id="transition-modal-title" style={{marginBottom:'1rem'}} variant="h2">{modalTitle}</Typography>}
                            <MakeAsyncFunction
                                listener={promiseListener}
                                start={actions.UPDATE_USER}
                                resolve={actions.UPDATE_USER_SUCCESS}
                                reject={actions.UPDATE_USER_ERROR}
                            >
                                {onSubmit => (
                                    <Form
                                        validate={this.validate}
                                        onSubmit={onSubmit}
                                        decorators={[focusOnError]}
                                        initialValues={{[modalFieldName]:modalFieldValue}}
                                    >
                                        {({submitError,handleSubmit, submitting,pristine, values }) => (
                                            <form id="userEditForm" onSubmit={handleSubmit}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name={modalFieldName}
                                                                component={TextField}
                                                                type="text"
                                                                label={modalFieldLable}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container justifyContent="space-between" alignItems="flex-end" alignContent="space-between">
                                                            {submitError && <Grid item style={{color:'#ff0000'}} xs={6}>{submitError}</Grid>}
                                                            <Grid item style={{textAlign:'right',marginLeft:'auto'}}>
                                                                <Button className={classes.submitButton} disableRipple color="primary" type="submit" disabled={submitting}>
                                                                    Speichern<FordwardIcon/>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    </Form>
                                )}
                            </MakeAsyncFunction>
                        </div>
                    </Fade>
                </Modal>
            </>
        );
    }

}

SettingsEdit.propTypes = {
    classes:PropTypes.object.isRequired,
    values:PropTypes.object.isRequired,
    modalOpen:PropTypes.bool.isRequired,
    openModal:PropTypes.func.isRequired,
    closeModal:PropTypes.func.isRequired,
    modalPWResetOpen:PropTypes.bool.isRequired,
    openPWResetModal:PropTypes.func.isRequired,
    closePWResetModal:PropTypes.func.isRequired,
    confirmPWReset:PropTypes.func.isRequired,
    username:PropTypes.string.isRequired
};

function mapStateToProps(state) {
    const {user,userEditModalWindow,modalPWResetOpen} = state.app;
    return {
        values:user,
        username:user.login,
        modalOpen:userEditModalWindow,
        modalPWResetOpen:modalPWResetOpen
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openModal:() => dispatch(openUserSettingsModalWindow()),
        closeModal:() => dispatch(closeUserSettingsModalWindow()),
        openPWResetModal:() => dispatch(openPWResetModalWindow()),
        closePWResetModal:() => dispatch(closePWResetModalWindow()),
        confirmPWReset:(username) => dispatch(confirmPWResetModalWindow(username))
    }
};


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SettingsEdit));
