import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles'
import GoIcon from '../../../Assets/Svg/Go';
import CalendarIcon from '../../../Assets/Svg/Calendar';
import {loadNews,showNews} from '../../../Reducers/App';
import { connect } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import {format,parseISO } from 'date-fns';
import { motion } from "framer-motion"


const LinkButton = withStyles(theme => ({
    root: {
        padding:0,
        marginTop:'20px',
        '&:hover':{
            backgroundColor:'transparent'
        }
    },
    label:{
        textTransform:'uppercase',
        textDecoration:'none',
        display:'block',
        padding:0,
        fontSize:14,
        '&:hover':{
            backgroundColor:'transparent'
        },
        color:'#00468E',
        '& svg':{
            verticalAlign:'middle',
            margin:0,
            width:50,
            height:30,

        }
    }

}))(Button);

const styles = {
    newsContent:{
        backgroundColor:'#ffffff',
        borderRadius:'6px',
        padding:'20px 30px 20px 30px'
    },
    newsItem:{
        paddingBottom:'24px',
        marginBottom:'24px',
        backgroundImage:'linear-gradient(to right,rgba(216,216,216,1) 33%, rgba(255,255,255,0) 0%)',
        backgroundPosition:'bottom',
        backgroundSize:'8px 1px',
        backgroundRepeat:'repeat-x'
    },
    newsLink:{

    },
};


class NewsList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    componentDidMount(){
        //if(!this.props.news.length){
            this.props.loadNews();
        //}
    }

    handleItemClick(item){
        this.props.showNews(item);
    }

    render() {

        const {classes,news,newsLoading} = this.props;

        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <Typography color="primary" style={{marginTop:20}} variant="h2">Allgemeine Infos</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <motion.div
                        initial={{ opacity: 0,scale:1,y:0 }}
                        animate={{ opacity: 1,scale:1,y:0 }}
                        exit={{ opacity: 0 }}
                        transition={{delay:0}}
                    >
                    <Box className={classes.newsContent}>
                        {(newsLoading ? Array.from(new Array(3)) : news).map((item,index) => {

                            let newsdate = null;

                            if(
                                !!item &&
                                !!item.newsdate &&
                                !!parseISO(item.newsdate.date)
                            ){
                                newsdate = parseISO(item.newsdate.date);
                            }

                            return (
                                <Box className={classes.newsItem} key={!!item && item.uid ? item.uid : index}>
                                    {item && item.uid ? (<Typography style={{marginBottom:'9px'}} color="primary" variant="h4">{item.title}</Typography>) : (<Skeleton style={{marginBottom:'9px'}} width="80%" height={21} />)}
                                    {item && item.uid ? (<Typography variant="body2"><CalendarIcon style={{width:'19px',height:'26px',verticalAlign:'middle',marginRight:'10px',marginTop:'-4px'}} />{!!newsdate && newsdate instanceof Date ? format(newsdate,'dd.MM.yyyy') : ''}</Typography>) : (<Skeleton width="50%" height={21} />)}
                                    {item && item.uid ? (<Typography style={{marginTop:'18px'}} variant="body1">{item.intro}</Typography>) : (<Skeleton style={{marginTop:'18px'}} width="100%" height={78} />)}
                                    {item ? (<LinkButton onClick={(e) => this.handleItemClick(item)} disableRipple disableFocusRipple >Weiterlesen<GoIcon/></LinkButton>) : (<Skeleton style={{marginTop:'20px'}} width={90} height={17} />)}
                                </Box>
                            )})}
                    </Box>
                    </motion.div>
                </Grid>
            </Grid>

        );
    }
}


NewsList.propTypes = {
    classes:PropTypes.object.isRequired,
    news:PropTypes.array.isRequired,
    loadNews:PropTypes.func.isRequired,
    newsLoading:PropTypes.bool.isRequired,
    showNews:PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const {news,newsLoading} = state.app;

    return {
        news:news,
        newsLoading:newsLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadNews:() => dispatch(loadNews()),
        showNews:(item) => dispatch(showNews(item)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(NewsList));
