export const Paths = {
    HOME:'/',
    GARANTIE_ADD:'/garantie/',
    GARANTIE_LISTE:'/garantieliste/',
    DOWNLOADS:'/downloads/',
    PREISLISTENPDFS:'/preislistenpdfs/',
    GARANTIEBEDINGUNGENPDFS:'/garantiebedingungenpdfs/',
    GARANTIEUNDPREISLISTEN_DOWNLOADS:'/garantiebundpreislistenDownloads/:downloadType/:fahrzeugart',
    URTEILE:'/urteile/',
    KONTAKT:'/kontakt/',
    EINSTELLUNGEN:'/einstellungen/',
    INFO:'/haendlerinformation/',
    LOGIN:'/login/',
    FORGET:'/forget/',
    FORGET_SUCCESS:'/forget/success',
    RESET:'/reset/',
    RESET_SUCCESS:'/reset/success',
    URTEIL:'/urteil/',
    KUNDENPORTAL:'/kundenportal/',
    KUNDENPORTAL_LOGIN: '/kundenportal/login/',
    VERTRAGSDETAILS: '/kundenportal/vertragsdetails/'
};
