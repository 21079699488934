//import { REHYDRATE } from 'redux-persist/lib/constants';
import getApiInstance from '../Api/Api';
import getNewsApiInstance from '../Api/NewsApi';
import { FORM_ERROR } from 'final-form';
import {actions as formAction} from './Form';

export const actions = {
    INIT:'app/INIT',
    GOTO:'app/GOTO',
    LOGOUT:'app/LOGOUT',
    LOGOUT_SUCCESS:'app/LOGOUT/SUCCESS',
    LOGIN:'app/LOGIN',
    CONNECTION_STATUS:'app/CONNECTION_STATUS',
    JWTOKEN_RECEIVE:'app/JWTOKEN/RECEIVE',
    LOGIN_REFRESH:'app/LOGIN/REFRESH',
    LOGIN_REFRESH_STARTED:'app/LOGIN/REFRESH/STARTED',
    LOGIN_REFRESH_STOPED:'app/LOGIN/REFRESH/STOPED',
    LOGIN_SUCCESS:'app/LOGIN/SUCCESS',
    LOGIN_ERROR:'app/LOGIN/ERROR',
    SPEEDDIAL_OPEN:'app/SPEEDDIAL/OPEN',
    SPEEDDIAL_CLOSE:'app/SPEEDDIAL/CLOSE',
    NEWS_LOAD:'app/NEWS/LOAD',
    NEWS_LOAD_SUCCESS:'app/NEWS/LOAD/SUCCESS',
    NEWS_LOAD_ERROR:'app/NEWS/LOAD/ERROR',
    NEWS_SHOW:'app/NEWS/SHOW',
    NEWS_SHOW_ERROR:'app/NEWS/SHOW/ERROR',
    NEWS_SINGLE_LOAD:'app/NEWS/SINGLE/LOAD',
    NEWS_SINGLE_LOAD_SUCCESS:'app/NEWS/SINGLE/LOAD/SUCCESS',
    NEWS_SINGLE_LOAD_ERROR:'app/NEWS/SINGLE/LOAD/ERROR',
    LOAD_GARANTIE:'garantie/LOAD',
    LOAD_GARANTIE_SUCCESS:'garantie/LOAD/SUCCESS',
    LOAD_GARANTIE_ERROR:'garantie/LOAD/ERROR',
    LOAD_PDF_FROM_PATH:'pdf/LOAD_FROM_PATH',
    LOAD_PDF_FROM_PATH_SUCCESS:'pdf/LOAD_FROM_PATH/SUCCESS',
    LOAD_PDF_FROM_PATH_ERROR:'pdf/LOAD_FROM_PATH/ERROR',
    OPEN_PDF_FROM_PATH:'pdf/OPEN_FROM_PATH',
    OPEN_PDF_FROM_PATH_SUCCESS:'pdf/OPEN_FROM_PATH/SUCCESS',
    OPEN_PDF_FROM_PATH_ERROR:'pdf/OPEN_FROM_PATH/ERROR',
    LOAD_TARIFE:'tarife/LOAD',
    LOAD_TARIFE_SUCCESS:'tarife/LOAD/SUCCESS',
    LOAD_TARIFE_ERROR:'tarife/LOAD/ERROR',
    CREATE_GARANTIE:'garantie/CREATE',
    CREATE_GARANTIE_SUCCESS:'garantie/CREATE/SUCCESS',
    CREATE_GARANTIE_ERROR:'garantie/CREATE/ERROR',
    LOAD_CONDITION:'condition/LOAD',
    LOAD_CONDITION_ERROR:'condition/LOAD/ERROR',
    LOAD_CONDITION_SUCCESS:'condition/LOAD/SUCCESS',
    PW_REFRESH:'app/PW/REFRESH',
    PW_REFRESH_SUCCESS:'app/PW/REFRESH/SUCCESS',
    PW_REFRESH_ERROR:'app/PW/REFRESH/ERROR',
    LOAD_USER_HV:'user/HV/LOAD',
    LOAD_USER_HV_SUCCESS:'user/HV/LOAD/SUCCESS',
    LOAD_USER_HV_ERROR:'user/HV/LOAD/ERROR',
    HIDE_FIRST_LOGIN_WINDOW:'app/FIRST_LOGIN/MODAL/HIDE',
    LOAD_HV_AUSWERTUNGEN:'hv/auswertungen/LOAD',
    LOAD_HV_AUSWERTUNGEN_ERROR:'hv/auswertungen/ERROR',
    LOAD_HV_AUSWERTUNGEN_SUCCESS:'hv/auswertungen/SUCCESS',
    LOAD_USER_INFO:'user/info/LOAD',
    LOAD_USER_INFO_ERROR:'user/info/LOAD/ERROR',
    LOAD_USER_INFO_SUCCESS:'user/info/LOAD/SUCCESS',
    UPDATE_USER:'user/UPDATE',
    UPDATE_USER_SUCCESS:'user/UPDATE/SUCCESS',
    UPDATE_USER_ERROR:'user/UPDATE/ERROR',
    MODAL_USER_SETTINGS_OPEN:'user/settings/MODAL/OPEN',
    MODAL_USER_SETTINGS_CLOSE:'user/settings/MODAL/CLOSE',
    GARANTIE_PROMISE_START:'garantie/PROMISE/START',
    GARANTIE_PROMISE_RESOLVE:'garantie/PROMISE/RESOLVE',
    GARANTIE_PROMISE_REJECT:'garantie/PROMISE/REJECT',
    GARANTIE_WIZARD_SHOW:'garantie/WIZARD/SHOW',
    LOAD_CUSTOMER_VALIDATION_RULES:'customer/rules/LOAD',
    LOAD_CUSTOMER_VALIDATION_RULES_ERROR:'customer/rules/ERROR',
    LOAD_CUSTOMER_VALIDATION_RULES_SUCCESS:'customer/rules/SUCCESS',
    LOAD_VEHICLE_VALIDATION_RULES:'vehicle/rules/LOAD',
    LOAD_VEHICLE_VALIDATION_RULES_ERROR:'vehicle/rules/ERROR',
    LOAD_VEHICLE_VALIDATION_RULES_SUCCESS:'vehicle/rules/SUCCESS',
    LOAD_SUMMARY:'garantie/summary/LOAD',
    LOAD_SUMMARY_ERROR:'garantie/summary/ERROR',
    LOAD_SUMMARY_SUCCESS:'garantie/summary/SUCCESS',
    SUBMIT_GARANTIE:'garantie/create/SUBMIT',
    SUBMIT_GARANTIE_ERROR:'garantie/create/ERROR',
    SUBMIT_GARANTIE_SUCCESS:'garantie/create/SUCCESS',
    CREATE_VEHICLE:'vehicle/create',
    CREATE_VEHICLE_ERROR:'vehicle/create/ERROR',
    CREATE_VEHICLE_SUCCESS:'vehicle/create/SUCCESS',
    LOAD_GARANTIE_VALIDATION_RULES:'garantie/rules/LOAD',
    LOAD_GARANTIE_VALIDATION_RULES_ERROR:'garantie/rules/ERROR',
    LOAD_GARANTIE_VALIDATION_RULES_SUCCESS:'garantie/rules/SUCCESS',
    CONTACT_SUBMIT:'app/CONTACT/SUBMIT',
    CONTACT_SUBMIT_RESOLVE:'app/CONTACT/SUBMIT/RESOLVE',
    CONTACT_SUBMIT_REJECT:'app/CONTACT/SUBMIT/REJECT',
    CONTACT_BACK_TO_FROM:'app/CONTACT/BACK_TO_FORM',
    NETWORK_CHANGE:'app/NETWORK/CHANGE',
    PW_RESET:'app/PW/RESET',
    PW_RESET_RESOLVE:'app/PW/RESET/RESOLSVE',
    PW_RESET_REJECT:'app/PW/RESET/RESOLSVE',
    MODAL_USER_PWRESET_OPEN:'user/pwreset/MODAL/OPEN',
    MODAL_USER_PWRESET_CLOSE:'user/pwreset/MODAL/CLOSE',
    MODAL_USER_PWRESET_CONFIRM:'user/pwreset/MODAL/CONFIRM',
    LOAD_DOWNLOAD:'downloads/LOAD',
    LOAD_DOWNLOAD_SUCCESS:'downloads/LOAD/SUCCESS',
    LOAD_DOWNLOAD_ERROR:'downloads/LOAD/ERROR',
    LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN:'preislisten/LOAD',
    LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN_SUCCESS:'preislisten/LOAD/SUCCESS',
    LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN_ERROR:'preislisten/LOAD/ERROR',
    AUTH_UNAUTHORIZED:'auth/unauthorized',
    APPROVE_PROMISE_START:'approve/PROMISE/START',
    APPROVE_PROMISE_RESOLVE:'approve/PROMISE/RESOLVE',
    APPROVE_PROMISE_REJECT:'approve/PROMISE/REJECT',
    APPROVE_TO:'newApprove/TO',
    APPROVE_COMPLETED:'newApprove/COMPLETED',
    LOGIN_KUNDENPORTAL:'kundenportal/LOGIN',
    LOGIN_KUNDENPORTAL_SUCCESS: 'kundenportal/LOGIN/SUCCESS',
    LOGIN_KUNDENPORTAL_ERROR: 'kundenportal/LOGIN/ERROR',
    LOAD_KUNDENPORTAL_GARANTIE: 'kundenportal/GARANTIE',
    LOAD_KUNDENPORTAL_GARANTIE_SUCCESS: 'kundenportal/GARANTIE/SUCCESS',
    LOAD_KUNDENPORTAL_GARANTIE_ERROR:'kundenportal/GARANTIE/ERROR',
    LOAD_KUNDENPORTAL_GARANTIE_PDF: 'kundenportal/PDF/GARANTIE',
    LOAD_KUNDENPORTAL_GARANTIE_PDF_SUCCESS: 'kundenportal/GARANTIE/PDF/SUCCESS',
    LOAD_KUNDENPORTAL_GARANTIE_PDF_ERROR: 'kundenportal/GARANTIE/PDF/ERROR',
    CHECK_EMAIL:'kundenportal/email',
    CHECK_EMAIL_SUCCESS: 'kundenportal/EMAIL/SUCCESS',
    CHECK_EMAIL_ERROR: 'kundenportal/EMAIL/ERROR',
    KUNDENPORTALGARANTIEID:'kundenportal/GARANTIEID',
    KUNDENPORTALEMAIL: 'kundenportal/EMAIL',
    FAHRZEUGART_TARIFE_LOAD: 'fahrzeugart/tarife/LOAD',
    FAHRZEUGART_TARIFE_LOAD_SUCCESS: 'fahrzeugart/tarife/LOAD/SUCCESS',
};

const defaultRegex = /default:([^|]*)/gmi;

export const sections = {
    SEARCH: 'Section/SUCHE',
    POS: 'Section/POS',
    FILTER: 'Section/FILTER',
    WARENGRUPPEN: 'Section/WARENGRUPPEN',
    TYPEN: 'Section/TYPEN',
    TYPE: 'Section/TYPE',
    DOWNLOADS: 'Section/DOWNLOADS',
};

export const initialState = {
    version:null,
    speedDialOpen:false,
    isAuthenticated: false,
    lastAuthentification:null,
    networkStatus:null,
    isOnline:true,
    apiBaseURL:'https://www.progarant.de/webgar/api',
    apiNewsBaseURL:'https://www.progarant.de/rest',
    apiTimeout:30000,
    news:[],
    downloads:[],
    currentNews:null,
    contactFormSubmited:false,
    newsLoading:false,
    downloadsLoading:false,
    preislistenUndGarantiebedingungen:{},
    preislistenUndGarantiebedingungenLoading:false,
    fahrzeugartSelected:null,
    fahrzeugartTarife:{},
    tarifeLoading:false,
    conditionLoading:false,
    garantieListLoading:false,
    singleNewsLoading:false,
    userEditModalWindow:false,
    modalPWResetOpen:false,
    showRelogin:false,
    jwToken:null,
    kundenportalGarantieId:null,
    kundenportalEmail:null,
    kundenportalEmailSuccess:false,
    kundenportalEmailErrorMessage: false,
    kundenportalLoginErrorMessage: false,
    kundenportalIsAuthenticated:false,
    kundenportalLoginString:null,
    kundenportalOtp:null,
    kundenportalJwToken:false,
    kundenportalGarantieLoaded: false,
    kundenportalGarantieError:false,
    kundenportalGarantie:{
        garnr: null,
        vendorLogin: null,
        vendorName: null,
        customerTitle: null,
        customerName: null,
        customerBirthday: null,
        customerStreet: null,
        customerPostcode: null,
        customerCity: null,
        customerTelephone: null,
        customerMail: null,
        condition: null,
        manufacturer: null,
        model: null,
        kw: null,
        ps: null,
        ccm: null,
        vehicleNumber: null,
        hsn: null,
        tsn: null,
        zylinderAnzahl: null,
        fuel: null,
        turbo: null,
        allrad: null,
        km: null,
        gesamtlaufleistung: null,
        erstzulassung: null,
        platenumber: null,
        price: null,
        inspectionProof: null,
        verkaufsdatum: null,
        wiederzulassung: null,
        endWerksgarantieDate: null,
        freeOfCharge: true,
        laufzeit: null,
        mobilitaetsgarantie: null,
        alternativantrieb: null,
        plusTariff: null,
        remarks: null,
    },
    auth:{
        login:null,
        status:null,
        api_token:null,
        token_type:null,
        expires_in:null,
        is_admin: false,
        is_hv:false,
        edit_garantie_id:null,
        login_after_migration: false,
        account_approved:false,
        is_migrated:false,
        hv_info:null,
        is_deactivated:false,
    },
    user:{
        status:null,
        login:null,
        name:null,
        email:null,
        mobile:null,
        phone:null,
        address:null,
        postcode:null,
        city:null,
        ustid:null,
        hv_info:null,
    },
    hv_auswertungen:[],
    garantien:[],
    garantie:{
        fahrzeugart:null,
        garantieart:"",
        isPlusTariff:false,
        tarifTypAccept:false,
        garantietyp:null,
        garantie:{},
        tarife:{},
        customerValidationRules:{},
        vehicleValidationRules:{},
        garantieValidationRules:{},
        conditions:{},
        customer:{},
        inspektionsnachweise:{},
        garantiedaten:{},
        remarks:'',
        gar_nr:null,
        pdf:{},
        chargedImpacts:{},
        inspektionscheckliste:false,
        uebergabeprotokoll:false
    },
    showFirstLoginWindow:false,
    ansprechpartner:null
};

getApiInstance({
    baseURL:initialState.apiBaseURL,
    timeout:initialState.apiTimeout,
});

getNewsApiInstance({
    baseURL:initialState.apiNewsBaseURL,
    timeout:initialState.apiTimeout,
});

export default function App (state = initialState, action = {}){

    switch (action.type) {
        case actions.CHECK_EMAIL:
            return Object.assign({}, state, {
                kundenportalGarantieId: action.payload.kundenportalGarantieId,
                kundenportalEmail: action.payload.kundenportalEmail
            });
        case actions.CHECK_EMAIL_SUCCESS:
            return Object.assign({}, state, {
                kundenportalEmailSuccess: true,
                kundenportalEmailErrorMessage: false,
                kundenportalLoginString: action.payload.kundenportalLoginString
            });
        case actions.CHECK_EMAIL_ERROR:
            return Object.assign({}, state, {
                kundenportalEmailSuccess: false,
                kundenportalEmailErrorMessage: true
            });
        case actions.LOGIN_KUNDENPORTAL:
            return Object.assign({}, state, {
                kundenportalOtp: action.payload.kundenportalOtp
            });
        case actions.LOGIN_KUNDENPORTAL_SUCCESS:
            return Object.assign({}, state, {
                kundenportalIsAuthenticated: true,
                kundenportalJwToken: action.payload.kundenportalJwToken,
                kundenportalLoginErrorMessage: false
            });
        case actions.LOGIN_KUNDENPORTAL_ERROR:
            return Object.assign({}, state, {
                kundenportalIsAuthenticated: false,
                kundenportalJwToken: null,
                kundenportalLoginErrorMessage: true
            });

        case actions.LOAD_KUNDENPORTAL_GARANTIE:
            return Object.assign({}, state, {
                kundenportalGarantie: [],
                kundenportalGarantieLoaded:false,
                kundenportalGarantieError:false
            });
        case actions.LOAD_KUNDENPORTAL_GARANTIE_SUCCESS:
            return Object.assign({}, state, {
                kundenportalGarantie: action.payload.kundenportalGarantie,
                kundenportalGarantieLoaded: true,
                kundenportalGarantieError:false
            });
        case actions.LOAD_KUNDENPORTAL_GARANTIE_ERROR:
            return Object.assign({}, state, {
                kundenportalGarantie: [],
                kundenportalGarantieLoaded:false,
                kundenportalGarantieError:true
            });
        case actions.LOAD_KUNDENPORTAL_GARANTIE_PDF:
            return Object.assign({}, state, {});
        case actions.LOAD_KUNDENPORTAL_GARANTIE_PDF_SUCCESS:
            return Object.assign({}, state, {});
        case actions.LOAD_KUNDENPORTAL_GARANTIE_PDF_ERROR:
            return Object.assign({}, state, {
                kundenportalGarantie: []
            });

        case actions.APPROVE_COMPLETED:
            return Object.assign({}, state, {
                auth:Object.assign({},state.auth,{account_approved:true}),
                user:((action.payload.hasOwnProperty('userdata')) ? action.payload.userdata : state.user),

            });

        case actions.JWTOKEN_RECEIVE:
            return Object.assign({}, state, {
                jwToken:action.payload.jwtToken
            });

        case actions.AUTH_UNAUTHORIZED:
            return Object.assign({}, state, {
                //showRelogin:true
            });

        case actions.CONTACT_SUBMIT_RESOLVE:
            return Object.assign({}, state, {
                contactFormSubmited:((!!action.payload && !!action.payload.status && action.payload.status === 'success'))
            });

        case actions.CONTACT_BACK_TO_FROM:
            return Object.assign({}, state, {
                contactFormSubmited:false
            });

        case actions.MODAL_USER_PWRESET_OPEN:
            return Object.assign({}, state, {
                modalPWResetOpen:true
            });

        case actions.MODAL_USER_PWRESET_CONFIRM:
        case actions.MODAL_USER_PWRESET_CLOSE:
            return Object.assign({}, state, {
                modalPWResetOpen:false
            });

        case formAction.COMPLETED_WIZARD:
        case formAction.CANCEL_WIZARD:
            return Object.assign({}, state, {
                garantie:Object.assign({},initialState.garantie,)
            });

        /* Customer Validation Rules */
        case actions.LOAD_CUSTOMER_VALIDATION_RULES_ERROR:
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{customerValidationRules:{}})
            });

        case actions.LOAD_CUSTOMER_VALIDATION_RULES_SUCCESS:
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{customerValidationRules:action.payload.rules})
            });

        /* Garantie Validation Rules */
        case actions.LOAD_GARANTIE_VALIDATION_RULES_ERROR:
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{garantieValidationRules:{}})
            });

        case actions.LOAD_GARANTIE_VALIDATION_RULES_SUCCESS:
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{garantieValidationRules:action.payload.rules})
            });

        /* Vehicle Validation Rules */
        case actions.LOAD_VEHICLE_VALIDATION_RULES_ERROR:
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{vehicleValidationRules:{}})
            });

        case actions.LOAD_VEHICLE_VALIDATION_RULES_SUCCESS:

            const defaultValues = Object.assign({},...Object.entries({vehicle:action.payload.rules}).map(([ruleName,rules]) => {
                return {[ruleName]:Object.assign({}, ...Object.entries(rules).map(([k, v]) => {
                    const defValue = defaultRegex.exec(v);
                    return ((!!defValue && !!defValue[1]) ? {[k]: defValue[1]} : null);
                }))};
            }));


            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{
                    vehicleValidationRules:action.payload.rules,
                    vehicle:Object.assign({},defaultValues.vehicle,state.garantie.vehicle),
                    vehicleCharged: action.payload?.chargedImpacts ? action.payload.chargedImpacts : {}
                })
                //garantie:Object.assign({},state.garantie,{vehicleValidationRules:action.payload.rules})
            });

        /* Create Vehicle */
        case actions.CREATE_VEHICLE:
        case actions.CREATE_VEHICLE_ERROR:
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{chargedImpacts:{}})
            });

        case actions.CREATE_VEHICLE_SUCCESS:
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{chargedImpacts:((!!action.payload && !!action.payload.chargedImpacts && Object.keys(action.payload.chargedImpacts).length > 0 && action.payload.chargedImpacts.constructor === Object) ? action.payload.chargedImpacts : {})})
            });

            /* Garantie abschicken */
        case actions.SUBMIT_GARANTIE_ERROR:
        case actions.SUBMIT_GARANTIE:
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{gar_nr:null,pdf:{}})
            });

        case actions.SUBMIT_GARANTIE_SUCCESS:
            let updateGarantie = Object.assign({},state.garantie.garantie);

            if(updateGarantie.hasOwnProperty(state.garantie.garantieart)){
                updateGarantie[state.garantie.garantieart].tmp_id = null;
            }

            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{pdf:action.payload.pdf,gar_nr:action.payload.gar_nr,garantie:updateGarantie})
            });

        case actions.MODAL_USER_SETTINGS_OPEN:
            return Object.assign({}, state, {
                userEditModalWindow:true
            });

        case actions.MODAL_USER_SETTINGS_CLOSE:
            return Object.assign({}, state, {
                userEditModalWindow:false
            });

        case actions.UPDATE_USER_SUCCESS:
            return Object.assign({}, state, {
                user:((!action.payload.hasOwnProperty(FORM_ERROR) && action.payload.hasOwnProperty('userdata')) ? action.payload.userdata : state.user),
                userEditModalWindow:((!action.payload.hasOwnProperty(FORM_ERROR) && action.payload.hasOwnProperty('userdata')) ? false : state.userEditModalWindow)
            });

        case actions.LOAD_USER_INFO_SUCCESS:
            return Object.assign({}, state, {
                user:action.payload.userInfo
            });

        case actions.LOAD_USER_INFO_ERROR:
            return Object.assign({}, state, {
                user:{}
            });

        case actions.LOAD_HV_AUSWERTUNGEN:
        case actions.LOAD_HV_AUSWERTUNGEN_ERROR:
            return Object.assign({}, state, {
                hv_auswertungen:[]
            });


        case actions.LOAD_HV_AUSWERTUNGEN_SUCCESS:
            return Object.assign({}, state, {
                hv_auswertungen:action.payload.auswertungen
            });

        case actions.HIDE_FIRST_LOGIN_WINDOW:
            return Object.assign({}, state, {
                showFirstLoginWindow:false
            });

        case actions.NEWS_SINGLE_LOAD:
            return Object.assign({}, state, {
                currentNews:null,
                singleNewsLoading:true
            });

        case actions.NEWS_SINGLE_LOAD_SUCCESS:
            return Object.assign({}, state, {
                currentNews:action.payload,
                singleNewsLoading:false
            });

        case actions.NEWS_SINGLE_LOAD_ERROR:
            return Object.assign({}, state, {
                singleNewsLoading:false
            });

        case actions.NEWS_LOAD:
            return Object.assign({}, state, {
                newsLoading:true
            });

        case actions.NEWS_LOAD_SUCCESS:
            return Object.assign({}, state, {
                news:action.payload.news,
                newsLoading:false
            });

        case actions.NEWS_LOAD_ERROR:
            return Object.assign({}, state, {
                newsLoading:false
            });

        case actions.LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated:false,
                lastAuthentification:null,
                auth:Object.assign({},initialState.auth)
            });

        case actions.SPEEDDIAL_OPEN:
            return Object.assign({}, state, {
                speedDialOpen:!state.speedDialOpen
            });

        case actions.SPEEDDIAL_CLOSE:
            return Object.assign({}, state, {
                //speedDialOpen:false
            });

        /* Load Downloads */
        case actions.LOAD_DOWNLOAD:
            return Object.assign({}, state, {
                downloads:[],
                downloadsLoading:true
            });

        case actions.LOAD_DOWNLOAD_SUCCESS:
            return Object.assign({}, state, {
                downloads:action.payload.downloads,
                downloadsLoading:false,
            });

        case actions.LOAD_DOWNLOAD_ERROR:
            return Object.assign({}, state, {
                downloads:[],
                downloadsLoading:false
            });

        /* Load Preislisten und Garantiebedingungen */
        case actions.LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN:
            return Object.assign({}, state, {
                preislistenUndGarantiebedingungen: {},
                preislistenUndGarantiebedingungenLoading: true
            });

        case actions.LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN_SUCCESS:
            return Object.assign({}, state, {
                preislistenUndGarantiebedingungen: action.payload.preislistenUndGarantiebedingungen,
                preislistenUndGarantiebedingungenLoading: false,
            });

        case actions.LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN_ERROR:
            return Object.assign({}, state, {
                preislistenUndGarantiebedingungen: {},
                preislistenUndGarantiebedingungenLoading: false
            });

        case actions.FAHRZEUGART_TARIFE_LOAD:
            return Object.assign({}, state, {
                fahrzeugartSelected: action.payload.fahrzeugartSelected,
            });

        case actions.FAHRZEUGART_TARIFE_LOAD_SUCCESS:
            return Object.assign({}, state, {
                fahrzeugartTarife: action.payload.fahrzeugartTarife,
            });

        /* Load Garantien */
        case actions.LOAD_GARANTIE:
            return Object.assign({}, state, {
                garantien:[],
                garantieListLoading:true
            });

        case actions.LOAD_GARANTIE_SUCCESS:
            return Object.assign({}, state, {
                garantien:action.payload.garantien,
                garantieListLoading:false,
            });

        case actions.LOAD_GARANTIE_ERROR:
            return Object.assign({}, state, {
                garantien:[],
                garantieListLoading:false
            });

        /* Tarife Laden */
        case actions.LOAD_TARIFE:
            const initialtarife = {...state.garantie.tarife};
            initialtarife[state.garantie.fahrzeugart] = [];
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{tarife:initialtarife}),
                tarifeLoading:true
            });

        case actions.LOAD_TARIFE_SUCCESS:
            const newTarife = {...state.garantie.tarife};
            newTarife[state.garantie.fahrzeugart] = action.payload.tarife || [];
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{tarife:newTarife}),
                tarifeLoading:false
            });

        case actions.LOAD_TARIFE_ERROR:
            const iniTtarife = {...state.garantie.tarife};
            iniTtarife[state.garantie.fahrzeugart] = [];
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{tarife:iniTtarife}),
                tarifeLoading:false
            });

        /* Conditionen Laden */
        case actions.LOAD_CONDITION:
            const initCondition = {...state.garantie.conditions};
            initCondition[state.garantie.garantieart] = [];
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{conditions:initCondition}),
                conditionLoading:true,
            });

        case actions.LOAD_CONDITION_ERROR:
            const initialCondition = {...state.garantie.conditions};
            initialCondition[state.garantie.garantieart] = [];
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{conditions:initialCondition}),
                conditionLoading:false,
            });

        case actions.LOAD_CONDITION_SUCCESS:
            const newConiditions = {...state.garantie.conditions};
            newConiditions[state.garantie.garantieart] = action.payload.conditions || [];
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{conditions:newConiditions}),
                conditionLoading:false,
            });

        /* Garantie erstellen */
        case actions.CREATE_GARANTIE_ERROR:
        case actions.CREATE_GARANTIE:
            const initGarantie = {...state.garantie.garantie};
            initGarantie[state.garantie.garantieart] = {};
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{garantie:initGarantie})
            });

        case actions.CREATE_GARANTIE_SUCCESS:
            const newGarantie = {...state.garantie.garantie};
            newGarantie[state.garantie.garantieart] = {tmp_id:action.payload.tmp_id,policyDependsOnVehicleCondition:action.payload.policyDependsOnVehicleCondition} || {};
            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,{garantie:newGarantie})
            });

        case actions.LOGIN:
            return Object.assign({}, state, {
                jwToken:null
            });

        case actions.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated:((!!action.payload && !!action.payload.data && action.payload.data.status === 'success' && !action.payload.hasOwnProperty(FORM_ERROR))),
                lastAuthentification:new Date(),
                ansprechpartner:((!!action.payload && !!action.payload.data && !!action.payload.data.hv_info) ? action.payload.data.hv_info : null ),
                auth:Object.assign({},action.payload.data),
                //auth:Object.assign({},action.payload.data,{account_approved:false,is_migrated:true}),
                showFirstLoginWindow:((!!action.payload && !!action.payload.data && !!action.payload.data.login_after_migration) ? true : false)
            });

        case actions.LOGIN_REFRESH:
            return Object.assign({}, state, {
                isAuthenticated:((!!action.payload && !!action.payload.data && action.payload.data.status === 'success' && !action.payload.hasOwnProperty(FORM_ERROR))),
                lastAuthentification:new Date(),
                auth:Object.assign({},action.payload.data),
            });

        case actions.GARANTIE_PROMISE_START:
            const newGarantieart = ((!!action.payload.garantieart_virt) ? ((String(action.payload.garantieart_virt).endsWith('__plus')) ? String(action.payload.garantieart_virt).substr(0,String(action.payload.garantieart_virt).length-6) : action.payload.garantieart_virt) : state.garantie.garantieart);
            const isPlusTariff = ((!!action.payload.garantieart_virt) ? (String(action.payload.garantieart_virt).endsWith('__plus')) : state.garantie.isPlusTariff );

            const garantie = (newGarantieart && newGarantieart !== state.garantie.garantieart) ? {...initialState.garantie.garantie} : {...state.garantie.garantie};

            return Object.assign({}, state, {
                garantie:Object.assign({},state.garantie,action.payload,{garantieart:newGarantieart,isPlusTariff:isPlusTariff, garantie}),
            });

        case actions.LOAD_USER_HV:
        case actions.LOAD_USER_HV_ERROR:
            return Object.assign({}, state, {
                ansprechpartner:null
            });

        case actions.LOAD_USER_HV_SUCCESS:
            return Object.assign({}, state, {
                ansprechpartner:action.payload
            });

      case actions.CONNECTION_STATUS:
        return Object.assign({},state,{isOnline:!!action.status});

        default:
            return state
    }
};

// Action Creators
export const logout = () => ({
    type: actions.LOGOUT,
});

export const checkEmail = (kundenportalGarantieId, kundenportalEmail) => ({
    type: actions.CHECK_EMAIL,
    payload: {
        kundenportalGarantieId: kundenportalGarantieId,
        kundenportalEmail: kundenportalEmail
    }
});

export const loginKundenportal = (kundenportalLoginString, kundenportalOtp) => ({
    type: actions.LOGIN_KUNDENPORTAL,
    payload: {
        kundenportalLoginString: kundenportalLoginString,
        kundenportalOtp: kundenportalOtp
    }
});
export const loadKundenportalGarantie = (kundenportalJwToken) => ({
    type: actions.LOAD_KUNDENPORTAL_GARANTIE,
    payload: {
        kundenportalJwToken: kundenportalJwToken
    }
});
export const loadKundenportalGarantiePdf = (path, filename) => ({
    type: actions.LOAD_KUNDENPORTAL_GARANTIE_PDF,
    payload: {
        path: path,
        filename: filename
    }
});

export const login = (username,password,jwToken) => ({
    type: actions.LOGIN,
    payload:{
        username:username,
        password:password,
        jwToken:jwToken
    }
});

export const loadGarantien = () => ({
    type: actions.LOAD_GARANTIE,
});

export const loadTarife = (vehicleType) => ({
    type: actions.LOAD_TARIFE,
    payload:{
        vehicleType:vehicleType
    }
});

export const loadPdfFromPath = (path,filename) => ({
    type: actions.LOAD_PDF_FROM_PATH,
    payload:{
        path:path,
        filename:filename
    }
});

export const openPdfFromPath = (path,filename) => ({
    type: actions.OPEN_PDF_FROM_PATH,
    payload:{
        path:path,
        filename:filename
    }
});

export const openSpeedDial = () => ({
    type: actions.SPEEDDIAL_OPEN,
});

export const closeSpeedDial = () => ({
    type: actions.SPEEDDIAL_CLOSE,
});

export const loadDownloads = () => ({
    type: actions.LOAD_DOWNLOAD,
});

export const loadPreislistenUndGarantiebedingungen = () => ({
    type: actions.LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN,
});

export const loadFahrzeugartTarife = (fahrzeugartSelected) => ({
    type: actions.FAHRZEUGART_TARIFE_LOAD,
    payload: {
        fahrzeugartSelected: fahrzeugartSelected
    }
});

export const loadNews = () => ({
    type: actions.NEWS_LOAD,
});

export const loadSingleNews = (uid) => ({
    type: actions.NEWS_SINGLE_LOAD,
    payload:{
        uid:uid
    }
});

export const showGarantieWizard = () => ({
    type: actions.GARANTIE_WIZARD_SHOW
});

export const hideFirstLoginWindow = () => ({
   type: actions.HIDE_FIRST_LOGIN_WINDOW
});

export const loadHvAuswertungen = () => ({
    type: actions.LOAD_HV_AUSWERTUNGEN
});

export const openUserSettingsModalWindow = () => ({
   type: actions.MODAL_USER_SETTINGS_OPEN
});

export const openPWResetModalWindow = () => ({
    type: actions.MODAL_USER_PWRESET_OPEN
});

export const closePWResetModalWindow = () => ({
    type: actions.MODAL_USER_PWRESET_CLOSE
});

export const backToContactForm = () => ({
    type: actions.CONTACT_BACK_TO_FROM
});

export const closeUserSettingsModalWindow = () => ({
    type: actions.MODAL_USER_SETTINGS_CLOSE
});

export const confirmPWResetModalWindow = (username) => ({
    type: actions.MODAL_USER_PWRESET_CONFIRM,
    payload:{
        username:username
    }
});

export const showNews = (item) => ({
    type: actions.NEWS_SHOW,
    payload:{
        ...item
    }
});
