import React from 'react'
import { Form } from 'react-final-form'
import Grid from "@material-ui/core/es/Grid/Grid";
import FordwardIcon from '../../Assets/Svg/Forward';
import Button from '@material-ui/core/Button';
import FormStateToRedux from '../../Helpers/FormStateToRedux'
import MakeAsyncFunction from 'react-redux-promise-listener'
import {actions} from '../../Reducers/App';
import { promiseListener } from '../../ConfigureStore';
import { connect } from 'react-redux';

class Wizard extends React.Component {

    static Page = ({ children }) => children

    /**
     * NOTE: Both validate and handleSubmit switching are implemented
     * here because 🏁 Redux Final Form does not accept changes to those
     * functions once the form has been defined.
     */

    validate = values => {
        const errors = {};
        const activePage = React.Children.toArray(this.props.children)[
            this.props.page
            ]


        if (
            !!values.email &&
            !!values.email_repeat &&
            values.email !== values.email_repeat
        ) {
            errors.email = "E-Mail-Adressen stimmen nicht überein";
            return errors;
        }else if(
            !!values.new_passwort_repeat &&
            !!values.new_passwort &&
            values.new_passwort_repeat !== values.new_passwort
        ){
            errors.new_passwort_repeat = "Passwort stimmt nicht überein";
            return errors;
        }

        return activePage.props.validate ? activePage.props.validate(values) : {}
    }


    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }))

    handleSubmit = values => {
        const { children, onSubmit,page} = this.props
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values)
        } else {
            this.next(values)
        }
    }

    render() {
        const { children,values,page,wasSubmited,is_migrated} = this.props
        const activePage = React.Children.toArray(children)[page]
        const isLastPage = page === React.Children.count(children) - 1
        const isPreLastPage = page === React.Children.count(children) - 2;
        const initVals = Object.assign({},values,{page:page});

        return (
            <MakeAsyncFunction
                listener={promiseListener}
                start={actions.APPROVE_PROMISE_START}
                resolve={actions.APPROVE_PROMISE_RESOLVE}
                reject={actions.APPROVE_PROMISE_REJECT}
            >
                {onSubmit => (
                    <Form
                        initialValues={initVals}
                        validate={this.validate}
                        onSubmit={onSubmit}
                    >
                        {({ handleSubmit,submitError, submitting, values,validating }) => (
                            <form id="approveForm" onSubmit={handleSubmit}>
                                <FormStateToRedux form="newApprove" />
                                {activePage}
                                {wasSubmited && submitError && <Grid container spacing={5}><Grid item style={{color:'#ff0000',textAlign:'center'}} xs={12}>{submitError}</Grid></Grid>}
                                <Grid container justifyContent="flex-end" wrap="wrap-reverse" spacing={5}>
                                    {!isLastPage && !isPreLastPage && <Grid item>
                                        <Button disableRipple color="primary" type="submit" disabled={submitting || validating}>
                                            {is_migrated ? 'SPEICHERN UND WEITER' : 'SPEICHERN UND ABSCHLIESSEN'}<FordwardIcon/>
                                        </Button>
                                    </Grid>
                                    }
                                    {isPreLastPage && (
                                        <Grid item>
                                            <Button disableRipple color="primary" type="submit" disabled={submitting || validating}>
                                                SPEICHERN UND ABSCHLIESSEN<FordwardIcon/>
                                            </Button>
                                        </Grid>
                                    )}
                                    {isLastPage && (
                                        <Grid item style={{marginTop:'40px'}}>
                                            <Button disableRipple color="primary" type="submit" disabled={submitting || validating}>
                                                SCHLIESSEN<FordwardIcon/>
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        )}
                    </Form>
                )}
            </MakeAsyncFunction>
        )
    }
}

function mapStateToProps(state) {
    const {approvepage,approveWasSubmited} = state.form;
    const {user} = state.app;

    return {
        page:((!!approvepage) ? approvepage : 0),
        values:Object.assign({},user),
        wasSubmited:approveWasSubmited,
        is_migrated:((!!state.app.auth.is_migrated))
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Wizard);
