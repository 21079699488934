import * as React from 'react';
import TextField from '@material-ui/core/TextField';


const TextFieldWrapper = ({input: {name, onChange, value, ...restInput}, meta, ...rest}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || meta.submitError);

    return (
        <TextField
            name={name}
            helperText={showError ? meta.error || meta.submitError : undefined}
            error={showError}
            inputProps={restInput}
            onChange={onChange}
            value={value}
            {...rest}
            InputProps={{
                required: false,
                ...rest.InputProps,
            }}
        />
    );
};

export default TextFieldWrapper;
