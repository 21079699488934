import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '../../Helpers/DefaultTextField';
import FordwardIcon from '../../Assets/Svg/Forward';
import { Form,Field } from 'react-final-form';
import {withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import createDecorator from 'final-form-focus';
import MakeAsyncFunction from 'react-redux-promise-listener'
import { promiseListener } from '../../ConfigureStore';
import {actions} from '../../Reducers/App';
import { push } from 'connected-react-router';
import {Paths} from '../../Constants/Paths';
import queryString from 'query-string';

const required = value => (value ? undefined : 'Bitte angeben');

const passwordValidator = value => {
    let result = undefined;

    if(!value){
        result = 'Bitte angeben';
    } else if(String(value).length <= 7){
        result = 'Ihr Passwort muss mindestens 8 Zeichen lang sein';
    }else if(
        !String(value).match(new RegExp("[A-Z]")) ||
        !String(value).match(new RegExp("[a-z]"))
    ){
        result = 'Ihr Passwort muss Groß- und Kleinbuchstaben enthalten';
    }else if(
        !String(value).match(new RegExp("[0-9]"))
    ){
        result = 'Ihr Passwort muss mindestens eine Zahl enthalten';
    }else if(
        !String(value).match(new RegExp("[?!@#\$%\^\&*\)\(+=._-]+"))
    ){
        result = 'Ihr Passwort muss mindestens eines der folgenden Sonderzeichen enthalten ? ! @ # $ % ^ & * ) ( + = . _ -';
    }

    return result
};

const focusOnError = createDecorator();


const styles = theme => ({
    cardContent: {
        padding:'96px 54px 33px 54px'
    },
    submitButton:{
        padding:0
    }
});

const LinkButton = withStyles(theme => ({
    root: {
        '&:hover':{
            backgroundColor:'transparent'
        }
    },
    label:{
        textTransform:'none',
        textDecoration:'underline'
    }

}))(Button);

class Reset extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handleSubmit      = this.handleSubmit.bind(this);
    }

    validate = values => {
        return this.props.validate ? this.props.validate(values) : {}
    };

    handleSubmit = values => {
        this.props.handleRefresh(values.username);
    };

    onClickBack = () => {
        this.props.goTo(Paths.LOGIN);
    };


    render() {

        const {classes,search} = this.props;
        const {token,login} = queryString.parse(search);

        const label = 'Passwort zurücksetzen';

        return (
            <Grid container justifyContent="center" style={{marginTop:'0px'}} spacing={0}>
                <Grid item xs={12} sm={8}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <MakeAsyncFunction
                            listener={promiseListener}
                            start={actions.PW_RESET}
                            resolve={actions.PW_RESET_RESOLVE}
                            reject={actions.PW_RESET_REJECT}
                            >
                            {onSubmit => (
                            <Form
                                validate={this.validate}
                                onSubmit={onSubmit}
                                initialValues={{token:token,login:login}}
                                decorators={[focusOnError]}
                            >
                                {({submitError,handleSubmit, submitting,pristine, values }) => (
                                    <form id="kontaktForm" onSubmit={handleSubmit}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <Typography style={{textAlign:'center',textTransform:'uppercase',marginBottom:'11px',color:'#474748'}} variant="body1">User Login</Typography>
                                                <Typography color="primary" style={{textAlign:'center'}} variant="h1">{label}</Typography>
                                                <Typography color="primary" style={{marginTop:'2em'}} >Geben Sie hier Ihr neues Passwort ein und klicken Sie auf Passwort ändern. Ein sicheres Passwort muss mindestens acht Zeichen lang sein und muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.
                                                    Wenn Sie Probleme beim Zurücksetzen Ihres Passworts haben oder sich nicht in Ihrem Konto anmelden können, wenden Sie sich an den für Sie zuständigen Außendienstmitarbeiter oder unser Büro in Castrop-Rauxel - Tel: 02305-9779-7.</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        fullWidth
                                                        required
                                                        validate={passwordValidator}
                                                        name="password"
                                                        component={TextField}
                                                        type="password"
                                                        label="Neues Passwort"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        fullWidth
                                                        required
                                                        validate={required}
                                                        name="password_confirmation"
                                                        component={TextField}
                                                        type="password"
                                                        label="Neues Passwort wiederholen"
                                                    />
                                                </FormControl>

                                            </Grid>
                                            {submitError && <Grid item style={{textAlign:'center',color:'#ff0000'}} xs={12}>{submitError}</Grid>}
                                            <Field
                                                name="token"
                                                type="hidden"
                                            >
                                                {props => {
                                                    const {input,meta,...rest} = props;
                                                    const {name, onChange, value, ...restInput} = input;
                                                    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || meta.submitError);
                                                    return (
                                                        <>
                                                        <input
                                                            name={name}
                                                            onChange={onChange}
                                                            value={value}
                                                            {...rest}
                                                            {...restInput}
                                                        />
                                                        {!!showError && <Grid item xs={12} style={{padding:0,textAlign:'center',color:'#f00'}}>Der Sicherheitstoken ist nicht vorhanden oder ungültig.</Grid>}
                                                        </>
                                                    )
                                                }}
                                            </Field>
                                            <Field
                                                name="login"
                                                type="hidden"
                                            >
                                                {props => {
                                                    const {input,meta,...rest} = props;
                                                    const {name, onChange, value, ...restInput} = input;
                                                    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || meta.submitError);
                                                    return (
                                                        <>
                                                        <input
                                                            name={name}
                                                            onChange={onChange}
                                                            value={value}
                                                            {...rest}
                                                            {...restInput}
                                                        />
                                                        {!!showError && <Grid item xs={12} style={{padding:0,textAlign:'center',color:'#f00'}}>Ihr Benutzername ist nicht bekannt oder ungültig.</Grid>}
                                                        </>
                                                    )
                                                }}
                                            </Field>
                                            <Grid item style={{textAlign:'right'}} xs={12}>
                                                <Button className={classes.submitButton} disableRipple color="primary" type="submit" disabled={submitting}>
                                                    Passwort ändern<FordwardIcon/>
                                                </Button>
                                            </Grid>
                                            <Grid item style={{textAlign:'right'}} xs={12}>
                                                <LinkButton onClick={(e) => this.onClickBack(e)} disableRipple disabled={submitting} type="button">
                                                    zurück zum Login
                                                </LinkButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                            )}
                            </MakeAsyncFunction>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

Reset.propTypes = {
    classes:PropTypes.object.isRequired,
    goTo:PropTypes.func.isRequired,
    search:PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Reset));
