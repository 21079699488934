import React,{useRef} from 'react';
import PropTypes from 'prop-types';
import {Typography,Box,Grid,Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import { Form,FormSpy } from 'react-final-form'
import FormStateToRedux from '../../../Helpers/FormStateToRedux'
import { connect } from 'react-redux';
import {showGarantieWizard} from '../../../Reducers/App';
import {nextWizard,prevWizard,cancelWizard,completeWizard} from '../../../Reducers/Form';
import {actions} from '../../../Reducers/App';
import FordwardIcon from '../../../Assets/Svg/Forward';
import BackwardIcon from '../../../Assets/Svg/Backward';
import CancelIcon from '../../../Assets/Svg/Cancel';
import MakeAsyncFunction from 'react-redux-promise-listener'
import { promiseListener } from '../../../ConfigureStore';
import {Paths} from '../../../Constants/Paths';
import { push } from 'connected-react-router';
import createDecorator from "final-form-calculate";


const styles = {
    cancelButton:{

    },
    transitionEnter:{
        opacity: 1
    },
    transitionEnterActive:{
        opacity: 1
    },
    transitionExit:{
        opacity: 0
    },
    transitionExitActive:{
        opacity: 0
    }
};

/*const calculator = createDecorator(
    {
        field: 'vehicle.kw',
        updates: {
            'vehicle.ps': (kwValue, allValues) => parseInt(Math.round(kwValue*1.35962),10)
        }
    },
    {
        field: 'vehicle.ps',
        updates: {
            'vehicle.kw': (psValue, allValues) => parseInt(Math.round(psValue*0.735499),10)
        }
    },
);*/

const decorator = createDecorator(
    {
        field: 'inspektionsnachweise.inspectionProof', // when inspectionProof changes...
        isEqual: (a) => {
            if(
                Array.isArray(a) &&
                a.length > 1
            ){
                return false
            }
            return true
        },
        updates: {
            ["inspektionsnachweise.inspectionProof"]: (fooValue, allValues) => {
                return Array.isArray(fooValue) ? [fooValue[fooValue.length - 1]] : fooValue;
            }
        }
    }
)


class GarantieAdd extends React.Component {

    componentDidMount(){
        this.props.load();
    }

    componentDidUpdate(prevProps){
        if(
            prevProps.page !== this.props.page
        ){
            this.props.load();
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            prevTitle:null
        };

    }

    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    };

    static Page = ({ children }) => children;

    next = values => {
        this.props.nextStep(values)
    };

    previous = () => {
        this.props.prevStep();
    };

    cancel = () => {
        this.props.cancel();
    };

    completed = () => {
        this.props.complete();
    };

    backToOverview = () => {
        this.props.goTo(Paths.HOME+"?resetForm=1");
    };

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.props.page
            ]
        return activePage.props.validate ? activePage.props.validate(values) : {}
    };

    handleSubmit = values => {
        const {onSubmit } = this.props;
        return onSubmit(values);
    };


    static getDerivedStateFromProps(props, state){
        if(!!props){
            const {children,page} = props;

            if(page <= 0){
                return null;
            }

            const activePage = React.Children.toArray(children)[page-1];
            const {title} = activePage.props || {};

            if (title !== state.prevTitle) {
                return {
                    prevTitle: title
                };
            }
        }
        return null;
    }


    render() {
        const {children,page,values,classes,wasSubmited} = this.props;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;
        const isFirstPage = page === 0;
        const isPreLastPage = page === React.Children.count(children) - 2;
        const {title,subtitle} = activePage.props || {};
        //const removedEmptyVals = Object.assign({},...filter(initialValues,(item) => !isEmpty(item)));
        const initVals = Object.assign({},values,{page:page});

        return (
            <Grid container spacing={5}>
                {!!title && <Grid item xs={12}>
                    <Box textAlign="center">
                            <Typography color="primary" style={{marginTop:20,minHeight:'30px',position:'relative'}} variant="h2">
                                {title}
                            </Typography>
                            {!!subtitle && <Typography style={{marginTop:'20px'}}>{subtitle.split('\n').map((item, key) => {
                                return <React.Fragment key={key}>{item}<br/></React.Fragment>
                            })}</Typography>}
                    </Box>
                </Grid>}
                <Grid item xs={12}>
                    <MakeAsyncFunction
                        listener={promiseListener}
                        start={actions.GARANTIE_PROMISE_START}
                        resolve={actions.GARANTIE_PROMISE_RESOLVE}
                        reject={actions.GARANTIE_PROMISE_REJECT}
                    >
                        {onSubmit => (
                            <Form
                                initialValues={initVals}
                                validate={this.validate}
                                decorators={[decorator]}
                                onSubmit={onSubmit}
                            >
                                {({ handleSubmit,submitError, submitting,form, values,pristine,validating }) => {
                                    const valuesRef = useRef({});
                                    return (
                                    <form id="garantieForm" onSubmit={handleSubmit}>
                                        <FormStateToRedux form="newGarantie" />
                                        <FormSpy
                                            subscription={{ values: true }}
                                            onChange={({ values }) => {
                                                let needReset = false;
                                                const next = { ...values };

                                                if
                                                (
                                                    !!values &&
                                                    !!values.vehicle &&
                                                    !!values.vehicle.kw &&
                                                    (
                                                        (!!valuesRef &&
                                                        !!valuesRef.current &&
                                                        !!valuesRef.current.vehicle &&
                                                        !!valuesRef.current.vehicle.kw &&
                                                        parseInt(values.vehicle.kw,10) !== parseInt(valuesRef.current.vehicle.kw,10)) ||
                                                        !valuesRef.current ||
                                                        !valuesRef.current ||
                                                        !valuesRef.current.vehicle ||
                                                        !valuesRef.current.vehicle.kw
                                                    )
                                                ) {
                                                    next.vehicle.ps = parseInt(Math.round(values.vehicle.kw*1.35962),10);
                                                    needReset = true;

                                                }else if(
                                                    !!values &&
                                                    !!values.vehicle &&
                                                    !!values.vehicle.ps &&
                                                    (
                                                        (!!valuesRef &&
                                                        !!valuesRef.current &&
                                                        !!valuesRef.current.vehicle &&
                                                        !!valuesRef.current.vehicle.ps &&
                                                        parseInt(values.vehicle.ps,10) !== parseInt(valuesRef.current.vehicle.ps,10)) ||
                                                        !valuesRef.current ||
                                                        !valuesRef.current ||
                                                        !valuesRef.current.vehicle ||
                                                        !valuesRef.current.vehicle.ps
                                                    )
                                                ){
                                                    next.vehicle.kw = parseInt(Math.round(values.vehicle.ps*0.735499),10);
                                                    needReset = true;
                                                }

                                                if (needReset) {
                                                    form.reset(next);
                                                }


                                                valuesRef.current = values;
                                            }}
                                        />
                                        {activePage}
                                        {wasSubmited && submitError && <Grid container spacing={5}><Grid item style={{color:'#ff0000',textAlign:'center'}} xs={12}>{submitError}</Grid></Grid>}
                                        <Grid container justifyContent="space-between" wrap="wrap-reverse" spacing={5}>
                                            <Grid item>
                                                {!isFirstPage && !isLastPage && <Button disabled={submitting || validating} className={classes.cancelButton} disableRipple color="secondary" type="button" onClick={this.cancel}>
                                                    <CancelIcon/>Abbrechen
                                                </Button>}
                                            </Grid>
                                            <Grid item style={{flexGrow:1}}>
                                                <Grid container justifyContent="flex-end" spacing={5}>
                                                    {page > 0 && !isLastPage && (
                                                        <Grid item>
                                                            <Button disabled={submitting || validating} disableRipple color="secondary" type="button" onClick={this.previous}>
                                                                <BackwardIcon/>Zurück
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                    {!isFirstPage && !isPreLastPage && !isLastPage && (
                                                        <Grid item>
                                                            <Button disableRipple color="primary" disabled={submitting || validating} type="submit">Weiter<FordwardIcon/></Button>
                                                        </Grid>
                                                    )}
                                                    {isLastPage && (
                                                        <Grid item>
                                                            <Button disableRipple color="secondary" type="button" onClick={this.backToOverview}>zurück zur Übersicht<FordwardIcon/></Button>
                                                        </Grid>
                                                    )}
                                                    {isLastPage && (
                                                        <Grid item>
                                                            <Button disableRipple color="primary" type="button" onClick={this.completed}>Weitere Garantie anlegen<FordwardIcon/></Button>
                                                        </Grid>
                                                    )}
                                                    {!isFirstPage && isPreLastPage && (
                                                        <Grid item>
                                                            <Button disableRipple color="primary" type="submit" disabled={submitting || validating}>
                                                                abschicken<FordwardIcon/>
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}}
                            </Form>
                        )}
                    </MakeAsyncFunction>
                </Grid>
            </Grid>
        );
    }
}

GarantieAdd.propTypes = {
    classes:PropTypes.object.isRequired,
    title:PropTypes.string,
    subtitle:PropTypes.string,
    page:PropTypes.number.isRequired,
    values:PropTypes.object.isRequired,
    nextStep:PropTypes.func.isRequired,
    prevStep:PropTypes.func.isRequired,
    cancel:PropTypes.func.isRequired,
    complete:PropTypes.func.isRequired,
    load:PropTypes.func.isRequired,
    wasSubmited:PropTypes.bool.isRequired,
    goTo:PropTypes.func.isRequired,
    initialValues:PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const {page,wasSubmited} = state.form;
    const {garantie} = state.app;

    return {
        page:((!!page) ? page : 0),
        values:garantie,
        wasSubmited:wasSubmited
    }
}

const mapDispatchToProps = dispatch => {
    return {
        nextStep:(values) => dispatch(nextWizard(values)),
        prevStep:() => dispatch(prevWizard()),
        cancel:() => dispatch(cancelWizard()),
        complete:() => dispatch(completeWizard()),
        load:() => dispatch(showGarantieWizard()),
        goTo:(path) => dispatch(push(path)),
    }
};


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(GarantieAdd));
