import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarIcon(props) {
    return (
        <SvgIcon viewBox="0 0 15 17" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M1.14285851,15.1428585 L13.7143021,15.1428585 L13.7143021,5.99999046 L1.14285851,5.99999046 L1.14285851,15.1428585 Z M4.57143402,4.28570271 L4.57143402,1.71427107 C4.57143402,1.55355659 4.44643387,1.42855644 4.28571939,1.42855644 L3.71429014,1.42855644 C3.55357566,1.42855644 3.42857552,1.55355659 3.42857552,1.71427107 L3.42857552,4.28570271 C3.42857552,4.44641718 3.55357566,4.57141733 3.71429014,4.57141733 L4.28571939,4.57141733 C4.44643387,4.57141733 4.57143402,4.44641718 4.57143402,4.28570271 Z M11.4285851,4.28570271 L11.4285851,1.71427107 C11.4285851,1.55355659 11.3035849,1.42855644 11.1428704,1.42855644 L10.5714412,1.42855644 C10.4107267,1.42855644 10.2857265,1.55355659 10.2857265,1.71427107 L10.2857265,4.28570271 C10.2857265,4.44641718 10.4107267,4.57141733 10.5714412,4.57141733 L11.1428704,4.57141733 C11.3035849,4.57141733 11.4285851,4.44641718 11.4285851,4.28570271 Z M14.8571606,3.71427345 L14.8571606,15.1428585 C14.8571606,15.7678593 14.3393028,16.285717 13.7143021,16.285717 L1.14285851,16.285717 C0.51785776,16.285717 0,15.7678593 0,15.1428585 L0,3.71427345 C0,3.08927271 0.51785776,2.57141495 1.14285851,2.57141495 L2.28571701,2.57141495 L2.28571701,1.71427107 C2.28571701,0.928555846 2.92857492,0.285697937 3.71429014,0.285697937 L4.28571939,0.285697937 C5.07143462,0.285697937 5.71429253,0.928555846 5.71429253,1.71427107 L5.71429253,2.57141495 L9.14286804,2.57141495 L9.14286804,1.71427107 C9.14286804,0.928555846 9.78572595,0.285697937 10.5714412,0.285697937 L11.1428704,0.285697937 C11.9285856,0.285697937 12.5714436,0.928555846 12.5714436,1.71427107 L12.5714436,2.57141495 L13.7143021,2.57141495 C14.3393028,2.57141495 14.8571606,3.08927271 14.8571606,3.71427345 Z"  fill="#3F3F3F"></path>
            </g>
        </SvgIcon>
    );
}