import React from 'react';
import PropTypes from 'prop-types';
import {Typography,Box,Grid,CardContent,Card} from '@material-ui/core';
import { connect } from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {loadHvAuswertungen} from '../../../Reducers/App';


const styles = theme => ({

});

class Haendlerinfo extends React.Component {

    componentDidMount() {
        this.props.getHvAuswertungen();
    }

    render() {

        const {hv_auswertungen} = this.props;

        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <Typography color="primary" style={{marginTop:20}} variant="h2">Händlerinformation</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent style={{padding:'26px 54px 42px 54px'}}>
                            <Grid container spacing={5}>
                                <Grid item>
                                    Hier können Sie die Händlerinformationen runterladen:
                                    {hv_auswertungen.length === 0 && <div><em>Keine Händlerinformationen vorhanden</em></div>}
                                    {hv_auswertungen.length > 0 && hv_auswertungen.map((item, index) => (
                                        <Box key="">
                                            <a style={{color:'#00468E'}} rel="noopener noreferrer" target="_blank" href={item}>{item}</a>
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }

}

Haendlerinfo.propTypes = {
    classes:PropTypes.object.isRequired,
    hv_auswertungen:PropTypes.array.isRequired,
    getHvAuswertungen:PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const {hv_auswertungen} = state.app;
    return {
        hv_auswertungen:hv_auswertungen
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHvAuswertungen:() => dispatch(loadHvAuswertungen()),
    }
};


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Haendlerinfo));