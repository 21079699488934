import * as React from 'react';
import PropTypes from 'prop-types';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {withStyles} from '@material-ui/core/styles';
import CalendarIcon from '../Assets/Svg/Calendar';
import parseISO from 'date-fns/parseISO'

const styles = theme => ({
    keyboardButton:{
        '&:hover':{
            backgroundColor:'transparent'
        }
    }
});

const DateTextFieldWrapper = ({input: {name, onChange, value, ...restInput}, meta,classes, ...rest}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || meta.submitError);
    const dateValue = ((!!value && value instanceof Date) ? value : (!!value) ? parseISO(value) : value);

    return (
        <KeyboardDatePicker
            {...rest}
            disableToolbar
            name={name}
            variant="inline"
            format="dd.MM.yyyy"
            id={`date-picker-inline-${name}`}
            value={dateValue instanceof Date && !isNaN(dateValue) ? dateValue : null}
            helperText={showError ? meta.error || meta.submitError : undefined}
            error={showError}
            inputProps={restInput}
            InputProps={{
                required: false,
            }}
            onChange={onChange}
            keyboardIcon={<CalendarIcon style={{fontSize:'18px'}} />}
            KeyboardButtonProps={{
                'aria-label': 'Datum ändern',
                disableRipple:true,
                className:classes.keyboardButton
            }}
        />
    );
};

DateTextFieldWrapper.propTypes = {
    classes:PropTypes.object.isRequired,
};

export default withStyles(styles)(DateTextFieldWrapper);
