import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ReisemobilIcon(props) {
    return (
        <SvgIcon viewBox="0 0 64 35" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g  transform="translate(1.000000, 0.000000)">
                    <polyline  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" points="58.5487069 29.9304267 62.2457465 29.9304267 62.2395089 28.0366842 61.688103 28.0366842 61.6937168 25.9682881 62.2457465 25.9682881 62.2457465 22.1065752 52.7664297 19.0470208 48.3533109 14.1068228"></polyline>
                    <path d="M16.8577396,29.9304267 L49.3838287,29.9304267"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <g  transform="translate(0.000000, 0.131739)">
                        <polyline  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" points="0.908572277 10.2887733 0.908572277 18.9653079 0.46819604 19.9296446 0.506245545 27.8932188 0.908572277 27.8932188 0.908572277 29.7988129 7.6608 29.7988129"></polyline>
                        <path d="M56.8790198,5.82525446 L51.2121386,1.06033366 L22.2227822,0.960531683 L18.1508614,3.76933366 L17.2077327,3.76933366 L13.5025842,3.76933366 L13.5025842,3.01707624 L10.5378416,3.01707624 L10.5378416,3.76933366 L8.28730693,3.76933366 L8.28730693,3.01707624 L5.32131683,3.01707624 L5.32131683,3.76933366 L2.04531683,3.76933366 C1.41718812,3.76933366 0.908821782,4.27832376 0.908821782,4.90645248 L0.908821782,8.85050198 C0.908821782,9.47863069 1.41718812,9.98824455 2.04531683,9.98824455 L52.565703,9.98824455 C53.0335248,9.98824455 53.5188119,9.82544257 54.1269802,9.3027297 L56.8297426,6.7777396 C57.3306238,6.12590792 56.8790198,5.82525446 56.8790198,5.82525446 Z"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M38.0218099,26.9396733 L38.0218099,13.2817723 C38.0218099,12.6536436 37.5128198,12.1446535 36.8846911,12.1446535 L31.1348495,12.1446535 C30.506097,12.1446535 29.9971069,12.6536436 29.9971069,13.2817723 L29.9971069,26.9396733"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M26.4864475,26.9396733 L26.4864475,23.5632475 C26.4864475,22.9351188 25.9774574,22.4261287 25.3493287,22.4261287 L20.1508931,22.4261287 C19.5227644,22.4261287 19.0131505,22.9351188 19.0131505,23.5632475 L19.0131505,24.8669109 L19.0131505,26.9396733"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <polygon  points="0 34.3069307 62.7149406 34.3069307 62.7149406 0.492148515 0 0.492148515"></polygon>
                        <polygon  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" points="12.1427822 20.0059307 27.2384554 20.0059307 27.2384554 12.4328317 12.1427822 12.4328317"></polygon>
                        <polygon  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" points="3.71949505 17.6992574 9.03332673 17.6992574 9.03332673 12.232604 3.71949505 12.232604"></polygon>
                        <polyline  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" points="52.2209495 10.4462109 48.3536228 13.9748347 40.6794743 13.9748347 40.6794743 10.8909535"></polyline>
                        <path d="M35.7740822,16.0491564 C35.7740822,17.084602 34.934498,17.9235624 33.8990525,17.9235624 C32.8642307,17.9235624 32.0252703,17.084602 32.0252703,16.0491564 C32.0252703,15.0137109 32.8642307,14.1747505 33.8990525,14.1747505 C34.934498,14.1747505 35.7740822,15.0137109 35.7740822,16.0491564 Z"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M45.6689495,5.99672673 C45.6689495,6.40404356 45.3383554,6.73463762 44.9304149,6.73463762 L41.4173851,6.73463762 C41.0100683,6.73463762 40.6794743,6.40404356 40.6794743,5.99672673 L40.6794743,4.69555842 C40.6794743,4.28824158 41.0100683,3.95764752 41.4173851,3.95764752 L44.9304149,3.95764752 C45.3383554,3.95764752 45.6689495,4.28824158 45.6689495,4.69555842 L45.6689495,5.99672673 Z"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M40.6794743,13.9750218 L40.6794743,29.1486653"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M48.3705267,14.0313475 L48.4360218,13.9752089"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M52.7331832,26.2995059 L52.7331832,18.9578228"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M40.9761356,19.3875327 L51.8077693,19.3413743"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M57.7044446,30.1446891 C57.7044446,32.1850158 56.0502267,33.8392337 54.0092762,33.8392337 C51.9695733,33.8392337 50.3153554,32.1850158 50.3153554,30.1446891 C50.3153554,28.1043624 51.9695733,26.4495208 54.0092762,26.4495208 C56.0502267,26.4495208 57.7044446,28.1043624 57.7044446,30.1446891 Z"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M0.897594059,27.8960257 L8.63162376,27.8960257"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M15.8538564,27.905195 L40.679599,27.905195"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M61.7442416,27.905195 L57.5924792,27.905195"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <polyline  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" points="43.8110733 24.8256802 43.4287069 24.8256802 43.4287069 22.4934327 43.8110733 22.4934327"></polyline>
                        <path d="M15.9982574,30.1446891 C15.9982574,32.1850158 14.3446634,33.8392337 12.3037129,33.8392337 C10.2633861,33.8392337 8.60854455,32.1850158 8.60854455,30.1446891 C8.60854455,28.1043624 10.2633861,26.4495208 12.3037129,26.4495208 C14.3446634,26.4495208 15.9982574,28.1043624 15.9982574,30.1446891 Z"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M13.7919475,30.1443772 C13.7919475,30.966496 13.1251455,31.633298 12.3030267,31.633298 C11.4815317,31.633298 10.8147297,30.966496 10.8147297,30.1443772 C10.8147297,29.3222584 11.4815317,28.6554564 12.3030267,28.6554564 C13.1251455,28.6554564 13.7919475,29.3222584 13.7919475,30.1443772 Z"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M55.498696,30.1443772 C55.498696,30.966496 54.8318941,31.633298 54.0097752,31.633298 C53.1876564,31.633298 52.5214782,30.966496 52.5214782,30.1443772 C52.5214782,29.3222584 53.1876564,28.6554564 54.0097752,28.6554564 C54.8318941,28.6554564 55.498696,29.3222584 55.498696,30.1443772 Z"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                        <polyline  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" points="32.5833505 22.9543931 32.2009842 22.9543931 32.2009842 20.6221455 32.5833505 20.6221455"></polyline>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}
