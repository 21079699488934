import createSagaMiddleware from 'redux-saga'
import App from './Reducers/App';
import Form from './Reducers/Form';
import logger from 'redux-logger'
import { createStore,applyMiddleware,combineReducers,compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter,routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createReduxPromiseListener from 'redux-promise-listener'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import {initialState as AppState} from './Reducers/App';
import LogRocket from "logrocket";

//const expireReducer = require('redux-persist-expire');

export const history = createBrowserHistory({
    basename: '/login',
});
//import Reactotron from './ReactotronConfig'

const reduxPromiseListener = createReduxPromiseListener();

export default function configureStore(preloadedState,version) {
    const sagaMiddleware = createSagaMiddleware();

    const initState = Object.assign({},preloadedState,{'app':Object.assign(AppState,{version:version})});

    const rootPersistConfig = {
        key: `root-${version}`,
        storage,
        stateReconciler: autoMergeLevel2,
        blacklist: ['app','router'],
    };

    const appPersistConfig = {
        key: `app-${version}`,
        storage: storage,
        stateReconciler: autoMergeLevel2,
        //blacklist: ['jwToken','contactFormSubmited','showRelogin','is_deactivated','isAuthenticated','user','news','downloads','newsLoading','downloadsLoading','tarifeLoading','conditionLoading','garantieListLoading','singleNewsLoading'],
        //blacklist: ['contactFormSubmited','showRelogin','is_deactivated','isAuthenticated','news','downloads','newsLoading','downloadsLoading','tarifeLoading','conditionLoading','garantieListLoading','singleNewsLoading'],
    };

    /** ToDo: Das erste wieder aktivieren */

    const createRootReducer = (history) => combineReducers({
        router: connectRouter(history),
        app:persistReducer(appPersistConfig,App),
        form:Form
    });

    const persistedReducer = persistReducer(rootPersistConfig,createRootReducer(history));

    let middlewares = [
        routerMiddleware(history),
        reduxPromiseListener.middleware,
        sagaMiddleware,
        // LogRocket.reduxMiddleware()
    ];

    if(process.env.NODE_ENV === 'development'){
        middlewares[middlewares.length] = logger;
    }

    let store = createStore(
        persistedReducer,
        initState,
        compose(
            applyMiddleware(
                ...middlewares
            )
        ),
    );

    let persistor = persistStore(store);

    /* Purge Data */
    persistor.purge();

    /** Todo: Wieder akivieren */

    return {
        store:store,
        persistor:persistor,
        runSaga: sagaMiddleware.run
    }
}

export const promiseListener = reduxPromiseListener;
