import React from 'react';
import PropTypes from 'prop-types';
import {Typography,Box,Grid,CardContent,Card,Button} from '@material-ui/core';
import { connect } from 'react-redux';
import {loadSingleNews} from '../../../Reducers/App'
import Skeleton from '@material-ui/lab/Skeleton';
import {format,parseISO } from 'date-fns';
import FordwardIcon from '../../../Assets/Svg/Forward';
import BackwardIcon from '../../../Assets/Svg/Backward';
import {withStyles} from '@material-ui/core/styles'

const styles = {
    bodytext:{
        '& A':{
            color:'#00468E',
        }
    }
}

class NewsShow extends React.Component {

    constructor(props) {
        super(props);
        this.handleNextPrevClick = this.handleNextPrevClick.bind(this);
    }

    componentDidMount(){

        if(
            !this.props.news ||
            this.props.news['uid'] !== this.props.newsUid
        ){
            this.props.loadNews(this.props.newsUid);
        }
    }

    componentDidUpdate(oldProps){
        const newProps = this.props;

        if(
            newProps &&
            oldProps &&
            !!newProps.newsUid &&
            !!oldProps.newsUid &&
            newProps.newsUid !== oldProps.newsUid
        ){
            this.props.loadNews(newProps.newsUid);
        }
    }

    createMarkup(val) {
        return {__html: val};
    }

    handleNextPrevClick(uid){
        this.props.loadNews(uid);
    }

    render() {

        const {news,newsLoading,classes} = this.props;

        let newsdate = null;

        if(
            !!news &&
            !!news.newsdate &&
            !!parseISO(news.newsdate.date)
        ){
            newsdate = parseISO(news.newsdate.date);
        }

        return (
            <>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        {news ? (<Typography color="primary" style={{marginTop:20}} variant="h2">{news.title}</Typography>) : (<Skeleton style={{margin:'0 auto',marginTop:20}} width="60%" height={30} />)}
                        {news ? (<Typography style={{marginTop:10}} variant="body1">{!!newsdate && newsdate instanceof Date ? format(newsdate,'dd.MM.yyyy') : ''}</Typography>) : (<Skeleton style={{margin:'0 auto',marginTop:10}} width="10%" height={20} />)}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent style={{padding:'26px 54px 42px 54px'}}>
                            <Grid container spacing={5}>
                                {!!news && !!news.content && news.content.length && news.content.map((item,index) => {
                                    return (
                                        <Grid key={index} item style={{marginBottom:'1rem'}} xs={12}>
                                            {!!item.flexformArray && !!item.flexformArray.settings && !!item.flexformArray.settings.text && <div className={classes.bodytext} dangerouslySetInnerHTML={this.createMarkup(item.flexformArray.settings.text)}></div>}
                                        </Grid>
                                    );
                                })}
                                <Grid item xs={12}>
                                    {news ? (<Typography variant="body1">{news.body}</Typography>) : (Array.from(new Array(25)).map((item,index) => (<Skeleton key={index} width="100%" height={24} />)))}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {!!news && <Grid container justifyContent="space-between" spacing={5}>
                {!!news.prevUid && <Grid item>
                    <Button disableRipple onClick={(e) => this.handleNextPrevClick(news.prevUid)} color="primary" type="submit" disabled={newsLoading}>
                        <BackwardIcon/>vorherige Info
                    </Button>
                </Grid>}
                {!!news.nextUid && <Grid item>
                    <Button disableRipple onClick={(e) => this.handleNextPrevClick(news.nextUid)} color="primary" type="submit" disabled={newsLoading}>
                        nächste Info<FordwardIcon/>
                    </Button>
                </Grid>}
            </Grid>}
            </>
        );
    }

}

NewsShow.propTypes = {
    newsUid:PropTypes.string.isRequired,
    newsLoading:PropTypes.bool.isRequired,
    loadNews:PropTypes.func.isRequired,
    news:PropTypes.object,
    classes:PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const {singleNewsLoading,currentNews} = state.app;
    return {
        newsLoading:singleNewsLoading,
        news:currentNews
    }
}

const mapDispatchToProps = dispatch => {

    return {
        loadNews:(uid) => dispatch(loadSingleNews(uid)),
    }
};


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(NewsShow));
