import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Info(props) {
    return (
        <SvgIcon viewBox="0 0 22 22" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g  transform="translate(-17.000000, -429.000000)" stroke="currentColor" strokeWidth="1.5">
                    <g  transform="translate(18.000000, 430.000000)">
                        <g >
                            <path d="M19.87495,9.9375 C19.87495,15.4255 15.42545,19.875 9.93745,19.875 C4.44945,19.875 -5e-05,15.4255 -5e-05,9.9375 C-5e-05,4.4495 4.44945,0 9.93745,0 C15.42545,0 19.87495,4.4495 19.87495,9.9375 Z" ></path>
                            <polygon  points="10.5974 14.1101 10.5974 9.6106 10.5974 7.9241 9.2774 7.9241 8.1234 7.9241 8.1234 9.1306 9.2774 9.1306 9.2774 14.1101 8.1234 14.1101 8.1234 15.3166 11.7519 15.3166 11.7519 14.1101"></polygon>
                            <path d="M10.56345,5.5515 C10.56345,6.1 10.11895,6.5445 9.57045,6.5445 C9.02195,6.5445 8.57745,6.1 8.57745,5.5515 C8.57745,5.003 9.02195,4.5585 9.57045,4.5585 C10.11895,4.5585 10.56345,5.003 10.56345,5.5515 Z" ></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};