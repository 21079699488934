export const PublicCert = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAvQCbNXVp7zq5OyfnSdEM
xPlsRSrQXgVRKB6GNDKWt+qvWygWlZKLj7So/zgSKhFFKtuOMbiGmj3GaS2bug9q
e2LclRzIUzEdJn/ZyGzmpuB7Cojgf+hx5z3wmk7ACFp9LaEXOkU1hdeZQMEQkrfB
2fF90HMTjBI72Ie0dUCBhbBoP7m6/ybdja/i2MPW/m9AUHK/EnNOHCAbA5891DRk
Dl4Hcvf+gV9HuRsk6xDBJGIIYcZMvTm/DSLnEHr/puKX2NnYP5cmYzW4/SrvpD72
XlXA3yvBXOPeKEmOZjTwGQXHoShvqlsjs+r7NTmdEfGPYT6oGIJP4BwgaOdFl0OL
F/GiLSpCM0YeVzjP2RJSlea0Njk+7JjiQRI0stUWE2e82caDSrLRZlXQD0QuzHBH
5jueQgOY2MVURgzTIBR/Y+2IGU+b+JfdiQdnsWJ4E7IEEIUEcU12uVsUFTGUd5z3
m+Z0dPa903WGU9x3T8aIjSV+VI5PPmV0cIQYW2A1k7858Q3dWS59HBrbaBIa9m2H
0Bphb6B7fQozqvGHo92cjtDHuR+DJ9SV7Wht9jzitkHu9PYn63SoFq57EUF+dL+t
CX1r/TVpwlMJBZiJBwNfwBKVpTe25Awj+2LjQKDU5XkZ4xww9r1Wy5Z3pbMqRO3I
+6gl9eTuYha9E2GTT8D2CpECAwEAAQ==
-----END PUBLIC KEY-----`;