import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CheckIcon(props) {
    return (
        <SvgIcon viewBox="0 0 25 23" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)" strokeWidth="2">
                    <circle className="full-circle" cx="10.5" cy="10.5" r="10.5" stroke="currentColor"></circle>
                    <path className="half-circle" d="M17.7985417,2.52834786 C15.9126819,0.952280962 13.4611533,0 10.78125,0 C4.82693004,0 -5.68434189e-14,4.70101013 -5.68434189e-14,10.5 C-5.68434189e-14,16.2989899 4.82693004,21 10.78125,21 C15.9809923,21 20.3209822,17.4149889 21.3374691,12.6444455" stroke="currentColor"></path>
                    <polyline className="stroke-check" stroke="#3FA83C" points="6.8449707 10.0333333 10.78125 14.2907552 22.8137614 4.14681197"></polyline>
                </g>
            </g>
        </SvgIcon>
    );
};
