import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Paths } from '../../Constants/Paths';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router';
import ProgarantLogo from '../../Assets/Png/logo512.png';
import Button from '@material-ui/core/Button';
import FordwardIcon from '../../Assets/Svg/Forward';
import { put } from 'redux-saga/effects';
import { actions } from '../../Reducers/App';
import { checkEmail } from '../../Reducers/App';
import { useState } from 'react';

const INVALID_REQUIRED = 'Bitte angeben.';
const INVALID_EMAIL = 'Dies ist keine gültige E-Mail Adresse.';
const ERROR_WRONG_EMAIL = 'Die eingetragene E-Mail entspricht nicht der im System hinterlegten E-Mail. Bei Fragen wenden Sie sich bitte an Ihren Händler oder an den Pro Garant Support unter service @progarant.de.';
const styles = theme => ({
    cardContent: {
        padding: '96px 54px 33px 54px',
        '@media (max-width: 500px)': {
            padding: '96px 10px 33px 10px',
        },
    },
    submitButton: {
        padding: 0
    },
    logo: {
        width: '200px',
        height: 'auto',
        position: 'absolute',
        top: '0px'
    },
    input: {
        "& input": {
            color: 'black',

            padding: '1em',
            textAlign: 'left',
            borderTopLeftRadius: '0.3em 0.3em',
            borderTopRightRadius: '0.3em 0.3em',
            width: '100%',
            border: 'none',
            borderBottom: '1px solid #7CB9E8',
            backgroundColor: '#F5F5F5'
        },
        "& input:invalid": {
            borderBottom: '1px solid red',
            color: 'red'
        },
        "& p": {
            textAlign: 'left',
            color: 'red'
        },
        "& input:focus": {
            outline: 'none'
        }
    },
    middleDot: {
        display: 'inline-block',
        height: '0.5em',
        margin: '0 0.2em',
        fontSize: '1.5em'
    }
});
const LinkButton = withStyles(theme => ({
    root: {
        '&:hover': {
            // fontWeight: 'bold',
            backgroundColor: 'transparent'
        }
    },
    label: {
        textTransform: 'none',
        // textDecoration: 'underline'
    }

}))(Button);


class KundenPortal extends React.PureComponent {

    constructor(props) {
        super(props);
        this.props = props
        this.state = {
            email: '',
            garantieId: '',
            errorEmail: false,
            eMailSending: false
        };

        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this)

        const queryParams = new URLSearchParams(window.location.search);
        const garantieId = queryParams.get('garantieid');

        garantieId ? this.state.garantieId = garantieId : this.state.garantieId = false;

    }



    validateRequired(input) {
        if (!input) {
            this.setState({ errorEmail: INVALID_REQUIRED })
            document.getElementById('input-email').setCustomValidity(INVALID_REQUIRED);

            return false
        }
        else {
            this.setState({ errorEmail: false })
            document.getElementById('input-email').setCustomValidity('');

            return true
        }
    }
    validateEmail(inputText) {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (inputText.match(mailformat)) {
            this.setState({ errorEmail: false })
            document.getElementById('input-email').setCustomValidity('');

            return true;
        }
        else {
            this.setState({ errorEmail: INVALID_EMAIL })
            document.getElementById('input-email').setCustomValidity(INVALID_EMAIL);

            return false;
        }
    }


    sendEmail(email) {
        const response = false;
        this.props.checkEmail(this.state.garantieId, this.state.email)
        if (response) {
            return true
        }
        else {
            return false
        }

    }
    handleEmailSubmit() {
        if (!this.validateRequired(this.state.email) || !this.validateEmail(this.state.email)) return

        this.props.checkEmail(this.state.garantieId, this.state.email)

        // if (this.sendEmail(this.state.email)) {
        //     this.setState({ errorEmail: false })
        //     document.getElementById('input-email').setCustomValidity('');

        //     this.props.goTo(Paths.KUNDENPORTAL_LOGIN);
        // }

        // else {
        //     this.setState({ errorEmail: ERROR_WRONG_EMAIL })
        //     document.getElementById('input-email').setCustomValidity(ERROR_WRONG_EMAIL);
        // }
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
        this.validateRequired(event.target.value)
    }

    render() {

        const { classes, kundenportalEmailErrorMessage } = this.props;

        return (
            <Grid container justifyContent="center" style={{ marginTop: '85px' }} spacing={0}>
                <img className={classes.logo} alt='Logo' src={ProgarantLogo}></img>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            {!!this.state.garantieId ? this.renderEmaileingabe() : this.renderError()}
                        </CardContent>
                    </Card>
                </Grid>
                {this.renderFooter()}
            </Grid>
        )

    }
    renderError(){
        return (
            <Grid container spacing={4}>
                {/* <Popup/> */}
                <Grid item xs={12}>
                    <Typography color="primary" style={{ textAlign: 'center' }} variant="h2">
                        Pro Garant Login
                    </Typography>
                    <Typography color="primary" style={{ textAlign: 'center' }} variant="h2">
                        <strong>Garantiebedingungen</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <p style={{ color: 'red' }}>
                        Entschuldigung, es sieht so aus, als ob dieser Link nicht funktioniert.
                    </p>
                    <p style={{ color: 'red' }}>
                        Aber keine Sorge! Bitte scannen Sie einfach den ursprünglichen QR-Code erneut, um sicher auf die gewünschte Seite zu gelangen. Vielen Dank für Ihr Verständnis und Ihre Geduld.
                    </p>
                </Grid>
            </Grid>
        )
    }
    renderEmaileingabe() {
        const { classes, kundenportalEmailErrorMessage } = this.props;
        return (
            <Grid container spacing={4}>
                {/* <Popup/> */}
                <Grid item xs={12}>
                    <Typography color="primary" style={{ textAlign: 'center' }} variant="h2">
                        Pro Garant Login
                    </Typography>
                    <Typography color="primary" style={{ textAlign: 'center' }} variant="h2">
                        <strong>Garantiebedingungen</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <p>Bitte tragen Sie Ihre E-Mail Adresse ein, die Sie bei dem Abschluss Ihrer Garantie angegeben haben:</p>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' }}>
                    <div className={classes.input}>
                        <input
                            id="input-email"
                            className={classes.input}
                            type='text'
                            value={this.state.code}
                            onChange={this.handleEmailChange}
                            placeholder='Bitte E-Mail eingeben'>
                        </input>
                        {this.state.errorEmail && <p style={{ color: 'red' }}>{this.state.errorEmail}</p>}
                        {kundenportalEmailErrorMessage && <p style={{ color: 'red' }}>{ERROR_WRONG_EMAIL}</p>}
                    </div>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right', paddingRight: '10%', paddingLeft: '10%' }}>
                    <Button className={classes.submitButton} disableRipple color="primary" onClick={this.handleEmailSubmit} disabled={this.state.eMailSending}>
                        Abschicken<FordwardIcon />
                    </Button>
                </Grid>
            </Grid>
        )
    }
    renderFooter() {
        const { classes } = this.props;

        return (
            <Grid style={{ marginTop: '3em' }} item xs={12}>
                <Grid item xs={12} container justifyContent="center">
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/" type="button">
                        www.progarant.de
                    </LinkButton>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/datenschutz" type="button">
                        Datenschutz
                    </LinkButton>
                    <span className={classes.middleDot}>.</span>
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/impressum" type="button">
                        Impressum
                    </LinkButton>
                </Grid>

            </Grid>
        )
    }
}

function mapStateToProps(state) {
    const { kundenportalGarantieId, kundenportalEmail, kundenportalEmailErrorMessage } = state.app;
    return {
        kundenportalGarantieId: kundenportalGarantieId,
        kundenportalEmail: kundenportalEmail,
        kundenportalEmailErrorMessage: kundenportalEmailErrorMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo: (path) => dispatch(push(path)),
        checkEmail: (kundenportalGarantieId, kundenportalEmail) => dispatch(checkEmail(kundenportalGarantieId, kundenportalEmail)),
    }
};
KundenPortal.propTypes = {
    classes: PropTypes.object.isRequired,
    goTo: PropTypes.func.isRequired,
    checkEmail: PropTypes.func.isRequired,
    // load: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(KundenPortal));
