import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Box,
    Grid,
    Card,
    CardContent,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
    loadFahrzeugartTarife,loadPreislistenUndGarantiebedingungen,
} from '../../../Reducers/App';
import { motion } from "framer-motion"
import DownloadTable from "./DownloadTable";
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
    cardContent:{
        padding:'30px 30px 40px 30px'
    },

    tableWrapper:{
        paddingTop:'16px',
        borderTop:'2px solid #F3F3F3',
        marginTop:'55px'
    },
    loadingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
    }

});

const DownloadPdfs = ({classes, selectedFahrzeugart, downloadType}) => {

    const dispatch = useDispatch();
    const preislistenUndGarantiebedingungen = useSelector(state => state.app.preislistenUndGarantiebedingungen);
    const fahrzeugartTarife = useSelector(state => state.app.fahrzeugartTarife);
    const [downloads, setDownloads] = useState(null);

    const getUntertitel = (fahrzeugart) => {
        switch(fahrzeugart) {
            case 'PKW':
                return 'PKW (Verbrenner / Hybrid)';
            case 'pkw-elektro':
                return 'PKW (Elektro)';
            case 'Reisemobil':
                return 'Reisemobile';
            case 'Motorrad':
                return 'Motorrad';
            default:
                return 'Mehr Service für Sie';
        }
    }


    const extractFromGarantiebedingungen = (preislistenUndGarantiebedingungen, fahrzeugartTarife) => {
        const categorized = {
            grundvarianten: { titel: 'Grundvarianten', items: [] },
            plusvarianten: { titel: 'Plusvarianten', items: [] },
            sonstige: { titel: 'Sonstige', items: [] }
        };

        const sonstigeIdentifiers = ['gas_kompakt'];

        fahrzeugartTarife.availableGarantien.forEach(garantie => {
            const identifier = garantie.identifier;
            const downloadUri = `garantiebedingung/${identifier}`;

            if (!preislistenUndGarantiebedingungen.garantiebedingung.includes(downloadUri))
                return;

            if (sonstigeIdentifiers.includes(identifier)) {
                categorized.sonstige.items.push({ downloadUri, name: garantie.variant });
            } else {
                const isPlusVariant = garantie.hasPlusTariff;
                if (isPlusVariant)
                    categorized.plusvarianten.items.push({ downloadUri, name: `${garantie.variant} PLUS` });

                categorized.grundvarianten.items.push({ downloadUri, name: garantie.variant });
            }
        });

        return categorized;
    };


    useEffect(() => {
        if (downloadType === 'garantiebedingungen')
            dispatch(loadPreislistenUndGarantiebedingungen());
        dispatch(loadFahrzeugartTarife(selectedFahrzeugart));
    }, [dispatch, selectedFahrzeugart]);

    function getPricelistDownloads(fahrzeugartTarife) {
        let result = {
            gebrauchtwagen: { titel: 'Gebrauchtwagen', items: [] },
            neufahrzeuge: { titel: 'Anschlussgarantie für Neufahrzeuge', items: [] }
        };

        fahrzeugartTarife.availableGarantien.forEach(garantie => {
            const name = garantie.variant.toUpperCase() + (garantie.hasPlusTariff ? " / " +garantie.variant.toUpperCase()+ " PLUS" : "");
            garantie.pricelists.forEach(pricelist => {

                if (pricelist.includes("gebrauchtwagen") || garantie.pricelists.length === 1)
                    result.gebrauchtwagen.items.push({ downloadUri: pricelist, name });

                if (garantie.dependsOnVehicleCondition && (pricelist.includes("neufahrzeug") || garantie.pricelists.length === 1))
                    result.neufahrzeuge.items.push({ downloadUri: pricelist, name });
            });
        });

        return result;
    }

    useEffect(() => {

        if (fahrzeugartTarife && Object.keys(fahrzeugartTarife).length > 0){

            if (downloadType !== 'garantiebedingungen') {

                setDownloads(getPricelistDownloads(fahrzeugartTarife));
            } else if (downloadType === 'garantiebedingungen' && preislistenUndGarantiebedingungen && Object.keys(preislistenUndGarantiebedingungen).length > 0){

                setDownloads( extractFromGarantiebedingungen(preislistenUndGarantiebedingungen, fahrzeugartTarife));
            }
        }


    }, [preislistenUndGarantiebedingungen, fahrzeugartTarife, downloadType]);


    const titel = downloadType === 'garantiebedingungen' ? 'Download Garantiebedingungen' : 'Download Preislisten';

    return (
        <>
            <Grid item xs={12}>
                <Box textAlign="center" style={{ marginBottom: '25px' }}>
                    <Typography color="primary" style={{marginTop:20}} variant="h2">{titel}</Typography>
                    <Typography style={{marginTop:'20px'}}>{getUntertitel(selectedFahrzeugart)}</Typography>
                </Box>
            </Grid>
            <motion.div
                initial={{opacity: 0, scale: .9, y: 10}}
                animate={{opacity: 1, scale: 1, y: 0}}
                exit={{opacity: 0}}
                transition={{delay: 0}}
            >
                <Card>
                    <CardContent>
                        <div className={classes.tableWrapper}>
                            {downloads ? (
                                Object.entries(downloads).map(([key, group]) => (
                                    group.items.length > 0 && <DownloadTable key={key} downloads={group.items} titel={group.titel} />
                                ))
                            ) : (
                                <div className={classes.loadingIndicator}>
                                    <CircularProgress/>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </motion.div>
        </>
    );
}

DownloadPdfs.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedFahrzeugart: PropTypes.string.isRequired,
    downloadType: PropTypes.string.isRequired,
};

export default withStyles(styles)(DownloadPdfs);
