import React from 'react';
import Grid from '@material-ui/core/Grid';
import KontaktAdd from '../Templates/Kontakt/KontaktAdd';
import NewsLatest from '../Templates/News/NewsLatest';

class Kontakt extends React.PureComponent {

    render() {

        return (
            <Grid container spacing={5}>
                <Grid item xs={12} lg={8}>
                    <KontaktAdd />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <NewsLatest />
                </Grid>
            </Grid>
        );
    }
}

export default Kontakt;