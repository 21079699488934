import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function OverviewIcon(props) {
    return (
        <SvgIcon viewBox="0 0 26 22" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g  transform="translate(-17.000000, -152.000000)" stroke="currentColor" strokeWidth="1.5">
                    <g  transform="translate(18.000000, 153.000000)">
                        <g  transform="translate(-0.000000, 0.000000)">
                            <path d="M21.5983077,15.7670256 L2.05112821,15.7670256 C0.918307692,15.7670256 -0.000153846154,14.8485641 -0.000153846154,13.7157436 L-0.000153846154,2.05215385 C-0.000153846154,0.918820513 0.918820513,-0.000153846154 2.05215385,-0.000153846154 L21.5983077,-0.000153846154 C22.731641,-0.000153846154 23.6501026,0.918820513 23.6501026,2.05215385 L23.6501026,13.7152308 C23.6501026,14.8480513 22.731641,15.7670256 21.5983077,15.7670256 Z" ></path>
                            <path d="M12.028,15.7670256 L12.028,19.0106154" ></path>
                            <path d="M6.80235897,19.3429744 L17.253641,19.3429744" ></path>
                            <path d="M0.0225128205,2.59794872 L23.507641,2.59794872" ></path>
                            <path d="M0.0225128205,12.6885641 L23.507641,12.6885641" ></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}