import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    const allowedDecimalSeparators = [','];
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale="0"
            allowedDecimalSeparators={allowedDecimalSeparators}
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const NumberTextFieldWrapper = ({input: {name, onChange, value, ...restInput}, meta, ...rest}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || meta.submitError);

    return (
        <TextField
            name={name}
            helperText={showError ? meta.error || meta.submitError : undefined}
            error={showError}
            inputProps={restInput}
            onChange={onChange}
            value={value}
            {...rest}
            InputProps={{
                inputComponent: NumberFormatCustom,
                required: false,
                ...rest.InputProps,
            }}
        />
    );
};

export default NumberTextFieldWrapper;
