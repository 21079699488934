import {createTheme } from '@material-ui/core/styles';

export default createTheme({
    overrides: {
        MuiTooltip:{
            tooltip: {
                color:'#ffffff',
                backgroundColor:'#00468E'
            }
        },
        MuiBadge:{
            root:{
                verticalAlign:'baseline'
            }
        },
        MuiMenuItem:{
          root:{
              fontSize:'1rem',
              minHeight:'auto'
          }
        },
        MuiSnackbarContent:{
            root:{
                backgroundColor:'#3FA73C',
                maxWidth:'380px',
                alignItems:'flex-start',
                flexWrap:'nowrap',
                paddingLeft:'24px',
                paddingRight:'24px'
            }
        },
        MuiTypography:{
          root:{
              '& A':{
                  color:'#00468E',
                  textDecoration:'underline'
              }
          }
        },
        MuiSpeedDialAction:{
            fab:{
                color:'#ffffff',
                backgroundColor:'#00468E',
                '&:hover': {
                    backgroundColor:'#00468E',
                }
            },
            staticTooltipLabel:{
                whiteSpace:'nowrap',
                color:'#00468E'
            }
        },
        MuiSwitch:{
          root:{
              alignSelf:'flex-start'
          }
        },
        MuiTable:{
          root:{
              marginBottom:'47px'
          }
        },
        MuiTableRow:{
          root:{
              '&:nth-child(odd) TD':{
                  backgroundColor:'#F4F4F4'
              }
          },
            hover:{
              '&:hover TD':{
                  backgroundColor:'#00000012'
              }

            }
        },
        MuiTableCell:{
          root:{
              fontSize:'16px',
              padding:'6px 13px 6px 13px',
              borderBottom:'none'
          }
        },
        MuiButton:{
          label:{
              textTransform:'uppercase',
              fontSize:'1rem',
              '& svg':{
                  width:45,
                  height:45,
                  marginLeft:'10px'
              }
          },
            textPrimary:{
                '&:hover':{
                    backgroundColor:'transparent'
                },
            },
            textSecondary:{
                color:'#00468E',
                '& svg':{
                    width:45,
                    height:45,
                    marginRight:'10px',
                    marginLeft:'0px'
                },
                '&:hover':{
                    backgroundColor:'transparent'
                }
            }
        },
        MuiInput:{
            underline:{
                '&:before':{
                    borderBottom:'2px solid #00468E'
                },
                '&:hover:not(.Mui-disabled):before':{
                    borderBottom:'2px solid #00468E'
                }
            },
            input:{
                color:'#3F3F3F',
                fontSize:'14px',
                paddingLeft:'10px',
                paddingRight:'10px'
            },
        },
        MuiFormLabel:{
            root:{
                color:'#3F3F3F',
                fontSize:'14px',
                paddingLeft:'10px',
                paddingRight:'10px'
            }
        },
        MuiSelect:{
          select:{
              '&:focus':{
                  backgroundColor:'transparent'
              }
          }
        },
        MuiRadio:{
            root:{
                color:'#00468E',
                padding:'15px 23px 15px 9px',
                '&:hover':{
                    backgroundColor:'transparent'
                },
                "&$checked": {
                    "&:hover": {
                        "backgroundColor": "transparent !important"
                    }
                }
            },
        },
        MuiCheckbox:{
            root:{
                color:'#00468E',
                padding:'15px 23px 15px 9px',
                '&:hover':{
                    backgroundColor:'transparent'
                },
                "&$checked": {
                    "&:hover": {
                        "backgroundColor": "transparent !important"
                    }
                }
            },
        },
        MuiCard:{
            root:{
                boxShadow:'none',
                height:'100%',
                '&:hover':{
                    boxShadow:'0 6px 20px 0 rgba(0,0,0,0.15)'
                }
            },
        },
        MuiCardActionArea:{
            focusHighlight:{
                backgroundColor:'#ffffff',
            }
        }
    },
    spacing: factor => [0, 4, 8, 16, 32,46,64][factor],
    palette:{
        text: {
            primary:'#3F3F3F'
        },
        primary:{
            main:'#00468E'
        },
        background:{
            default:'#F3F3F3'
        },
    },
    typography:{
        h1: {
            fontSize:`${(45 / 14)}em`,
            lineHeight:'50px'
        },
        h2: {
            fontSize:`${(30 / 14)}em`
        },
        h4: {
            fontSize:`${(18 / 14)}em`
        },
        subtitle1: {
            fontSize:`${(18 /11)}em`
        }
    },
    shape:{
        borderRadius: 6
    }
});
