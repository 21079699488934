import React from 'react';
import GarantieWizard from '../Templates/Garantie/GarantieAdd';
import Steps from '../Templates/Garantie/GarantieSteps';
import { Field } from 'react-final-form';
import InputCard from '../Partials/InputCard';
import PkwIcon from '../../Assets/Svg/Pkw';
import PkwElektroIcon from '../../Assets/Svg/Pkw-elektro';
import ReisemobilIcon from '../../Assets/Svg/Reisemobil';
import MotorradIcon from '../../Assets/Svg/Motorrad';
import {
    Card,
    CardContent,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Hidden,
    InputAdornment,
    Tooltip
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Radio,Checkbox,Select} from 'final-form-material-ui';
import {FormControlLabel,RadioGroup,FormControl,Box,Grid,MenuItem,FormLabel} from '@material-ui/core';
import { connect } from 'react-redux';
import Switch from '../../Helpers/Switch';
import NumberTextField from '../../Helpers/NumberTextField';
import TextField from '../../Helpers/DefaultTextField';
import UploadField from '../../Helpers/DefaultUploadField';
import CurrencyField from '../../Helpers/CurrencyTextField';
import DateField from '../../Helpers/DateTextField';
import * as EmailValidator from 'email-validator';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import deLocale from "date-fns/locale/de";
import {format } from 'date-fns';
import {loadTarife,loadPdfFromPath,openPdfFromPath} from '../../Reducers/App';
import Numeral from 'numeral';
import '../../Helpers/NumeralConfig';
import { Link } from 'react-router-dom';
import {Paths} from '../../Constants/Paths';
import Skeleton from '@material-ui/lab/Skeleton';
import parseISO from 'date-fns/parseISO'
import { motion,AnimatePresence } from "framer-motion"
import {getMehrzahlFromFahrzeugart} from '../../Helpers/StringFunctions';
import {getFormState} from '../../Reducers/Form';
import { FormSpy } from 'react-final-form'
import TextButton from "../Partials/TextButton";
import FormHelperText from "@material-ui/core/FormHelperText";
import InfoIcon from '@material-ui/icons/Info';




Numeral.locale('de');

const Error = ({ name,style,...rest }) => (
    <Field
        name={name}
        subscribe={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
            touched && error ? <Grid item xs={12}><Typography style={{...{textAlign:'center',color:'#E00B1E'}, ...style}}>{error}</Typography></Grid> : null
        }
    />
);

const required = value => {
    return value ? undefined : 'Bitte angeben'
} ;

const validateHsn = value => {
    if (!value) return 'Bitte angeben'
    if (value.length !== 4) return 'Die Nummer muss 4-stellig sein.'
} ;

const validateTsn = value => {
    if (!value) return 'Bitte angeben'
    if (value.length !== 3) return 'Die Nummer muss 3-stellig sein.'
} ;

const requiredFahrgestellnummer = value => {
    if (!value) return 'Bitte angeben'
    if (value.length !== 17) return 'Die Nummer muss 17-stellig sein.'
    return undefined
}

const requiredSelection = value => (value ? undefined : 'Bitte auswählen');
const validEmail = value => (value ? ((EmailValidator.validate(value) === true) ? undefined : "ungültige E-Mail-Adresse") : 'Bitte angeben');
const validGeburtsdatum = value => {
    if (value) {
        const currentDate = new Date();
        const maxDate = new Date(1899, 12, 31);
        const checkDate = ((value instanceof Date && !isNaN(value)) ? value : parseISO(value));

        if (checkDate instanceof Date && !isNaN(checkDate) && checkDate >= maxDate) {
            const ageDiff = currentDate - checkDate;
            const ageDate = new Date(ageDiff);
            const age = Math.abs(ageDate.getUTCFullYear() - 1970);

            if(age >= 18) return undefined;
            else return "Das Mindestalter beträgt 18 Jahre.";
            
        } else {
            return "ungültiges Datum";
        }

    } else {
        return undefined;
    }
};

const requiredDate = value => {
    if(value) {
        const maxDate = new Date(1899, 12, 31);
        const checkDate = ((value instanceof Date && !isNaN(value)) ? value : parseISO(value));

        if(
            checkDate instanceof Date &&
            !isNaN(checkDate) &&
            checkDate >= maxDate
        ) {
            return undefined;
        }else{
            return "ungültiges Datum";
        }

    }else{
        return "Bitte angeben";
    }
};


const extractRule = (rules, rule) => {
    if (typeof rules === 'object' && rules !== null && rule in rules) {
        return rules[rule].toLowerCase();
    }
    return undefined;
}

const extractMaxFromRule = (rules, ruleName, ruleKey = 'max') => {
    const strRule = extractRule(rules, ruleName);
    if (!strRule) return undefined;

    const max = parseInt(strRule.replace(new RegExp(`.*${ruleKey}:`), ""), 10);
    return isNaN(max) ? undefined : max;
}

const extractZylinderRule = (rules, ruleName, ruleKey = 'anfrage') => {
    const strRule = extractRule(rules, ruleName);
    if (!strRule) return undefined;

    const ruleParts = strRule.split("|");
    const customRulePart = ruleParts.find(part => part.startsWith(ruleKey));

    if (!customRulePart) return undefined;

    const [_, ZylinderAnzahlPreiserhoehungAb, ZylinderAnzahlPreisanfrageAb] = customRulePart.split(":");

    return {
        ZylinderAnzahlPreiserhoehungAb: parseInt(ZylinderAnzahlPreiserhoehungAb, 10),
        ZylinderAnzahlPreisanfrageAb: parseInt(ZylinderAnzahlPreisanfrageAb, 10)
    };
}

const extractMinFromRule = (rules, ruleName, ruleKey = 'min') => {
    const strRule = extractRule(rules, ruleName);
    if (!strRule) return undefined;

    const min = parseFloat(strRule.replace(new RegExp(`.*${ruleKey}:`), ""));
    return isNaN(min) ? undefined : min;
}

const validateMax = (value, ruleName, vehicleCharged, errorTemplate, ruleKey = 'max') => {

    const max = extractMaxFromRule(vehicleCharged, ruleName, ruleKey);
    if (!max || value <= max) return undefined;

    return errorTemplate.replace('#max#', max);
}

const calculateAge = (birthday) => {
    const ageDiff = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDiff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const normalizeHsn = value => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    return onlyNums;
};

const normalizeTsn = value => {
    if (!value) return value;
    const onlyNums = value.replace(/[^A-Za-z0-9]/g, "");
    return String(onlyNums).toUpperCase();
};

const normalizeFahrgestellnummer = value => {
    if (!value) return value;
    const onlyNums = value.replace(/[^A-Za-z0-9]/g, "");
    return String(onlyNums).toUpperCase();
};

const normalizeKennzeichen = value => {
    if (!value) return value;
    const onlyNums = value.replace(/[^A-Za-z0-9äöüÄÖÜ\- ]/g, "");
    return String(onlyNums).toUpperCase();
};

const styles = theme => ({
    headline:{
        fontSize:'18px',
        color:'#00468E',
        borderBottom:'2px solid #F3F3F3',
        marginBottom:12,
        paddingBottom:12
    },
    subline:{
        fontSize:'14px',
        maxWidth:'535px',
        lineHeight: '24px'
    },
    intro:{
        fontSize:'18px',
        //maxWidth:'590px',
        marginBottom:15,
        lineHeight:'24px'
    },
    error:{
      color:'#E00B1E'
    },
    card:{
        border:'2px solid #ffffff'
    },
    cardActive:{
        border:'2px solid #00468E'
    },
    cardContent:{
        padding:'37px 26px 67px 26px',
        '&:last-child':{
            paddingBottom:67
        }
    },
    tarifLabel:{
        color:'#00468E',
        fontSize:`${theme.typography.round(18 /16)}em !important`
    },
    tarifTypLabel:{
        color:'#00468E',
        fontSize:`${theme.typography.round(18 /16)}em !important`
    },
    tarifAcceptLabel:{
        color:'#00468E',
        fontSize:`${theme.typography.round(18 /16)}em !important`
    },
    turboloaderLabel:{
        color:'#00468E',
        fontSize:`${theme.typography.round(18 /16)}em !important`,
        marginRight:'27px',
        '& .MuiButtonBase-root':{
            paddingRight:'15px'
        }
    },
    tarifInfo:{
        paddingLeft:'47px',
        lineHeight:'26px',
        '& ol':{
            paddingLeft:'15px'
        }
    },
    formControl:{
        fontSize:'14px',
        margin:0,
    },
    legend:{
        fontSize:'18px',
        marginBottom:'17px',
        padding:0
    },
    uploadLabel:{
        fontSize: '14px',
        color: '#3F3F3F',
        letterSpacing:'0',
        lineHeight: '26px',
        paddingTop:8,
        maxWidth:595
    },
    info:{
        color:'#00468E',
        textTransform:'uppercase',
        textDecoration:'underline'
    },
    textButton:{
        border:0,
        fontSize:'1rem',
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight:'2em',
        textDecoration:'underline',
        color:'#00468E',
        backgroundColor:'#ffffff',
        margin:0,
        cursor:'pointer',
        padding:0,
        '&:focus':{
            outline:'none'
        }
    },
    tableCellOdd:{
        backgroundColor:'#F4F4F4 !important'
    },
    tableCellEven:{
        backgroundColor:'#ffffff !important'
    },
    tableClassInfo:{
        color:'#E00B1E',
        padding:0,
        paddingRight:'0 !important'
    },
    tableClassInfoInnerWrap:{
        color:'#E00B1E',
        border:'1px solid #E00B1E',
        padding:'6px 16px 6px 13px',
        display:'block',
        marginBottom:'1rem'
    },
    uploadRoot:{
        display:'flex',
        alignItems:'flex-start'
    },
    tooltip: {
        fontSize: '1.2em',
    },
});



class Garantie extends React.PureComponent {

    constructor(props) {
        super(props);
        this.downloadUebergabeprotokoll          = this.downloadUebergabeprotokoll.bind(this);
        this.downloadInspektionscheckliste       = this.downloadInspektionscheckliste.bind(this);
        this.downloadGarantie                    = this.downloadGarantie.bind(this);
        this.handleDownloadCLick                 = this.handleDownloadCLick.bind(this)
        this.state = {
            isFreeOfCharge: '1',
            showKwHinweis: false,
            showZylinderHinweis: 'kein',
            showHsnHinweis: false,
            showTsnHinweis: false,
        };
        
    }
    componentDidUpdate(prevProps) {
        if (this.props.formState.values.page !== prevProps?.formState?.values?.page) {
            if (this.props.formState.values.page === 0) {
                this.setState({
                    isFreeOfCharge: '1',
                    showKwHinweis: false,
                    showZylinderHinweis: '',
                    showHsnHinweis: false,
                    showTsnHinweis: false,
                });
            }
        }

        const maxKw = this.props?.vehicleCharged?.kw ? extractMaxFromRule(this.props.vehicleCharged, 'kw', 'max') : null;
        this.setState({ showKwHinweis: maxKw && this.props.formState?.values?.vehicle?.kw > maxKw });

        const maxZylinderAnzahl = this.props?.vehicleCharged?.zylinderAnzahl ? extractZylinderRule(this.props.vehicleCharged, 'zylinderAnzahl', 'anfrage') : null;

        let zylinderAnzahlHinweis = '';

        zylinderAnzahlHinweis = maxZylinderAnzahl && maxZylinderAnzahl?.ZylinderAnzahlPreiserhoehungAb && this.props.formState?.values?.vehicle?.zylinderAnzahl > maxZylinderAnzahl?.ZylinderAnzahlPreiserhoehungAb ? 'Hinweis: Bitte beachten: Fahrzeuge mit mehr als ' + maxZylinderAnzahl?.ZylinderAnzahlPreiserhoehungAb + ' Zylindern sind aufpreispflichtig!' : zylinderAnzahlHinweis;

        zylinderAnzahlHinweis = maxZylinderAnzahl && maxZylinderAnzahl?.ZylinderAnzahlPreisanfrageAb && this.props.formState?.values?.vehicle?.zylinderAnzahl > maxZylinderAnzahl?.ZylinderAnzahlPreisanfrageAb ? 'Hinweis: Bitte die Annahme / den Preis in der Pro Garant Zentrale anfragen!' : zylinderAnzahlHinweis;

        this.setState({ showZylinderHinweis: zylinderAnzahlHinweis });

        this.setState({ isFreeOfCharge: this.props.formState?.values?.garantiedaten?.freeOfCharge ?? '0' });
    }

    bool2german(val){
        return ((parseInt(val,10) === 1 || val === true) ? 'Ja' : 'Nein')
    }

    getValFromObj(obj,field){
        return ((obj.hasOwnProperty(field)) ? obj[field] : '-');
    }

    downloadUebergabeprotokoll(e){
        e.preventDefault();
        if(this.props.pdf.hasOwnProperty('uebergabeprotokoll')){
            this.props.getPdfFromPath(this.props.pdf.uebergabeprotokoll,'uebergabeprotokoll.pdf');
        }
    }

    downloadInspektionscheckliste(e){
        e.preventDefault();
        if(this.props.pdf.hasOwnProperty('inspektionscheckliste')){
            this.props.getPdfFromPath(this.props.pdf.inspektionscheckliste,'inspektionscheckliste.pdf');
        }
    }

    downloadGarantie(e){
        e.preventDefault();
        if(this.props.pdf.hasOwnProperty('garantieabschluss')){
            this.props.getPdfFromPath(this.props.pdf.garantieabschluss,this.props.gar_nr+'.pdf');
        }
    }

    handleDownloadCLick(e){
      e.preventDefault();
      this.props.getPdfFromPath('https://www.progarant.de/fileadmin/user_upload/downloads/infoschreiben-zu-inspektions-wartungsnachweisen-final.pdf','infoschreiben-zu-inspektions-wartungsnachweisen-final.pdf');
    }

    render() {
        const { isFreeOfCharge, showKwHinweis, showZylinderHinweis } = this.state;
        const isEntgeltlich = isFreeOfCharge === '0';


        let availableTarife = [];
        const { formState, classes, selectedGarantieart, initialValues, tarife, conditions, customer, vehicle, garantiedaten, garantietarif, gar_nr, isPlusTariff, garantieValidationRules, chargedImpacts, tarifeLoading, conditionLoading, vehicleValidationRules, fahrzeugart, garantieart, remarks, vehicleCharged } = this.props;
        const emptyVehicleValidationRules = Object.keys(vehicleValidationRules).length === 0 && vehicleValidationRules.constructor === Object;
        const fahrzeugartMehrzahl = getMehrzahlFromFahrzeugart(fahrzeugart);
        const {customer:customerState} = formState.values || {customer:{}};
        if (garantiedaten?.freeOfCharge === undefined) garantiedaten.freeOfCharge = '1';

        const validateGesamtLaufleistung = (value) => {

            if (!value) return undefined;

            return validateMax(
                value,
                'gesamtlaufleistung',
                vehicleValidationRules,
                `Gesamtlaufleistung ist zu hoch (max. #max#)`,
                'max'
            );
        }

        const validateErstzulassung = (value) => {

            if (!value) return undefined;

            const reqDateValidation = requiredDate(value);
            if(reqDateValidation)
                return reqDateValidation;

            const erstzulassungDate = ((value instanceof Date && !isNaN(value)) ? value : parseISO(value));

            const alter = calculateAge(erstzulassungDate);

            return validateMax(
                alter,
                'erstzulassung',
                vehicleValidationRules,
                `Fahrzeug ist zu alt. Limit von #max# Jahren wurde überschritten."`,
                'maxyears'
            );
        }

        const validatePrice = (value) => {
            if (!value) return 'Bitte angeben';

            if (isNaN(value)) return 'Der Preis muss eine Zahl sein.';

            const min = extractMinFromRule(vehicleValidationRules, 'price', 'min');
            const max = extractMaxFromRule(vehicleValidationRules, 'price', 'max');

            if (min && value < min) return `Der Preis darf nicht kleiner als ${min} sein.`;
            if (max && value > max) return `Der Preis darf nicht größer als ${max} sein.`;

            return undefined;
        }

        const validateKw = (value) => {
            if (!value) return 'Bitte angeben';

            if (isNaN(value)) return 'Die Angabe muss eine Zahl sein.';

            const min = extractMinFromRule(vehicleValidationRules, 'kw', 'min');
            const max = extractMaxFromRule(vehicleValidationRules, 'kw', 'max');

            if (min && value < min) return `Die Angabe darf nicht kleiner als ${min} sein.`;
            if (max && value > max) return `Die Angabe darf nicht größer als ${max} sein.`;

            return undefined;
        }

        const validatePs = (value) => {
            if (!value) return 'Bitte angeben';

            if (isNaN(value)) return 'Die Angabe muss eine Zahl sein.';

            const min = extractMinFromRule(vehicleValidationRules, 'ps', 'min');
            const max = extractMaxFromRule(vehicleValidationRules, 'ps', 'max');

            if (min && value < min) return `Die Angabe darf nicht kleiner als ${min} sein.`;
            if (max && value > max) return `Die Angabe darf nicht größer als ${max} sein.`;

            return undefined;
        }

        const validateTachoKm = (value) => {
            if (!value) return 'Bitte angeben';

            if (isNaN(value)) return 'Die Angabe muss eine Zahl sein.';

            const min = extractMinFromRule(vehicleValidationRules, 'km', 'min');
            const max = extractMaxFromRule(vehicleValidationRules, 'km', 'max');

            if (min && value < min) return `Die Angabe darf nicht kleiner als ${min} sein.`;
            if (max && value > max) return `Die Angabe darf nicht größer als ${max} sein.`;

            return undefined;
        }

        const pkwTitle = (<>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ margin: '0 8px 0 0' }} >PKW</p>
                <p style={{ margin: '0 8px 0 0', color: 'black', fontSize: '0.9em' }}>- Verbrenner/Hybrid</p>
            </div></>);
        const pkwElektroTitle = (<>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ margin: '0 10px 0 0' }} >PKW</p>
                <p style={{ margin: '0 10px 0 0', color: 'black', fontSize: '0.9em' }}>- Elektro</p>
            </div></>)

        const fahrzeugarten = {
            1: {title: pkwTitle, value: "PKW", icon: <PkwIcon /> },
            2: {title: pkwElektroTitle, value: "pkw-elektro", icon: <PkwElektroIcon /> },
            3: {title: 'Reisemobil', value: "Reisemobil", icon: <ReisemobilIcon />},
            4: {title: 'Motorrad', value: "Motorrad", icon: <MotorradIcon />},
            
        };

        for (let i = 0; i < tarife.length; i++) {
            availableTarife.push({
                value:tarife[i]['identifier'],
                title:tarife[i]['name'],
                isPlusTariff:false
            });

            if(!!tarife[i]['hasPlusTariff']){
                availableTarife.push({
                    value:tarife[i]['identifier']+"__plus",
                    title:tarife[i]['namePlus'],
                    isPlusTariff:true
                });
            }
        }
        const grundtarife = availableTarife.filter((item,index) => (item.isPlusTariff === false));
        const plustarife = availableTarife.filter((item,index) => (item.isPlusTariff === true));

        const isGrundtarif = grundtarife.reduce((accumulator,item) => (accumulator || item.value === selectedGarantieart),false)
        const isPlustarif = plustarife.reduce((accumulator,item) => (accumulator || item.value === selectedGarantieart),false)

        const erstzulassung = (!!vehicle.erstzulassung && !!new Date(vehicle.erstzulassung)) ? new Date(vehicle.erstzulassung) : null;
        const verkaufsdatum = (!!vehicle.verkaufsdatum && !!new Date(vehicle.verkaufsdatum)) ? new Date(vehicle.verkaufsdatum) : null;
        const birthday = (!!customer.birthday && !!new Date(customer.birthday)) ? new Date(customer.birthday) : null;
        const wiederzulassung = (!!garantiedaten.wiederzulassung && !!new Date(garantiedaten.wiederzulassung)) ? new Date(garantiedaten.wiederzulassung) : null;
        const endWerksgarantieDate = (!!garantiedaten.endWerksgarantieDate && !!new Date(garantiedaten.endWerksgarantieDate)) ? new Date(garantiedaten.endWerksgarantieDate) : null;

        const overview = this.getOverview(fahrzeugart, customerState, customer, birthday, vehicle, erstzulassung, garantietarif, isPlusTariff, garantiedaten, garantieValidationRules, garantieart, wiederzulassung, endWerksgarantieDate, verkaufsdatum);

        return (
            <Grid container spacing={5}>
                <Grid item xs={12} md={8}>
                    <GarantieWizard initialValues={initialValues}>
                        <GarantieWizard.Page title="1. Auswahl Fahrzeugart" subtitle="Bitte die gewünschte Fahrzeugart wählen:">
                            <Grid container spacing={5}>
                                <AnimatePresence>
                                    {Object.keys(fahrzeugarten).map((item,index) => (
                                        <Grid key={index} item xs={12} sm={6}  >
                                            <motion.div
                                                initial={{ opacity: 0,scale:.9,y:50 }}
                                                animate={{ opacity: 1,scale:1,y:0 }}
                                                exit={{ opacity: 0 }}
                                                key={index}
                                                transition={{delay: .1*(index+1) }}
                                            >
                                                <Field
                                                    title={fahrzeugarten[item].title ?? ''}
                                                    icon={fahrzeugarten[item].icon}
                                                    value={fahrzeugarten[item].value}
                                                    name="fahrzeugart"
                                                    type="radio"
                                                    validate={required}
                                                >
                                                    {props => {
                                                        return <InputCard {...props} />
                                                    }}
                                                </Field>
                                            </motion.div>
                                        </Grid>
                                    ))}
                                    <Error name="fahrzeugart" />
                                </AnimatePresence>
                            </Grid>
                        </GarantieWizard.Page>
                        <GarantieWizard.Page title="2. Auswahl Garantieart" subtitle="Bitte die gewünschte Garantieart wählen:">
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={(plustarife.length || !!tarifeLoading) ? 6 : 12}>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:50,height:'100%' }}
                                        animate={{ opacity: 1,scale:1,y:0}}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .1 }}
                                    >
                                    <Card className={`${classes.card} `+(isGrundtarif ? `${classes.cardActive}` : '')}>
                                        <CardContent className={`${classes.cardContent}`}>
                                            {!!tarifeLoading && <Skeleton style={{margin:'0 auto',marginBottom:'55px'}} width="60%" height={30} />}
                                            {!!tarifeLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                            {!!tarifeLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                            {!!tarifeLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                            {!!tarifeLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                            {!tarifeLoading && <Typography color="primary" style={{marginBottom:55,textAlign:'center'}} variant="h2">Grundvarianten</Typography>}
                                            {!tarifeLoading && <RadioGroup>
                                                    {grundtarife.map((tarif,index) => (
                                                        <FormControlLabel key={tarif.value}
                                                            label={tarif.title}
                                                            className={classes.tarifLabel}
                                                            control={
                                                                <Field
                                                                    name="garantieart_virt"
                                                                    type="radio"
                                                                    value={tarif.value.toString()} >
                                                                    {props => {
                                                                        return <Radio disableRipple color="primary" {...props} />
                                                                    }}
                                                                </Field>
                                                            }
                                                        />
                                                    )
                                                )}
                                                </RadioGroup>}
                                        </CardContent>
                                    </Card></motion.div>
                                </Grid>
                                {(!!plustarife.length || !!tarifeLoading) && <Grid item xs={12} sm={6}>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:50,height:'100%' }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .2 }}
                                    >
                                    <Card className={`${classes.card} `+(isPlustarif ? `${classes.cardActive}` : '')}>
                                        <CardContent className={classes.cardContent}>
                                            {!!tarifeLoading && <Skeleton style={{margin:'0 auto',marginBottom:'55px'}} width="60%" height={30} />}
                                            {!!tarifeLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                            {!!tarifeLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                            {!!tarifeLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                            {!!tarifeLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                            {!tarifeLoading && <Typography color="primary" style={{marginBottom:55,textAlign:'center'}} variant="h2">Plusvarianten</Typography>}
                                            {!tarifeLoading && <RadioGroup>
                                                {plustarife.map((tarif,index) => (
                                                        <FormControlLabel key={tarif.value.toString()}
                                                                          label={tarif.title}
                                                                          className={classes.tarifLabel}
                                                                          control={
                                                                              <Field
                                                                                  name="garantieart_virt"
                                                                                  type="radio"
                                                                                  value={tarif.value.toString()} >
                                                                                  {props => {
                                                                                      return <Radio disableRipple color="primary" {...props} />
                                                                                  }}
                                                                              </Field>
                                                                          }
                                                        />
                                                    )
                                                )}
                                            </RadioGroup>}
                                        </CardContent>
                                    </Card></motion.div>
                                </Grid>}
                                <Error name="garantieart" />
                            </Grid>
                        </GarantieWizard.Page>
                        <GarantieWizard.Page title={`3. Auswahl und Bedingungen für ${fahrzeugartMehrzahl}`} subtitle="Bitte die gewünschte Garantieart wählen:">
                            <Grid container spacing={5}>
                                <Grid xs={12} item>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:50 }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .2 }}
                                    >
                                        <Card>
                                            <CardContent className={classes.cardContent}>
                                                {!!conditionLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                                {!!conditionLoading && <Skeleton style={{margin:'20px 0 20px 0'}} width="50%" height={24} />}
                                                {!!conditionLoading && <Skeleton style={{margin:'5px 0 5px 47px'}} width="70%" height={24} />}
                                                {!!conditionLoading && <Skeleton style={{margin:'5px 0 5px 47px'}} width="70%" height={24} />}
                                                {!!conditionLoading && <Skeleton style={{margin:'5px 0 5px 47px'}} width="70%" height={24} />}
                                                {!!conditionLoading && <Skeleton style={{margin:'5px 0 5px 47px'}} width="70%" height={24} />}
                                                {!!conditionLoading && <Skeleton style={{margin:'5px 0 5px 47px'}} width="70%" height={24} />}
                                                {!conditionLoading && !!conditions.length && <RadioGroup>
                                                    {conditions.map((condition,index) => (
                                                        <div key={condition.value.toString()}>
                                                            <FormControlLabel
                                                                label={condition.name}
                                                                className={classes.tarifTypLabel}
                                                                control={
                                                                    <Field
                                                                        name="garantietyp"
                                                                        type="radio"
                                                                        value={condition.value.toString()} >
                                                                        {props => {
                                                                            return <Radio disableRipple color="primary" {...props} />
                                                                        }}
                                                                    </Field>
                                                                }
                                                            />
                                                            {!!condition.policy && !!condition.policy.length && <Box className={classes.tarifInfo}>
                                                                {condition.policy.map((policy,index) => (
                                                                    <div key={index}>{policy}</div>
                                                                ))}
                                                            </Box>}
                                                        </div>
                                                    ))}
                                                </RadioGroup>}
                                                {!conditionLoading && <Box className={classes.tarifInfo}>
                                                    <FormControlLabel
                                                        label="Ich habe die Bedingungen gelesen und stimme zu."
                                                        className={classes.tarifAcceptLabel}
                                                        control={
                                                            <Field
                                                                name="tarifTypAccept"
                                                                type="checkbox"
                                                            >
                                                                {props => {
                                                                    return <Checkbox disableRipple color="primary" {...props} />
                                                                }}
                                                            </Field>
                                                        }
                                                    />
                                                </Box>}
                                            </CardContent>
                                        </Card>
                                    </motion.div>
                                </Grid>
                                <Error name="garantietyp" />
                            </Grid>
                        </GarantieWizard.Page>
                        <GarantieWizard.Page title="4. Eingabe Garantienehmer" subtitle="Achtung! Es können nur Anträge bearbeitet werden, die vollständig ausgefüllt sind.">
                            <Grid container spacing={5}>
                                <Grid xs={12} item>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:10 }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .2 }}
                                    >
                                    <Card>
                                        <CardContent style={{paddingBottom:'44px'}} className={classes.cardContent}>
                                            <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <Field
                                                            fullWidth
                                                            name="customer.title"
                                                            component={Select}
                                                            validate={requiredSelection}
                                                            label="Anrede *"
                                                            formControlProps={{ fullWidth: true }}
                                                        >
                                                            <MenuItem value="Herr">Herr</MenuItem>
                                                            <MenuItem value="Frau">Frau</MenuItem>
                                                            <MenuItem value="Firma">Firma</MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    <Hidden smDown>
                                                        <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                            &nbsp;
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} md={customerState.title === 'Firma' ? 12 : 6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name="customer.surname"
                                                                type="text"
                                                                label={customerState.title === 'Firma' ? "Firmenname" : "Nachname"}
                                                            >
                                                                {props => {
                                                                    return (
                                                                        <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    {customerState.title !== 'Firma' && <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                validate={required}
                                                                required
                                                                name="customer.prename"
                                                                type="text"
                                                                label="Vorname"
                                                            >
                                                                {props => {
                                                                        return <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>}
                                                    {customerState.title !== 'Firma' && <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                name="customer.birthday"
                                                                type="text"
                                                                validate={validGeburtsdatum}
                                                                disableFuture
                                                                autoOk
                                                                label="Geb. Datum"
                                                                
                                                            >{props => {
                                                                    return <DateField
                                                                        {...props}
                                                                        input={{
                                                                            ...props.input,
                                                                            onBlur: event => {},
                                                                        }}
                                                                    />
                                                            }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>}
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name="customer.telephone"
                                                                type="text"
                                                                label="Telefon"
                                                            >
                                                                {props => {
                                                                        return <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}

                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                name="customer.mail"
                                                                validate={validEmail}
                                                                type="text"
                                                                label="E-Mail"
                                                            >
                                                                {props => {
                                                                        return <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name="customer.street"
                                                                type="text"
                                                                label="Straße und Haus-Nr."
                                                            >
                                                                {props => {
                                                                        return <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name="customer.postcode"
                                                                type="text"
                                                                label="PLZ"
                                                            >
                                                                {props => {
                                                                        return <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                name="customer.city"
                                                                validate={required}
                                                                type="text"
                                                                label="Ort"
                                                            >
                                                                {props => {
                                                                        return <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid style={{paddingTop:'75px'}} item xs={12}>
                                                        <Typography variant="body2">* Pflichtfeld</Typography>
                                                    </Grid>
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </CardContent>
                                    </Card>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </GarantieWizard.Page>


                        <GarantieWizard.Page title="5. Eingabe Fahrzeugdaten" subtitle="Achtung! Es können nur Anträge bearbeitet werden, die vollständig ausgefüllt sind.">
                            <Grid container spacing={5}>
                                <Grid xs={12} item>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:10 }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .2 }}
                                    >
                                    <Card>
                                        <CardContent style={{paddingBottom:'44px'}} className={classes.cardContent}>
                                            <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name="vehicle.manufacturer"
                                                                type="text"
                                                                label="Hersteller"
                                                            >
                                                                {props => {
                                                                        return <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name="vehicle.model"
                                                                type="text"
                                                                label="Typ und Ausführung"
                                                            >
                                                                {props => {
                                                                        return <TextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}

                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                    validate={validateKw}
                                                                name="vehicle.kw"
                                                                type="text"
                                                                label="KW"
                                                                // onBlur={(e) => this.handleHinweise(e, vehicleCharged)}
                                                            >
                                                                {props => {
                                                                        return <NumberTextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment:
                                                                                    showKwHinweis && <InputAdornment position="end">
                                                                                        <Tooltip classes={{ tooltip: classes.tooltip }} title="Bitte die Annahme / den Preis in der Pro Garant Zentrale anfragen!">
                                                                                            <InfoIcon style={{fill: "#00468E", cursor: 'default'}} />
                                                                                        </Tooltip>
                                                                                    </InputAdornment>
                                                                            }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                        {/* {!!vehicleCharged.kw && <div>
                                                            {JSON.stringify(vehicleCharged.kw, null, 2)}
                                                        </div>} */}
                                                        
                                                    <Grid item xs={12} md={3} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                    validate={validatePs}
                                                                name="vehicle.ps"
                                                                type="text"
                                                                label="PS"
                                                            >
                                                                {props => {
                                                                        return <NumberTextField
                                                                            {...props}
                                                                            input={{
                                                                                ...props.input,
                                                                                onBlur: event => {},
                                                                            }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                    validate={validateHsn}
                                                                required
                                                                name="vehicle.hsn"
                                                                type="text"
                                                                label="HSN"
                                                                parse={normalizeHsn}
                                                            >
                                                                {props => {
                                                                    props['input']['maxLength'] = 4;
                                                                        return <TextField {...props}
                                                                                          input={{
                                                                                              ...props.input,
                                                                                              onBlur: event => {},
                                                                                          }}
                                                                                          InputProps={{
                                                                                              endAdornment:
                                                                                                  <InputAdornment position="end">
                                                                                                      <Tooltip title="Siehe Fahrzeugschein Feld 2.1. Bitte alle vier Ziffern angeben." classes={{ tooltip: classes.tooltip }}>
                                                                                                          <InfoIcon style={{fill: "#00468E", cursor: 'default'}} />
                                                                                                      </Tooltip>
                                                                                                  </InputAdornment>
                                                                                          }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                    validate={validateTsn}
                                                                name="vehicle.tsn"
                                                                type="text"
                                                                parse={normalizeTsn}
                                                                label="TSN"
                                                            >
                                                                {props => {
                                                                    props['input']['maxLength'] = 3;
                                                                        return <TextField {...props}
                                                                                          input={{
                                                                                              ...props.input,
                                                                                              onBlur: event => {},
                                                                                          }}
                                                                                          InputProps={{
                                                                                              endAdornment:
                                                                                                  <InputAdornment position="end">
                                                                                                      <Tooltip title="Siehe Fahrzeugschein Feld 2.2. Bitte die ersten drei Stellen (Ziffern/Buchstaben) angeben." classes={{ tooltip: classes.tooltip }}>
                                                                                                          <InfoIcon style={{fill: "#00468E", cursor: 'default'}} />
                                                                                                      </Tooltip>
                                                                                                  </InputAdornment>
                                                                                          }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <Field
                                                            fullWidth
                                                            name="vehicle.platenumber"
                                                            required={(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('platenumber') && String(vehicleValidationRules.platenumber).toLowerCase().includes('required') )) ? true : false}
                                                            validate={(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('platenumber') && String(vehicleValidationRules.platenumber).toLowerCase().includes('required') )) ? required : undefined}
                                                            parse={normalizeKennzeichen}
                                                            type="text"
                                                            label="Amtliches Kennzeichen"
                                                        >
                                                            {props => {
                                                                    return <TextField {...props}
                                                                                      input={{
                                                                                          ...props.input,
                                                                                          onBlur: event => {},
                                                                                      }}
                                                                    />
                                                            }}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                    required
                                                                    validate={validatePrice}
                                                                name="vehicle.price"
                                                                type="text"
                                                                label="Kaufpreis"
                                                            >
                                                                {props => {
                                                                        return <CurrencyField {...props}
                                                                                              input={{
                                                                                                  ...props.input,
                                                                                                  onBlur: event => {},
                                                                                              }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={requiredFahrgestellnummer}
                                                                name="vehicle.fahrgestellnummer"
                                                                parse={normalizeFahrgestellnummer}
                                                                type="text"
                                                                label="Fahrgestellnummer"
                                                            >
                                                                {props => {
                                                                    props['input']['maxLength'] = 17;
                                                                        return <TextField {...props}
                                                                                          input={{
                                                                                              ...props.input,
                                                                                              onBlur: event => {},
                                                                                          }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    { fahrzeugart !== 'pkw-elektro' && 
                                                        <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                                                            <FormControl fullWidth>
                                                                <Field
                                                                    fullWidth
                                                                    required
                                                                    validate={required}
                                                                    name="vehicle.zylinderAnzahl"
                                                                    type="text"
                                                                    label="Anzahl Zylinder"
                                                                    // onBlur={(e) => this.handleHinweise(e, vehicleCharged)}
                                                                >
                                                                    {props => {
                                                                        props['input']['maxLength'] = 2;
                                                                        return <NumberTextField {...props}
                                                                                                input={{
                                                                                                    ...props.input,
                                                                                                    onBlur: event => {},
                                                                                                }}
                                                                                                InputProps={{
                                                                                                    endAdornment:
                                                                                                        showZylinderHinweis && showZylinderHinweis !== '' && <InputAdornment position="end">
                                                                                                            <Tooltip title={showZylinderHinweis} classes={{ tooltip: classes.tooltip }}>
                                                                                                                <InfoIcon style={{fill: "#00468E", cursor: 'default'}} />
                                                                                                            </Tooltip>
                                                                                                        </InputAdornment>
                                                                                                }}
                                                                        />
                                                                    }}
                                                                </Field>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                        {fahrzeugart !== 'pkw-elektro' &&
                                                            <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
                                                                <FormControl fullWidth>
                                                                    <Field
                                                                        fullWidth
                                                                        required
                                                                        validate={required}
                                                                        name="vehicle.ccm"
                                                                        type="text"
                                                                        label="Hubraum (ccm)"
                                                                    >
                                                                        {props => {
                                                                            props['input']['maxLength'] = 5;
                                                                        return <NumberTextField {...props}
                                                                                                input={{
                                                                                                    ...props.input,
                                                                                                    onBlur: event => {},
                                                                                                }}
                                                                        />
                                                                        }}
                                                                    </Field>
                                                                </FormControl>
                                                            </Grid>
                                                    }
                                                    {fahrzeugart === 'pkw-elektro' && <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}></Grid>}
                                                    
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                    validate={validateTachoKm}
                                                                name="vehicle.km"
                                                                type="text"
                                                                label="KM-Stand lt. Tacho"
                                                            >
                                                                {props => {
                                                                    props['input']['maxLength'] = 7;
                                                                        return <NumberTextField {...props}
                                                                                                input={{
                                                                                                    ...props.input,
                                                                                                    onBlur: event => {},
                                                                                                }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                    validate={validateGesamtLaufleistung}
                                                                name="vehicle.gesamtlaufleistung"
                                                                type="text"
                                                                label="Gesamtlaufleistung"
                                                            >
                                                                {props => {
                                                                    props['input']['maxLength'] = 7;
                                                                        return <NumberTextField {...props}
                                                                                                input={{
                                                                                                    ...props.input,
                                                                                                    onBlur: event => {},
                                                                                                }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                name="vehicle.erstzulassung"
                                                                    validate={validateErstzulassung}
                                                                type="text"
                                                                autoOk
                                                                label="Erstzulassung"
                                                            >
                                                                {props => {
                                                                        return <DateField {...props}
                                                                                          input={{
                                                                                              ...props.input,
                                                                                              onBlur: event => {},
                                                                                          }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required={(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('verkaufsdatum') && String(vehicleValidationRules.verkaufsdatum).toLowerCase().includes('required') )) ? true : false}
                                                                validate={(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('verkaufsdatum') && String(vehicleValidationRules.verkaufsdatum).toLowerCase().includes('required'))) ? requiredDate : undefined}
                                                                name="vehicle.verkaufsdatum"
                                                                type="text"
                                                                autoOk
                                                                label="Verkaufsdatum lt. Kaufvertrag"
                                                            >
                                                                {props => {
                                                                        return <DateField {...props}
                                                                                          input={{
                                                                                              ...props.input,
                                                                                              onBlur: event => {},
                                                                                          }}
                                                                        />
                                                                }}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    {(emptyVehicleValidationRules || vehicleValidationRules.hasOwnProperty('fuel')) && <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <Field
                                                            fullWidth
                                                                // required
                                                            name="vehicle.fuel"
                                                            component={Select}
                                                            validate={requiredSelection}
                                                            label="Kraftstoff *"
                                                            formControlProps={{ fullWidth: true }}
                                                        >
                                                            {(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('fuel') && String(vehicleValidationRules.fuel).toLowerCase().includes('benzin') )) && <MenuItem value="Benzin">Benzin</MenuItem>}
                                                            {(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('fuel') && String(vehicleValidationRules.fuel).toLowerCase().includes('diesel') )) && <MenuItem value="Diesel">Diesel</MenuItem>}
                                                            {(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('fuel') && String(vehicleValidationRules.fuel).toLowerCase().includes('lpg') )) && <MenuItem value="LPG">LPG</MenuItem>}
                                                            {(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('fuel') && String(vehicleValidationRules.fuel).toLowerCase().includes('erdgas') )) && <MenuItem value="Erdgas">Erdgas</MenuItem>}
                                                            {(emptyVehicleValidationRules || (vehicleValidationRules.hasOwnProperty('fuel') && String(vehicleValidationRules.fuel).toLowerCase().includes('hybrid') )) && <MenuItem value="Hybrid">Hybrid</MenuItem>}
                                                        </Field>
                                                    </Grid>}
                                                    {(emptyVehicleValidationRules || vehicleValidationRules.hasOwnProperty('turbo')) && <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormLabel component="legend" >Turbolader *:</FormLabel>
                                                        <RadioGroup row style={{paddingLeft:'10px'}}>
                                                            <FormControlLabel
                                                                label="Ja"
                                                                className={classes.turboloaderLabel}
                                                                control={
                                                                    <Field
                                                                        name="vehicle.turbo"
                                                                        validate={required}
                                                                        type="radio"
                                                                        value="1" >
                                                                        {props => {
                                                                            return <Radio disableRipple color="primary" {...props} />
                                                                        }}
                                                                    </Field>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                label="Nein"
                                                                className={classes.turboloaderLabel}
                                                                control={
                                                                    <Field
                                                                        name="vehicle.turbo"
                                                                        validate={required}
                                                                        type="radio"
                                                                        value="0" >
                                                                        {props => {
                                                                            return <Radio disableRipple color="primary" {...props} />
                                                                        }}
                                                                    </Field>
                                                                }
                                                            />
                                                        </RadioGroup>
                                                        <Error style={{textAlign:'left',fontSize:'12px'}} name="vehicle.turbo" />
                                                    </Grid>}
                                                    {(emptyVehicleValidationRules ||vehicleValidationRules.hasOwnProperty('allrad')) && <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        <FormLabel component="legend" >Allrad:</FormLabel>
                                                        <RadioGroup row>
                                                            <Field
                                                                name="vehicle.allrad"
                                                                type="checkbox"
                                                                 >
                                                                {props => {
                                                                    return <Switch disableRipple color="primary" {...props} />
                                                                }}
                                                            </Field>
                                                        </RadioGroup>
                                                    </Grid>}
                                                    <Grid style={{paddingTop:'75px'}} item xs={12}>
                                                        <Typography variant="body2">* Pflichtfeld</Typography>
                                                    </Grid>
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </CardContent>
                                    </Card>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </GarantieWizard.Page>


                        <GarantieWizard.Page title="6. Eingabe Garantiedaten" subtitle="Garantierte Baugruppen: siehe § 1 in den Garantiebedingungen">
                            <Grid container spacing={5}>
                                <Grid xs={12} item>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:10 }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .2 }}
                                    >
                                    <Card>
                                        <CardContent style={{paddingBottom:'44px'}} className={classes.cardContent}>
                                            <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={12} md={6}>
                                                        <FormLabel className={classes.legend} component="legend" >Laufzeit:</FormLabel>
                                                        <RadioGroup>
                                                            <FormControlLabel
                                                                label="12 Monate"
                                                                className={classes.tarifTypLabel}
                                                                control={
                                                                    <Field
                                                                        name="garantiedaten.laufzeit"
                                                                        validate={required}
                                                                        type="radio"
                                                                        value="12" >
                                                                        {props => {
                                                                            return <Radio disableRipple color="primary" {...props} />
                                                                        }}
                                                                    </Field>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                label="24 Monate"
                                                                className={classes.tarifTypLabel}
                                                                control={
                                                                    <Field
                                                                        name="garantiedaten.laufzeit"
                                                                        validate={required}
                                                                        type="radio"
                                                                        value="24" >
                                                                        {props => {
                                                                            return <Radio disableRipple color="primary" {...props} />
                                                                        }}
                                                                    </Field>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                label="36 Monate"
                                                                className={classes.tarifTypLabel}
                                                                control={
                                                                    <Field
                                                                        name="garantiedaten.laufzeit"
                                                                        validate={required}
                                                                        type="radio"
                                                                        value="36" >
                                                                        {props => {
                                                                            return <Radio disableRipple color="primary" {...props} />
                                                                        }}
                                                                    </Field>
                                                                }
                                                            />
                                                        </RadioGroup>
                                                        <Error style={{textAlign:'left',fontSize:'12px'}} name="garantiedaten.laufzeit" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                      {garantieValidationRules.hasOwnProperty('mobilitaetsgarantie') && <>
                                                          <FormLabel className={classes.legend} component="legend" >Garantiezusatzleistungen:</FormLabel>
                                                          <RadioGroup style={{alignItems:'flex-start',marginBottom:'1em'}}>
                                                              <FormControlLabel
                                                                  label="Mobilitätsgarantie:"
                                                                  labelPlacement="top"
                                                                  classes={{label:classes.formControl}}
                                                                  control={<Field
                                                                  name="garantiedaten.mobilitaetsgarantie"
                                                                  type="checkbox"
                                                                  >
                                                                      {props => {
                                                                          return <Switch disableRipple color="primary" {...props} />
                                                                      }}
                                                                  </Field>
                                                                      }
                                                                  />
                                                              {garantieValidationRules.hasOwnProperty('alternativantrieb') && <FormControlLabel
                                                                  label="Alternativantrieb:"
                                                                  labelPlacement="top"
                                                                  classes={{label:classes.formControl}}
                                                                  control={<Field
                                                                      name="garantiedaten.alternativantrieb"
                                                                      type="checkbox"
                                                                  >{props => {
                                                                      return <Switch disableRipple color="primary" {...props} />
                                                                  }}</Field>}
                                                              />}
                                                          </RadioGroup>
                                                      </>}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <Grid container spacing={5}>
                                                        <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                          <FormLabel className={classes.legend} component="legend" >Garantiebeginn:</FormLabel>
                                                          <FormControl fullWidth>
                                                            <Field
                                                              fullWidth
                                                              required
                                                              name="garantiedaten.wiederzulassung"
                                                              validate={requiredDate}
                                                              type="text"
                                                              autoOk
                                                              label={garantieart === "gas_kompakt" ? "Tag der Umrüstung" : "Tag der Wiederzulassung"}
                                                            >
                                                              {props => {
                                                                return <DateField {...props} />
                                                              }}
                                                            </Field>
                                                          </FormControl>
                                                          {garantieValidationRules.hasOwnProperty('endWerksgarantieDate') &&
                                                            <FormControl fullWidth style={{marginTop:'1em'}}>
                                                              <Field
                                                                fullWidth
                                                                required
                                                                name="garantiedaten.endWerksgarantieDate"
                                                                validate={requiredDate}
                                                                type="text"
                                                                autoOk
                                                                label="Tag der Beendigung der Werksgarantie"
                                                              >
                                                                {props => {
                                                                  return <DateField {...props} />
                                                                }}
                                                              </Field>
                                                            </FormControl>
                                                          }
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                          <FormLabel className={classes.legend} component="legend" >Die Garantiezusage erfolgt:</FormLabel>
                                                          <RadioGroup style={{alignItems:'flex-start',marginBottom:'1em'}}>
                                                            <FormControlLabel
                                                                label="unentgeltlich (ist im Fahrzeugpreis enthalten)"
                                                                className={classes.tarifTypLabel}
                                                                control={
                                                                    <Field
                                                                        name="garantiedaten.freeOfCharge"
                                                                        validate={required}
                                                                        type="radio"
                                                                        value="1" >
                                                                            
                                                                        {props => {
                                                                            return <Radio disableRipple color="primary" {...props} />
                                                                        }}
                                                                    </Field>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                              label="entgeltlich (gegen gesonderte/gesondert ausgewiesene Berechnung)"
                                                              className={classes.tarifTypLabel}
                                                              control={
                                                                <Field
                                                                  name="garantiedaten.freeOfCharge"
                                                                    validate={required}
                                                                  type="radio"
                                                                  value="0" >
                                                                  {props => {
                                                                    return <Radio disableRipple color="primary" {...props} />
                                                                  }}
                                                                </Field>
                                                              }
                                                            />
                                                            
                                                          </RadioGroup>
                                                                    {isEntgeltlich && 
                                                            <div style={{ color: 'red' }}><strong>Achtung!</strong> Entgeltliche Garantiezusagen, Ihrem Kunden gegenüber, unterliegen der Versicherungssteuerpflicht!</div>
                                                        }
                                                          <Error style={{textAlign:'left',fontSize:'12px'}} name="garantiedaten.freeOfCharge" />
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} style={{paddingBottom:0}}>
                                                        <FormLabel className={classes.legend} component="legend" >Bemerkung:</FormLabel>
                                                    </Grid>
                                                    <Grid item xs={12} style={{paddingTop:0}}>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={12}>
                                                                <FormControl style={{border:'1px solid #B1B1B1'}} fullWidth>
                                                                    <Field
                                                                        fullWidth
                                                                        name="remarks"
                                                                        multiline
                                                                        rows={5}
                                                                        rowsMax={10}
                                                                        type="text"
                                                                        label="Ihre Bemerkung..."
                                                                    >
                                                                        {props => {
                                                                            props['input']['maxLength'] = 256;
                                                                            return <TextField {...props} />
                                                                        }}
                                                                    </Field>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{paddingTop:'27px'}} item xs={12}>
                                                        <Typography variant="body2">* Pflichtfeld</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.info}>Erklärungen Händler und Garantienehmer (Käufer)</Typography>
                                                        <ul>
                                                            <li>Mit der Abwicklung von Garantiefällen ist allein die Pro Garant GmbH durch den verkaufenden Händler / Garantiegeber beauftragt.</li>
                                                            <li>Die gesetzlichen Gewährleistungsrechte werden durch diese Garantie nicht eingeschränkt, diese stehen Ihnen unabhängig von der Garantie zu. Die Inanspruchnahme dieser Rechte ist unentgeltlich.</li>
                                                            <li>Informationen zum Datenschutz finden Sie unter <a target="_blank" rel="noopener noreferrer" style={{color:'#00468E'}} href="https://www.progarant.de/datenschutz">https://www.progarant.de/datenschutz</a></li>
                                                            <li>Die Garantie kann innerhalb von 14 Tagen nach Eingang abgelehnt werden.</li>
                                                            <li>Die Durchführung der vorgeschriebenen Inspektionen (siehe § 3b) ist Bestandteil dieser Garantie.</li>
                                                            <li>Der Käufer bestätigt den Empfang der Garantieurkunde und Kenntnisnahme der Garantiebedingungen.</li>
                                                            <li>Wir informieren Sie hiermit über die Erhebung und weitere Verwendung Ihrer E-Mail-Adresse im Rahmen unserer Geschäftsbeziehung 
                                                                zur werblichen Ansprache im Rahmen von § 7 Abs. 3 UWG. Sie können gegen die Verwendung Ihrer E-Mail-Adresse zur werblichen Ansprache 
                                                                jederzeit widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.
                                                            </li> 
                                                        </ul>
                                                    </Grid>
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </CardContent>
                                    </Card>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </GarantieWizard.Page>

                        <GarantieWizard.Page validate={(values) => {
                            let errors = {};
                            //console.log('VALIDATE');
                            //console.log(values.inspektionsnachweise.inspectionProof);
                            //console.log('----BLA----');

                            if(
                                !values.inspektionsnachweise ||
                                !values.inspektionsnachweise.inspectionProof ||
                                (Array.isArray(values.inspektionsnachweise.inspectionProof) && values.inspektionsnachweise.inspectionProof.length === 0)
                            ){
                                errors['inspektionsnachweise.inspectionProof'] = 'Bitte auswählen'
                            }

                            //console.log(errors)

                            return errors;
                        }} title="7. Inspektionsnachweise / weitere Dokumente" subtitle={`Inspektionsnachweise (in Bezug auf § 3b. der Garantiebedingungen)`}>
                            <Grid container spacing={5}>
                                <Grid xs={12} item>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:10 }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .2 }}
                                    >
                                        <Card>
                                            <CardContent style={{paddingBottom:'44px',paddingLeft:'54px',paddingRight:'54px'}} className={classes.cardContent}>
                                                <Typography className={classes.intro}>Bitte geben Sie den Inspektionsstand, bei Übergabe des Fahrzeugs an den Garantienehmer, an:<br />(Keine Mehrfachauswahl möglich.)</Typography>
                                                <Box style={{marginBottom:40}}>
                                                    <FormControlLabel
                                                        label="Die letzte Wartung wurde nach Herstellervorgaben durchgeführt und liegt noch innerhalb des vom Herstellers vorgegebenen Intervalls."
                                                        classes={{
                                                            root:classes.uploadRoot,
                                                            label:classes.uploadLabel
                                                        }}

                                                        control={
                                                            <Field
                                                                name="inspektionsnachweise.inspectionProof"
                                                                type="checkbox"
                                                                value="SG"
                                                            >
                                                                {props => {
                                                                    return <Checkbox style={{padding:9}} disableRipple color="primary" {...props} />
                                                                }}
                                                            </Field>
                                                        }
                                                    />
                                                    <FormSpy subscription={{ values: true }}>
                                                        {props => (
                                                          !!props.values.inspektionsnachweise && !!props.values.inspektionsnachweise.inspectionProof && props.values.inspektionsnachweise.inspectionProof[0] === 'SG'
                                                            ? <FormControl style={{paddingLeft:30,transform:'none'}} fullWidth>
                                                                <UploadField disabled={false} name="inspektionsnachweise.proofOfInspectionFilePath" label="Inspektionsnachweis" />
                                                            </FormControl>
                                                            : null
                                                          )}
                                                    </FormSpy>
                                                    <FormControlLabel
                                                        label="Es wurde eine Übergabeinspektion, nach Herstellervorgaben, durchgeführt."
                                                        classes={{
                                                            root:classes.uploadRoot,
                                                            label:classes.uploadLabel
                                                        }}
                                                        control={
                                                            <Field
                                                                name="inspektionsnachweise.inspectionProof"
                                                                type="checkbox"
                                                                value="ÜI"
                                                            >
                                                                {props => {
                                                                    return <Checkbox style={{padding:9}} disableRipple color="primary" {...props} />
                                                                }}
                                                            </Field>
                                                        }
                                                    />
                                                    <FormSpy subscription={{ values: true }}>
                                                        {props => (
                                                          !!props.values.inspektionsnachweise && !!props.values.inspektionsnachweise.inspectionProof && props.values.inspektionsnachweise.inspectionProof[0] === 'ÜI'
                                                            ? <FormControl style={{paddingLeft:30,transform:'none'}} fullWidth>
                                                                <UploadField disabled={!(props.values.inspektionsnachweise && props.values.inspektionsnachweise.inspectionProof && props.values.inspektionsnachweise.inspectionProof[0] === 'ÜI')} name="inspektionsnachweise.proofOfInspectionFilePath" label="Inspektionsnachweis" />
                                                                {1 === 2 && <pre style={{display:'block'}}>{JSON.stringify(props.values, 0, 2)}</pre>}
                                                            </FormControl>
                                                            : null
                                                        )}
                                                    </FormSpy>
                                                    <FormControlLabel
                                                        label="Der Kunde wurde schriftlich darauf hingewiesen, innerhalb von 6 Monaten nach Wiederzulassung des Fahrzeuges, eine Inspektion, nach Herstellervorgabe, durchführen zu lassen."
                                                        classes={{
                                                            root:classes.uploadRoot,
                                                            label:classes.uploadLabel
                                                        }}
                                                        control={
                                                            <Field
                                                                name="inspektionsnachweise.inspectionProof"
                                                                type="checkbox"
                                                                value="HI"
                                                            >
                                                                {props => {
                                                                    return <Checkbox style={{padding:9}} disableRipple color="primary" {...props} />
                                                                }}
                                                            </Field>
                                                        }
                                                    />
                                                    <FormSpy subscription={{ values: true }}>
                                                        {props => (
                                                            !!props.values && props.values.hasOwnProperty('garantietyp') && parseInt(props.values.garantietyp,10) === 1 && <FormControlLabel
                                                            label="Es war noch keine Inspektion fällig."
                                                            classes={{
                                                                root:classes.uploadRoot,
                                                                label:classes.uploadLabel
                                                            }}
                                                            control={
                                                                <Field
                                                                    name="inspektionsnachweise.inspectionProof"
                                                                    type="checkbox"
                                                                    value="NF"
                                                                >
                                                                    {props => {
                                                                        return <Checkbox style={{padding:9}} disableRipple color="primary" {...props} />
                                                                    }}
                                                                </Field>
                                                            }
                                                        />
                                                        )
                                                        }
                                                    </FormSpy>
                                                  <FormSpy>
                                                    {(props) => {
                                                      const {errors,hasValidationErrors,touched,modified} = props;
                                                      //console.log(props);
                                                      return (
                                                        (((hasValidationErrors)) && (touched['inspektionsnachweise.inspectionProof'] || modified['inspektionsnachweise.inspectionProof'])) && errors.hasOwnProperty('inspektionsnachweise.inspectionProof') ?
                                                          <FormHelperText error={true}>{errors['inspektionsnachweise.inspectionProof']}</FormHelperText>
                                                          : null
                                                      )
                                                    }}
                                                  </FormSpy>
                                                  <Typography style={{marginTop:16}}><TextButton disableRipple disableFocusRipple onClick={this.handleDownloadCLick} variant="text">Wichtige Informationen zu den notwendigen Inspektions-/Wartungsarbeiten finden Sie hier</TextButton></Typography>
                                                </Box>
                                                <Typography className={classes.headline}>Sonstige Dokumente</Typography>
                                                <Typography style={{marginBottom:16}} className={classes.subline}>Hier haben Sie die Möglichkeit weitere Dokumente wie z.B. einen Kfz-Schein, Reparaturbelege etc. hochzuladen.</Typography>
                                                <FormControl fullWidth>
                                                    <UploadField  name="inspektionsnachweise.additionalDocumentFilePath1" label="weiteres Dokument 1" />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <UploadField  name="inspektionsnachweise.additionalDocumentFilePath2" label="weiteres Dokument 2" />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <UploadField  name="inspektionsnachweise.additionalDocumentFilePath3" label="weiteres Dokument 3" />
                                                </FormControl>
                                                <Typography style={{fontSize:'14px'}}>* Pflichtfeld</Typography>
                                            </CardContent>
                                        </Card>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </GarantieWizard.Page>
                        <GarantieWizard.Page title="8. Zusammenfassung" subtitle={`Bitte überprüfen Sie Ihre eingegebenen Daten!
                       Nach dem Absenden sind keine Änderungen mehr möglich!`}>
                            <Grid container spacing={5}>
                                <Grid xs={12} item>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:10 }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .2 }}
                                    >
                                    <Card>
                                        <CardContent style={{paddingBottom:'44px'}} className={classes.cardContent}>
                                            {overview.map((item,index) => {
                                                return (
                                                    <Box key={index}>
                                                        <Typography variant="h4" style={{paddingBottom:'13px',color:'#00468E',borderBottom:'2px solid #F3F3F3',marginBottom:'30px'}}>{item.title}</Typography>
                                                        <Table>
                                                            <TableBody>
                                                                {item.data.length && item.data.filter((item) => !(item.hasOwnProperty('hidden') === true && item.hidden === true)).map((row,i) => {
                                                                    const fieldNames = ((!!row.field) ? ((Array.isArray(row.field)) ? row.field : String(row.field).split(' ')) : []);
                                                                    const tableClass = ((i%2 === 0) ? classes.tableCellOdd : classes.tableCellEven);

                                                                    return (
                                                                        <React.Fragment key={i}>
                                                                            <TableRow>
                                                                                <TableCell className={tableClass} style={{width:'50%'}}>
                                                                                    {row.title}:
                                                                                </TableCell>
                                                                                <TableCell className={tableClass} style={{width:'50%'}}>
                                                                                    {row.value}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {!!fieldNames.length && fieldNames.map((fieldname,index) => {
                                                                                return (
                                                                                chargedImpacts.hasOwnProperty(fieldname) && <TableRow key={index}>
                                                                                    <TableCell className={`${tableClass} ${classes.tableClassInfo}`} colSpan={2} style={{width:'50%'}}>
                                                                                        <span className={classes.tableClassInfoInnerWrap}><strong>Hinweis: </strong>
                                                                                        {chargedImpacts[fieldname]}
                                                                                        </span>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                );
                                                                            })}
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                )
                                            })}
                                            <Grid item xs={12} style={{marginBottom:'30px'}}>
                                                <Typography variant="h4" style={{paddingBottom:'13px',marginBottom:'13px',color:'#00468E',borderBottom:'2px solid #F3F3F3'}}>Bemerkung</Typography>
                                                <Box>
                                                    {remarks !== '' ? remarks : '-'}
                                                </Box>
                                            </Grid>
                                            {fahrzeugart !== 'Motorrad' && <Grid container spacing={5}>
                                                <Grid item style={{paddingTop:0}} xs={12} md={6}>
                                                    <FormControlLabel
                                                        label="Inspektionscheckliste als PDF"
                                                        className={classes.tarifAcceptLabel}
                                                        control={
                                                            <Field
                                                                name="inspektionscheckliste"
                                                                type="checkbox"
                                                                 >
                                                                {props => {
                                                                    return <Checkbox disableRipple color="primary" {...props} />
                                                                }}
                                                            </Field>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item style={{paddingTop:0}} xs={12} md={6}>
                                                    <FormControlLabel
                                                        label="Übergabeprotokoll als PDF"
                                                        className={classes.tarifAcceptLabel}
                                                        control={
                                                            <Field
                                                                name="uebergabeprotokoll"
                                                                type="checkbox"
                                                                 >
                                                                {props => {
                                                                    return <Checkbox disableRipple color="primary" {...props} />
                                                                }}
                                                            </Field>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>}
                                        </CardContent>
                                    </Card>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </GarantieWizard.Page>
                        <GarantieWizard.Page title="9. Abschluss / PDF-Dokument" subtitle={`Ihre Garantie wurde erfolgreich erstellt.`}>
                            <Grid container spacing={5}>
                                <Grid xs={12} item>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:10 }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay: .2 }}
                                    >
                                    <Card>
                                        <CardContent style={{paddingBottom:'44px'}} className={classes.cardContent}>
                                            <Typography color="primary" style={{marginBottom:'22px'}}>Die Garantienummer für diese Garantie: {gar_nr}</Typography>
                                            <Typography style={{marginBottom:'32px',lineHeight:'32px'}}>
                                            Damit ist die Erstellung der Garantie abgeschlossen.<br />
                                            Die eingegebenen Daten werden automatisch an Pro Garant übermittelt, die
                                            Garantie als PDF-Dokument bitte an Ihrem PC ausdrucken. Bitte prüfen
                                            Sie den korrekten Ausdruck des PDF-Dokuments.
                                            </Typography>
                                            <Typography style={{marginBottom:'32px',lineHeight:'32px'}}>
                                                Falls sich das Dokument nicht automatisch öffnen lässt,  können Sie die Garantie erneut als <button onClick={(e) => this.downloadGarantie(e)} title="PDF herunterladen" className={classes.textButton} rel="noopener noreferrer" target="_blank">PDF herunterladen.</button> Das Dokument finden Sie zusätzlich in Ihrer <Link to={Paths.GARANTIE_LISTE}>Garantieliste</Link>.
                                            </Typography>
                                            {fahrzeugart !== 'Motorrad' && <Typography style={{marginBottom:'32px',lineHeight:'32px'}}>
                                                Hier können Sie die <button title="Inspektionscheckliste" onClick={(e) => this.downloadInspektionscheckliste(e)} rel="noopener noreferrer" className={classes.textButton} target="_blank">Inspektionscheckliste</button> und das <button className={classes.textButton} onClick={(e) => this.downloadUebergabeprotokoll(e)} title="Übergabeprotokoll" rel="noopener noreferrer" target="_blank">Übergabeprotokoll</button> herunterladen.
                                            </Typography>}
                                            <Typography style={{lineHeight:'32px'}}>
                                                Sollte Ihnen der Acrobat-Reader fehlen, können Sie ihn <a href="https://www.adobe.com" rel="noopener noreferrer" target="_blank">hier herunterladen</a>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </GarantieWizard.Page>
                    </GarantieWizard>
                </Grid>
                <Hidden xsDown>
                    <Grid item xs={12} md={4}>
                        <Steps />
                    </Grid>
                </Hidden>
            </Grid>
        );
    }

    getOverview(fahrzeugart, customerState, customer, birthday, vehicle, erstzulassung, garantietarif, isPlusTariff, garantiedaten, garantieValidationRules, garantieart, wiederzulassung, endWerksgarantieDate, verkaufsdatum) {
        var overview = null;
        if (fahrzeugart === 'pkw-elektro') {
            overview = [
                {
                    title: 'Garantienehmer', data: [
                        { title: ((customerState.title === 'Firma') ? 'Firma' : 'Name'), value: ((customerState.title === 'Firma') ? customer.surname : customer.title + " " + customer.prename + " " + customer.surname) },
                        { title: 'E-Mail', field: 'mail', value: customer.mail },
                        { title: 'Straße, Hausnummer', field: 'street', value: customer.street },
                        { title: 'Postleitzahl, Ort', field: ['postcode', 'city'], value: customer.postcode + ", " + customer.city },
                        { title: 'Telefon', field: 'telephone', value: customer.telephone },
                        { title: 'Geb. Datum', field: 'birthday', value: ((birthday instanceof Date && !isNaN(birthday)) ? format(birthday, 'dd.MM.yyyy') : '-') },
                    ]
                },
                {
                    title: 'Fahrzeugdaten', data: [
                        { title: 'Hersteller/ Typ und Ausführung', field: ['manufacturer', 'model'], value: vehicle.manufacturer + " " + vehicle.model },
                        { title: 'KW/ PS', field: ['kw', 'ps'], value: Numeral(vehicle.kw).format('0') + " KW / " + Numeral(vehicle.ps).format('0') + " PS" },
                        { title: 'Amtl. Kennzeichen', field: 'platenumber', value: vehicle.platenumber },
                        { title: 'Kaufpreis', field: 'price', value: Numeral(Number(vehicle.price)).format('0,0.00 $') },
                        { title: 'Fahrgestellnummer', field: 'fahrgestellnummer', value: vehicle.fahrgestellnummer },
                        { title: 'HSN/ TSN', field: ['hsn', 'tsn'], value: vehicle.hsn + " / " + vehicle.tsn },
                        { title: 'KM-Stand lt. Tacho', field: 'km', value: ((!!vehicle.km) ? Numeral(vehicle.km).format('0,0') + " km" : '-') },
                        { title: 'Gesamtlaufleistung', field: 'gesamtlaufleistung', value: ((!!vehicle.gesamtlaufleistung) ? Numeral(vehicle.gesamtlaufleistung).format('0,0') + " km" : '-') },
                        { title: 'Allrad', field: 'allrad', value: this.bool2german(vehicle.allrad) },
                        { title: 'Erstzulassung', field: 'erstzulassung', value: ((erstzulassung instanceof Date && !isNaN(erstzulassung)) ? format(erstzulassung, 'dd.MM.yyyy') : '-') }
                    ]
                },
                {
                    title: 'Garantie Optionen', data: [
                        { title: 'Garantievariante', value: ((garantietarif.hasOwnProperty('name')) ? (!!isPlusTariff ? garantietarif.namePlus : garantietarif.name) : '-') },
                        { title: 'Mobilitätsgarantie', field: 'mobilitaetsgarantie', value: this.bool2german(garantiedaten.mobilitaetsgarantie) },
                        { title: 'Laufzeit bis zu', field: 'laufzeit', value: ((!!garantiedaten.laufzeit) ? garantiedaten.laufzeit + " Monate" : '-') },
                        { title: 'Die Garantiezusage erfolgt', field: 'freeOfCharge', value: ((garantiedaten.hasOwnProperty('freeOfCharge') && parseInt(garantiedaten.freeOfCharge, 10) === 1) ? "unentgeltlich (ist im Fahrzeugpreis enthalten)" : 'entgeltlich (gegen gesonderte/gesondert ausgewiesene Berechnung)') },
                        { title: (garantieart === "gas_kompakt" ? "Tag der Umrüstung" : "Tag der Wiederzulassung"), field: 'wiederzulassung', value: ((wiederzulassung instanceof Date && !isNaN(wiederzulassung)) ? format(wiederzulassung, 'dd.MM.yyyy') : '-') },
                        { title: 'Tag der Beendigung der Werksgarantie', field: 'endWerksgarantieDate', value: ((endWerksgarantieDate instanceof Date && !isNaN(endWerksgarantieDate)) ? format(endWerksgarantieDate, 'dd.MM.yyyy') : '-') },
                        { title: 'Verkaufsdatum lt. Kaufvertrag', field: 'verkaufsdatum', value: ((verkaufsdatum instanceof Date && !isNaN(verkaufsdatum)) ? format(verkaufsdatum, 'dd.MM.yyyy') : '-') }
                    ]
                }
            ];
        } else {
            overview = [
                {
                    title: 'Garantienehmer', data: [
                        { title: ((customerState.title === 'Firma') ? 'Firma' : 'Name'), value: ((customerState.title === 'Firma') ? customer.surname : customer.title + " " + customer.prename + " " + customer.surname) },
                        { title: 'E-Mail', field: 'mail', value: customer.mail },
                        { title: 'Straße, Hausnummer', field: 'street', value: customer.street },
                        { title: 'Postleitzahl, Ort', field: ['postcode', 'city'], value: customer.postcode + ", " + customer.city },
                        { title: 'Telefon', field: 'telephone', value: customer.telephone },
                        { title: 'Geb. Datum', field: 'birthday', value: ((birthday instanceof Date && !isNaN(birthday)) ? format(birthday, 'dd.MM.yyyy') : '-') },
                    ]
                },
                {
                    title: 'Fahrzeugdaten', data: [
                        { title: 'Hersteller/ Typ und Ausführung', field: ['manufacturer', 'model'], value: vehicle.manufacturer + " " + vehicle.model },
                        { title: 'KW/ PS', field: ['kw', 'ps'], value: Numeral(vehicle.kw).format('0') + " KW / " + Numeral(vehicle.ps).format('0') + " PS" },
                        { title: 'Amtl. Kennzeichen', field: 'platenumber', value: vehicle.platenumber },
                        { title: 'Kaufpreis', field: 'price', value: Numeral(Number(vehicle.price)).format('0,0.00 $') },
                        { title: 'Fahrgestellnummer', field: 'fahrgestellnummer', value: vehicle.fahrgestellnummer },
                        { title: 'HSN/ TSN', field: ['hsn', 'tsn'], value: vehicle.hsn + " / " + vehicle.tsn },
                        { title: 'KM-Stand lt. Tacho', field: 'km', value: ((!!vehicle.km) ? Numeral(vehicle.km).format('0,0') + " km" : '-') },
                        { title: 'Gesamtlaufleistung', field: 'gesamtlaufleistung', value: ((!!vehicle.gesamtlaufleistung) ? Numeral(vehicle.gesamtlaufleistung).format('0,0') + " km" : '-') },
                        { title: 'Anzahl Zylinder', field: 'zylinderAnzahl', value: vehicle.zylinderAnzahl },
                        { title: 'Hubraum', field: 'ccm', value: ((!!vehicle.ccm) ? Numeral(vehicle.ccm).format('0,0') + " ccm" : '-') },
                        { title: 'Kraftstoff', field: 'fuel', value: vehicle.fuel },
                        { title: 'Allrad', field: 'allrad', value: this.bool2german(vehicle.allrad) },
                        { title: 'Turbolader', field: 'turbo', value: this.bool2german(vehicle.turbo) },
                        { title: 'Erstzulassung', field: 'erstzulassung', value: ((erstzulassung instanceof Date && !isNaN(erstzulassung)) ? format(erstzulassung, 'dd.MM.yyyy') : '-') }
                    ]
                },
                {
                    title: 'Garantie Optionen', data: [
                        { title: 'Garantievariante', value: ((garantietarif.hasOwnProperty('name')) ? (!!isPlusTariff ? garantietarif.namePlus : garantietarif.name) : '-') },
                        { title: 'Mobilitätsgarantie', field: 'mobilitaetsgarantie', value: this.bool2german(garantiedaten.mobilitaetsgarantie) },
                        { title: 'Alternativantrieb', hidden: ((garantieValidationRules.hasOwnProperty('alternativantrieb') === true) ? false : true), field: 'alternativantrieb', value: this.bool2german(garantiedaten.alternativantrieb) },
                        { title: 'Laufzeit bis zu', field: 'laufzeit', value: ((!!garantiedaten.laufzeit) ? garantiedaten.laufzeit + " Monate" : '-') },
                        { title: 'Die Garantiezusage erfolgt', field: 'freeOfCharge', value: ((garantiedaten.hasOwnProperty('freeOfCharge') && parseInt(garantiedaten.freeOfCharge, 10) === 1) ? "unentgeltlich (ist im Fahrzeugpreis enthalten)" : 'entgeltlich (gegen gesonderte/gesondert ausgewiesene Berechnung)') },
                        { title: (garantieart === "gas_kompakt" ? "Tag der Umrüstung" : "Tag der Wiederzulassung"), field: 'wiederzulassung', value: ((wiederzulassung instanceof Date && !isNaN(wiederzulassung)) ? format(wiederzulassung, 'dd.MM.yyyy') : '-') },                        
                        { title: 'Tag der Beendigung der Werksgarantie', field: 'endWerksgarantieDate', value: ((endWerksgarantieDate instanceof Date && !isNaN(endWerksgarantieDate)) ? format(endWerksgarantieDate, 'dd.MM.yyyy') : '-') },
                        { title: 'Verkaufsdatum lt. Kaufvertrag', field: 'verkaufsdatum', value: ((verkaufsdatum instanceof Date && !isNaN(verkaufsdatum)) ? format(verkaufsdatum, 'dd.MM.yyyy') : '-') }
                    ]
                }
            ];
        }
        return overview;
    }
}

Garantie.propTypes = {
    classes:PropTypes.object.isRequired,
    selectedGarantieart:PropTypes.string,
    initialValues:PropTypes.object.isRequired,
    loadTarife:PropTypes.func.isRequired,
    tarife:PropTypes.array.isRequired,
    conditions:PropTypes.array.isRequired,
    customer:PropTypes.object.isRequired,
    vehicle:PropTypes.object.isRequired,
    vehicleCharged: PropTypes.object.isRequired,
    garantiedaten:PropTypes.object.isRequired,
    garantietarif:PropTypes.object.isRequired,
    gar_nr:PropTypes.string.isRequired,
    isPlusTariff:PropTypes.bool.isRequired,
    garantieValidationRules:PropTypes.object.isRequired,
    vehicleValidationRules:PropTypes.object.isRequired,
    customerValidationRules:PropTypes.object.isRequired,
    getPdfFromPath:PropTypes.func.isRequired,
    openPdfFromPath:PropTypes.func.isRequired,
    pdf:PropTypes.object.isRequired,
    chargedImpacts:PropTypes.object.isRequired,
    tarifeLoading:PropTypes.bool.isRequired,
    conditionLoading:PropTypes.bool.isRequired,
    fahrzeugart:PropTypes.string.isRequired,
    garantieart:PropTypes.string.isRequired,
    remarks:PropTypes.string.isRequired,
    formState:PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const {values} = state.form;
    const fahrzeugart = state.app.garantie.fahrzeugart;
    const gar_nr = state.app.garantie.gar_nr || '';
    const remarks = state.app.garantie.remarks || '';
    const garantieart = state.app.garantie.garantieart;
    const tarife = ((!!state.app.garantie.fahrzeugart && !!state.app.garantie.tarife && state.app.garantie.tarife.hasOwnProperty(state.app.garantie.fahrzeugart)) ? state.app.garantie.tarife[state.app.garantie.fahrzeugart] : []);
    const garantietarif = tarife.reduce((result, item) => {
        if (item.identifier === garantieart) { result = item }
        return result
    }, {});

    return {
        formState: getFormState(state,'newGarantie'),
        fahrzeugart:fahrzeugart || '',
        tarife:(state.app.garantie.tarife.hasOwnProperty(fahrzeugart)) ? state.app.garantie.tarife[fahrzeugart] : [],
        selectedGarantieart: !!state.form && !!state.form.newGarantie && !!state.form.newGarantie.values && !!state.form.newGarantie.values.garantieart_virt ? state.form.newGarantie.values.garantieart_virt : null,
        initialValues:values,
        garantietarif:garantietarif,
        garantieart:garantieart,
        remarks:remarks,
        gar_nr:String(gar_nr),
        conditionLoading:state.app.conditionLoading,
        tarifeLoading:state.app.tarifeLoading,
        customerValidationRules:state.app.garantie.customerValidationRules,
        garantieValidationRules:state.app.garantie.garantieValidationRules,
        vehicleValidationRules:state.app.garantie.vehicleValidationRules,
        pdf:state.app.garantie.pdf,
        chargedImpacts:state.app.garantie.chargedImpacts,
        isPlusTariff:state.app.garantie.isPlusTariff,
        customer:(!!state.app.garantie && !!state.app.garantie.customer) ? state.app.garantie.customer : {},
        vehicle:(!!state.app.garantie && !!state.app.garantie.vehicle) ? state.app.garantie.vehicle : {},
        vehicleCharged: (!!state.app.garantie && !!state.app.garantie.vehicleCharged) ? state.app.garantie.vehicleCharged : {},
        garantiedaten: (!!state.app.garantie && !!state.app.garantie.garantiedaten) ? state.app.garantie.garantiedaten : {},
        conditions:(state.app.garantie.conditions.hasOwnProperty(garantieart)) ? state.app.garantie.conditions[garantieart] : [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadTarife: (vehicleType) => dispatch(loadTarife(vehicleType)),
        getPdfFromPath:(path,filename) => dispatch(loadPdfFromPath(path,filename)),
        openPdfFromPath:(path,filename) => dispatch(openPdfFromPath(path,filename))
    }
};


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Garantie));

