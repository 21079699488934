import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Paths } from '../../Constants/Paths';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import { push } from 'connected-react-router';
import ProgarantLogo from '../../Assets/Png/logo512.png';
import Button from '@material-ui/core/Button';
import FordwardIcon from '../../Assets/Svg/Forward';
import { loginKundenportal } from '../../Reducers/App';

// const required = value => (value ? undefined : 'Bitte angeben');
const INVALID_REQUIRED = 'Bitte angeben.';
const INVALID_LENGTH = 'Es müssen mindestens 6 Zeichen sein.';
const ERROR_WRONG_CODE = 'Der eingegebene Code entspricht nicht den im System hinterlegten Code. Bei Fragen wenden Sie sich bitte an Ihren Händler oder an den Pro Garant Support unter service @progarant.de.';

const styles = theme => ({
    cardContent: {
        padding: '96px 54px 33px 54px',
        '@media (max-width: 500px)': {
            padding: '96px 10px 33px 10px',
        },
    },
    submitButton: {
        padding: 0
    },
    logo: {
        width: '200px',
        height: 'auto',
        position: 'absolute',
        top: '0px'
    },
    input: {
        "& input": {
            letterSpacing: '0.5em',
            fontSize: '1.5em',
            color: 'black',
            padding: '0.5em',
            // paddingLeft: '5%',
            // paddingRight: '5%',
            textAlign: 'center',
            borderTopLeftRadius: '0.3em 0.3em',
            borderTopRightRadius: '0.3em 0.3em',
            width: '100%',
            border: 'none',
            borderBottom: '1px solid #7CB9E8',
            backgroundColor: '#F5F5F5'
        },
        "& input:invalid": {
            borderBottom: '1px solid red',
            color: 'red'
        },
        "& p": {
            textAlign: 'left',
            color: 'red'
        },
        "& input:focus": {
            outline: 'none'
        }
    },
    middleDot: {
        display: 'inline-block',
        height: '0.5em',
        margin: '0 0.2em',
        fontSize: '1.5em'
    }
});

const LinkButton = withStyles(theme => ({
    root: {
        '&:hover': {
            // fontWeight: 'bold',
            backgroundColor: 'transparent'
        }
    },
    label: {
        textTransform: 'none',
        // textDecoration: 'underline'
    }

}))(Button);

class KundenLogin extends React.PureComponent {

    constructor(props) {
        super(props);
        this.props = props
        this.state = {
            code: '',
            email: '',
            garantieId: '',
            errorCode: false,
            codeCheck: false
        };
        let loginString = this.props.kundenportalLoginString;
        console.log('loginstring', loginString)
        this.handleCodeSubmit = this.handleCodeSubmit.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
    }
    validateRequired(input) {
        if (!input) {
            this.setState({ errorCode: INVALID_REQUIRED })
            document.getElementById('input-code').setCustomValidity(INVALID_REQUIRED);
        }
        else {

            this.setState({ errorCode: false })
            document.getElementById('input-code').setCustomValidity('');
        }
    }
    validateLength(lenght) {
        if (lenght === 0) {
            this.setState({ errorCode: INVALID_REQUIRED })
            document.getElementById('input-code').setCustomValidity(INVALID_REQUIRED);
            return false
        }
        else if (lenght < 6) {
            this.setState({ errorCode: INVALID_LENGTH })
            document.getElementById('input-code').setCustomValidity(INVALID_LENGTH);
            return false
        }
        else {
            this.setState({ errorCode: false })
            document.getElementById('input-code').setCustomValidity('');
            return true
        }
    }


    handleCodeSubmit() {
        if (!this.validateLength(this.state.code.length)) return;
        let loginString = this.props.kundenportalLoginString;
        // loginString = '1f8d3e61-e3a3-4569-9ac1-642f85a88375';
        this.props.loginKundenportal(loginString, this.state.code)
    }
    handleCodeChange(event) {
        this.setState({ code: event.target.value });
        this.validateRequired(event.target.value)
    }

    render() {

        const { classes, kundenportalLoginErrorMessage } = this.props;

        return (
            <Grid container justifyContent="center" style={{ marginTop: '85px' }} spacing={0}>
                <img className={classes.logo} alt='Logo' src={ProgarantLogo}></img>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography color="primary" style={{ textAlign: 'center' }} variant="h2">
                                        Pro Garant Login
                                    </Typography>
                                    <Typography color="primary" style={{ textAlign: 'center' }} variant="h2">
                                        <strong>Garantiebedingungen</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <p>
                                        Bitte tragen Sie hier Ihren 6-stelligen Code ein, den wir Ihnen an Ihre E-Mail geschickt haben.
                                        Dies kann einige Minuten dauern. Bitte schauen Sie ggf. in Ihrem Spam-Ordner nach.
                                    </p>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'center', paddingRight: '10%', paddingLeft: '10%' }}>
                                    <div className={classes.input}>
                                        <input
                                            // required
                                            // validate={required}
                                            id='input-code'
                                            maxLength={8}
                                            type='text'
                                            value={this.state.code}
                                            onChange={this.handleCodeChange}
                                            placeholder='- - - - - -'>
                                        </input>
                                        {this.state.errorCode && <p>{this.state.errorCode}</p>}
                                        {kundenportalLoginErrorMessage && <p>{ERROR_WRONG_CODE}</p>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'right', paddingRight: '10%', paddingLeft: '10%' }}>
                                    <Button className={classes.submitButton} disableRipple color="primary" onClick={this.handleCodeSubmit} disabled={this.state.codeCheck}>
                                        Abschicken<FordwardIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {this.renderFooter()}
            </Grid>
        )

    }
    renderFooter() {
        const { classes } = this.props;

        return (
            <Grid style={{ marginTop: '3em' }} item xs={12}>
                <Grid item xs={12} container justifyContent="center">
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/" type="button">
                        www.progarant.de
                    </LinkButton>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/datenschutz" type="button">
                        Datenschutz
                    </LinkButton>
                    <span className={classes.middleDot}>.</span>
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/impressum" type="button">
                        Impressum
                    </LinkButton>
                </Grid>

            </Grid>
        )
    }
}
function mapStateToProps(state) {
    const { kundenportalGarantieId, kundenportalEmail, kundenportalLoginString, kundenportalLoginErrorMessage } = state.app;
    return {
        kundenportalGarantieId: kundenportalGarantieId,
        kundenportalEmail: kundenportalEmail,
        kundenportalLoginString: kundenportalLoginString,
        kundenportalLoginErrorMessage: kundenportalLoginErrorMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo: (path) => dispatch(push(path)),
        loginKundenportal: (kundenportalLoginString, kundenportalOtp) => dispatch(loginKundenportal(kundenportalLoginString, kundenportalOtp)),
    }
};
KundenLogin.propTypes = {
    classes: PropTypes.object.isRequired,
    goTo: PropTypes.func.isRequired,
    loginKundenportal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(KundenLogin));
