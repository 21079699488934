import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Form } from 'react-final-form'
import {withStyles} from '@material-ui/core/styles'
import { connect } from 'react-redux';
import createDecorator from 'final-form-focus';
import MakeAsyncFunction from 'react-redux-promise-listener'
import {promiseListener} from '../../ConfigureStore';
import {actions} from '../../Reducers/App';
import { push } from 'connected-react-router';
import {Paths} from '../../Constants/Paths';
import LoginForm from '../../Components/Partials/LoginForm';

const focusOnError = createDecorator();


const styles = theme => ({
    cardContent: {
        padding:'96px 54px 33px 54px'
    },
    submitButton:{
        padding:0
    }
});


class Login extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handleSubmit      = this.handleSubmit.bind(this);
    }

    validate = values => {
        return this.props.validate ? this.props.validate(values) : {}
    };

    handleSubmit = values => {
        this.props.handleLogin(values.username,values.password);
    };

    onClickforget = () => {
        this.props.goTo(Paths.FORGET);
    };


    render() {

        const {classes,jwToken} = this.props;
        const initialValues = {jwToken:jwToken};
        return (
            <Grid container justifyContent="center" style={{marginTop:'0px'}} spacing={0}>
                <Grid item xs={12} sm={8}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <MakeAsyncFunction
                            listener={promiseListener}
                            start={actions.LOGIN}
                            resolve={actions.LOGIN_SUCCESS}
                            reject={actions.LOGIN_ERROR}
                            >
                            {onSubmit => {
                                return (
                                    <Form
                                        validate={this.validate}
                                        onSubmit={onSubmit}
                                        initialValues={initialValues}
                                        decorators={[focusOnError]}
                                    >
                                        {({submitError,handleSubmit, submitting,pristine, values }) => {
                                            return (
                                                <LoginForm onClickforget={(e) => this.onClickforget()} onSubmit={onSubmit} submitError={submitError} handleSubmit={handleSubmit} submitting={submitting} pristine={pristine} values={values} />
                                        )}}
                                    </Form>
                                )}}
                            </MakeAsyncFunction>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

Login.propTypes = {
    classes:PropTypes.object.isRequired,
    goTo:PropTypes.func.isRequired,
    jwToken:PropTypes.string,
};

function mapStateToProps(state) {
    const {jwToken} = state.app;
    return {
        jwToken:jwToken
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Login));
