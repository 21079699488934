import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BackwardIcon(props) {
    return (
        <SvgIcon viewBox="0 0 43 47" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(21.500000, 23.500000) scale(-1, 1) translate(-21.500000, -23.500000) ">
                    <path d="M1.00547198,38 L3.62530754,38 C7.55667552,42.3016816 13.2132247,45 19.5,45 C31.3741221,45 41,35.3741221 41,23.5 C41,11.6258779 31.3741221,2 19.5,2 C12.7391636,2 6.70718254,5.12060052 2.76585833,10 L0.262190941,10 C4.5140502,3.95216627 11.5454862,0 19.5,0 C32.4786916,0 43,10.5213084 43,23.5 C43,36.4786916 32.4786916,47 19.5,47 C11.9927682,47 5.30772353,43.479798 1.00547198,38 Z" fill="currentColor" fillRule="nonzero"></path>
                    <polyline stroke="currentColor" strokeWidth="2" points="16 17 24 23.9369213 16 31"></polyline>
                </g>
            </g>
        </SvgIcon>
    );
};