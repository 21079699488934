import React from 'react';
import {Button, Table, TableBody, TableCell, TableRow, TableHead, makeStyles} from '@material-ui/core';
import DownloadIcon from '../../../Assets/Svg/Downloads';
import ViewIcon from '../../../Assets/Svg/Magnifier';
import {
    loadPdfFromPath,
    openPdfFromPath
} from "../../../Reducers/App";
import {connect} from "react-redux";
import PropTypes from "prop-types";


const useStyles = makeStyles(theme => ({
    menuIcon:{
        backgroundColor:'#00468E',
        borderRadius:'100%',
        minHeight:`${theme.typography.round(86 /11.4)}em`,
        minWidth:`${theme.typography.round(86 /11)}em`,
        height:`${theme.typography.round(86 /11)}em`,
        width:`${theme.typography.round(86 /11)}em`,
        color:'#ffffff',
        margin:'0 auto',
        marginBottom:`${theme.typography.round(20 /11)}em`,
        '& svg':{
            width:'40px',
            height:'auto'
        }
    },
    cardContent:{
        padding:'30px 30px 40px 30px'
    },
    table:{
        marginBottom:'16px'
    },
    tableWrapper:{
        paddingTop:'16px',
        borderTop:'2px solid #F3F3F3',
        marginTop:'55px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableHeadCell:{
        borderBottom:'1px solid #3F3F3F',
        padding:'10px 13px 10px 13px',
        fontWeight: 'bold'
    },
    tableBodyCell:{
        fontSize:'14px',
        padding:'11px 13px 11px 13px',
    },
    tableBodyCellAction:{
        fontSize:'14px',
        padding:'0px 13px 0px 13px',
        color:'#00468E'
    },
    actionButton:{
        color:'#00468E',
        padding:'9px',
        '& svg':{
            width:'24px',
            height:'24px'
        },
        '&:hover':{
            backgroundColor:'transparent'
        }
    },
    actionButtonLabel:{
        fontSize:'12px',
        color:'rgba(63,63,63,.4)',
        textTransform:'none',
        marginLeft:'14px'
    }
}));

const DownloadTable = ({downloads, getPdfFromPath, openPdfFromPath, titel}) => {

    const classes = useStyles();

    const onClickDownload = (item) => {
        getPdfFromPath(item.downloadUri,item.name);
    }

    const onClickView = (item) => {
        openPdfFromPath(item.downloadUri,item.name);
    }

    return (
        <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'
        >
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeadCell}>{titel}</TableCell>
                    <TableCell className={classes.tableHeadCell}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {downloads.map((row, index) => {
                    return (
                        <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                        >
                            <TableCell className={classes.tableBodyCell}
                                       align="left">{row.name}</TableCell>
                            <TableCell className={classes.tableBodyCellAction} align="right">
                                <Button onClick={(e) => onClickDownload(row)}
                                        className={classes.actionButton} size="small"
                                        disableRipple><DownloadIcon/><span
                                    className={classes.actionButtonLabel}>Download</span></Button>
                                <Button onClick={(e) => onClickView(row)}
                                        className={classes.actionButton} size="small"
                                        disableRipple><ViewIcon/><span
                                    className={classes.actionButtonLabel}>Ansehen</span></Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
                {downloads.length === 0 && (
                    <TableRow style={{height: 42}}>
                        <TableCell className={classes.tableBodyCell}
                                   style={{backgroundColor: '#ffffff'}} colSpan={4}>
                            <em>Keine Downloads vorhanden</em>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};


function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPdfFromPath: (path, filename) => dispatch(loadPdfFromPath(path, filename)),
        openPdfFromPath: (path, filename) => dispatch(openPdfFromPath(path, filename))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadTable);

