import React from 'react';
import PropTypes from 'prop-types';
import {Typography,Box,Grid,Card,CardContent,Button,Table,TableBody,TableCell,TablePagination,TableRow} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import DownloadIcon from '../../../Assets/Svg/Downloads';
import ViewIcon from '../../../Assets/Svg/Magnifier';
import EnhancedTableHead from '../../../Helpers/EnhancedTableHead';
import {loadDownloads} from '../../../Reducers/App';
import { motion } from "framer-motion"

const styles = theme => ({
    menuIcon:{
        backgroundColor:'#00468E',
        borderRadius:'100%',
        minHeight:`${theme.typography.round(86 /11.4)}em`,
        minWidth:`${theme.typography.round(86 /11)}em`,
        height:`${theme.typography.round(86 /11)}em`,
        width:`${theme.typography.round(86 /11)}em`,
        color:'#ffffff',
        margin:'0 auto',
        marginBottom:`${theme.typography.round(20 /11)}em`,
        '& svg':{
            width:'40px',
            height:'auto'
        }
    },
    cardContent:{
        padding:'30px 30px 40px 30px'
    },
    table:{
        marginBottom:'16px'
    },
    tableWrapper:{
        paddingTop:'16px',
        borderTop:'2px solid #F3F3F3',
        marginTop:'55px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableHeadCell:{
        borderBottom:'1px solid #3F3F3F',
        padding:'10px 13px 10px 13px'
    },
    tableBodyCell:{
        fontSize:'14px',
        padding:'11px 13px 11px 13px',
    },
    tableBodyCellAction:{
        fontSize:'14px',
        padding:'0px 13px 0px 13px',
        color:'#00468E'
    },
    actionButton:{
        color:'#00468E',
        padding:'9px',
        '& svg':{
            width:'24px',
            height:'24px'
        },
        '&:hover':{
            backgroundColor:'transparent'
        }
    },
    actionButtonLabel:{
        fontSize:'12px',
        color:'rgba(63,63,63,.4)',
        textTransform:'none',
        marginLeft:'14px'
    }
});

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
}

function humanFileSize(bytes, si) {
    const thresh = si ? 1000 : 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class DownloadList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            order:'asc',
            orderBy:'datum',
            page:0,
            rowsPerPage:10
        };

        this.handleRequestSort          = this.handleRequestSort.bind(this);
        this.handleChangePage           =  this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage    =  this.handleChangeRowsPerPage.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
        this.onClickView = this.onClickView.bind(this);
    }

    componentDidMount() {
        this.props.getDownloads();
    }

    onClickDownload(item){
        window.open(item.downloadUri, "_blank");
    }

    onClickView(item){
        window.open(item.uri, "_blank");
    }

    handleChangePage(e,newPage) {
        this.setState(state => ({
            page:newPage
        }));
    }

    handleChangeRowsPerPage(e) {
        //setRowsPerPage(+event.target.value);

        this.setState(state => ({
            page:0,
            rowsPerPage:parseInt(e.target.value,10)
        }));
    }

    handleRequestSort(property) {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState(state => ({
            order:isDesc ? 'asc' : 'desc',
            orderBy:property
        }));
    };

    render() {

        const {classes,downloads,isLoading} = this.props;
        const {page,rowsPerPage,order,orderBy} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, downloads.length - page * rowsPerPage);

        const headCells = [
            { id: 'name', numeric: false, disablePadding: false, label: 'Titel',sortable:true,align:'left'},
            { id: 'size', numeric: true, disablePadding: false, label: 'Größe',sortable:true,align:'left'},
            { id: 'pdf', numeric: false, disablePadding: false, label: 'Garantie (PDF)',sortable:true,align:'left'},
        ];

        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <Typography color="primary" style={{marginTop:20}} variant="h2">Downloads</Typography>
                        <Typography style={{marginTop:'20px'}}>Mehr Service für Sie</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <motion.div
                        initial={{ opacity: 0,scale:.9,y:10 }}
                        animate={{ opacity: 1,scale:1,y:0 }}
                        exit={{ opacity: 0 }}
                        transition={{delay:0}}
                    >
                    <Card>
                        <CardContent >
                            <div className={classes.tableWrapper}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        headCells={headCells}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={downloads.length}
                                    />
                                    <TableBody>
                                        {stableSort(downloads, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {

                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={page+"-"+rowsPerPage+"-"+index}
                                                    >
                                                        <TableCell className={classes.tableBodyCell} align="left">{row.name}</TableCell>
                                                        <TableCell className={classes.tableBodyCell} align="left">{humanFileSize(row.size,true)}</TableCell>
                                                        <TableCell className={classes.tableBodyCellAction} align="left">
                                                            <Button onClick={(e) => this.onClickDownload(row)} className={classes.actionButton} size="small" disableRipple><DownloadIcon /><span className={classes.actionButtonLabel}>Download</span></Button>
                                                            <Button onClick={(e) => this.onClickView(row)} className={classes.actionButton} size="small" disableRipple ><ViewIcon /><span className={classes.actionButtonLabel}>Ansehen</span></Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {!isLoading && downloads.length > 0 && emptyRows > 0 && (
                                            <TableRow style={{ height: 42 * emptyRows }}>
                                                <TableCell colSpan={3} />
                                            </TableRow>
                                        )}
                                        {!isLoading && downloads.length === 0 && (
                                            <TableRow style={{ height: 42}}>
                                                <TableCell className={classes.tableBodyCell} style={{backgroundColor:'#ffffff'}} colSpan={4} >
                                                    <em>Keine Downloads vorhanden</em>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={downloads.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage="Zeilen pro Seite:"
                                    labelDisplayedRows={({ from, to, count }) => (from+"-"+to+" von "+count)}
                                    backIconButtonProps={{
                                        'aria-label': 'vorherige Seite',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'nächste Seite',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </div>
                        </CardContent>
                    </Card>
                    </motion.div>
                </Grid>
            </Grid>
        );
    }
}

DownloadList.propTypes = {
    classes:PropTypes.object.isRequired,
    goTo:PropTypes.func.isRequired,
    getDownloads:PropTypes.func.isRequired,
    isLoading:PropTypes.bool.isRequired,
    downloads:PropTypes.array.isRequired,
};


function mapStateToProps(state) {
    return {
        isLoading:state.app.downloadsLoading,
        downloads:state.app.downloads
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path)),
        getDownloads:() => dispatch(loadDownloads())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(DownloadList));
