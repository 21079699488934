import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import ProgarantLogo from '../../Assets/Png/logo512.png';
import DownloadIcon from '../../Assets/Svg/Downloads';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { loadKundenportalGarantie, loadKundenportalGarantiePdf } from '../../Reducers/App';


const styles = theme => ({
    cardContent: {
        padding: '96px 54px 33px 54px',
        '@media (max-width: 500px)': {
            padding: '96px 10px 33px 10px',
        },
    },
    submitButton: {
        padding: 0
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',

        "& td": {
            width: '50%',
            padding: '10px'
        },
        "& tr": {

        },
        "& tr:nth-child(odd)": {
            backgroundColor: '#F5F5F5'
        }
    },
    logo: {
        width: '200px',
        height: 'auto',
        position: 'absolute',
        top: '0px'
    },
    actionButton: {
        color: '#00468E',
        background: '#e2eaf2',
        border: '1px solid #00468E',
        // borderColor: 'blue',

        padding: '9px',
        '& svg': {
            width: '24px',
            height: '24px'
        },
        '&:hover': {

        }
    },
    actionButtonLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#00468E',
        textTransform: 'none',
        marginLeft: '14px'
    },
    hr: {
        marginBottom: '2em',
        border: '1px solid #f4f4f4'
    },
    middleDot: {
        display: 'inline-block',
        height: '0.5em',
        margin: '0 0.2em',
        fontSize: '1.5em'
    }
});

const LinkButton = withStyles(theme => ({
    root: {
        '&:hover': {
            // fontWeight: 'bold',
            backgroundColor: 'transparent'
        }
    },
    label: {
        textTransform: 'none',
        // textDecoration: 'underline'
    }

}))(Button);

class KundenVertragsdetails extends React.PureComponent {

    constructor(props) {
        super(props);
        this.props = props
        this.state = {};
        let token = this.props.kundenportalJwToken;

        // token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2Rldi5wcm9nYXJhbnQuZGUvd2ViZ2FyL2FwaS9jdXN0b21lci9hdXRoL2xvZ2luIiwiaWF0IjoxNjg1NDI4OTQ3LCJleHAiOjE2ODU0MzI1NDcsIm5iZiI6MTY4NTQyODk0NywianRpIjoibWlMclZLZFZ3eFJhV2doZCIsInN1YiI6IjQ5OWVjYzVhLWYzMDEtNDgwYy04MmE3LWJjYTBjY2U5NGJiYyIsInBydiI6IjljMDlkZTY4ODA1YzQwNzVhOTkwYjkwMzdlZWIzNjg5YjkyYzkzMjIifQ.OAswOPuSyZ7s2xrSuo08gfkd6CqbHkq5a_wfNCMQ3H0"


        this.downloadPdf = this.downloadPdf.bind(this);
        this.reloadGarantie = this.reloadGarantie.bind(this);
        this.props.loadKundenportalGarantie(token, this.state.code)
    }
    downloadPdf() {
        let path = '/customer/pdf/garantiebedingungen';
        let filename = this.props.kundenportalGarantieId + '.pdf';

        this.props.loadKundenportalGarantiePdf(path, filename)
    }
    reloadGarantie() {
        let token = this.props.kundenportalJwToken;
        console.log('test')
        this.props.loadKundenportalGarantie(token, this.state.code)
    }

    render() {

        const { classes, kundenportalGarantie, kundenportalGarantieLoaded, kundenportalGarantieError } = this.props;
        return (
            <Grid container justifyContent="center" style={{ marginTop: '85px' }} spacing={0}>
                <img className={classes.logo} alt='Logo' src={ProgarantLogo}></img>
                <Grid item xs={12} sm={12} md={8}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                                        <Typography color="primary" variant="h1">
                                            Ihre Garantie
                                        </Typography>
                                        {kundenportalGarantieLoaded && !kundenportalGarantieError && <strong>{kundenportalGarantie.garantiename}</strong>}

                                    </Grid>
                                    {kundenportalGarantieLoaded && !kundenportalGarantieError &&
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <Button className={classes.actionButton} onClick={this.downloadPdf}><DownloadIcon /><span className={classes.actionButtonLabel}>Garantiebedingungen herunterladen</span></Button>
                                        </Grid>}
                                    {kundenportalGarantieLoaded && !kundenportalGarantieError && this.renderGarantienehmer()}
                                    {kundenportalGarantieLoaded && !kundenportalGarantieError && this.renderFahrzeugdaten()}
                                    {kundenportalGarantieLoaded && !kundenportalGarantieError && this.renderGarantieOptionen()}
                                    {kundenportalGarantieError &&
                                        <Grid item xs={12} style={{ textAlign: 'center' }} >
                                            <p>Beim Laden der Garantie ist ein fehler aufgetreten</p>
                                        </Grid>
                                    }
                                    {kundenportalGarantieError &&
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <Button className={classes.actionButton} onClick={this.reloadGarantie}><span className={classes.actionButtonLabel}>Erneut Laden</span></Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {this.renderFooter()}
            </Grid>
        );
    }
    renderFooter() {
        const { classes} = this.props;

        return (
            <Grid style={{ marginTop: '3em' }} item xs={12}>
                <Grid item xs={12} container justifyContent="center">
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/" type="button">
                        www.progarant.de
                    </LinkButton>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/datenschutz" type="button">
                        Datenschutz
                    </LinkButton>
                    <span className={classes.middleDot}>.</span>
                    <LinkButton rel="noopener noreferrer" target="_self" title="www.progarant.de" href="https://www.progarant.de/impressum" type="button">
                        Impressum
                    </LinkButton>
                </Grid>

            </Grid>
        )
    }
    renderGarantienehmer() {
        const { classes, kundenportalGarantie } = this.props;
        return (
            <Grid item xs={12}>
                <Typography color="primary" variant="h4">
                    Garantienehmer
                </Typography>
                {/* <div style={{height: '5px'}}></div> */}
                <hr className={classes.hr}></hr>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td>Garantienummer:</td>
                            <td>{kundenportalGarantie.garnr}</td>
                        </tr>
                        <tr>
                            <td>Name/ Firma:</td>
                            <td>{kundenportalGarantie.customerTitle} {kundenportalGarantie.customerName}</td>
                        </tr>
                        <tr>
                            <td>E-Mail:</td>
                            <td>{kundenportalGarantie.customerMail}</td>
                        </tr>
                        <tr>
                            <td>Straße, Hausnummer:</td>
                            <td>{kundenportalGarantie.customerStreet}</td>
                        </tr>
                        <tr>
                            <td>Postleitzahl, Ort:</td>
                            <td>{kundenportalGarantie.customerPostcode}, {kundenportalGarantie.customerCity}</td>
                        </tr>
                        <tr>
                            <td>Telefon:</td>
                            <td>{kundenportalGarantie.customerTelephone}</td>
                        </tr>
                        <tr>
                            <td>Geb. Datum:</td>
                            <td>{kundenportalGarantie.customerBirthday}</td>
                        </tr>
                    </tbody>

                </table>
            </Grid>
        )
    }
    renderFahrzeugdaten() {
        const { classes, kundenportalGarantie } = this.props;
        return (
            <Grid item xs={12}>
                <Typography color="primary" variant="h4">
                    Fahrzeugdaten
                </Typography>
                <hr className={classes.hr}></hr>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td>Herstellung/ Typ und Ausführung:</td>
                            <td>{kundenportalGarantie.manufacturer} {kundenportalGarantie.model}</td>
                        </tr>
                        <tr>
                            <td>KW/ PS:</td>
                            <td>{kundenportalGarantie.kw}/ {kundenportalGarantie.ps}</td>
                        </tr>
                        <tr>
                            <td>Amtl. Kennzeichen:</td>
                            <td>{kundenportalGarantie.platenumber}</td>
                        </tr>
                        <tr>
                            <td>Kaufpreis:</td>
                            <td>{kundenportalGarantie.price}</td>
                        </tr>
                        <tr>
                            <td>Fahrgestellnummer:</td>
                            <td>{kundenportalGarantie.vehicleNumber}</td>
                        </tr>
                        <tr>
                            <td>HSN/ TSN:</td>
                            <td>{kundenportalGarantie.hsn}/ {kundenportalGarantie.tsn}</td>
                        </tr>
                        <tr>
                            <td>Gesamtlaufleistung:</td>
                            <td>{kundenportalGarantie.gesamtlaufleistung}</td>
                        </tr>
                        <tr>
                            <td>KM-Stand lt. Tacho:</td>
                            <td>{kundenportalGarantie.km}</td>
                        </tr>
                        {/* <tr>
                            <td>Hubraum:</td>
                            <td>{!!kundenportalGarantie.ccm ? kundenportalGarantie.ccm : '-'} ccm</td>
                        </tr> */}
                        <tr>
                            <td>Allrad:</td>
                            <td>{kundenportalGarantie.allrad}</td>
                        </tr>
                        <tr>
                            <td>Erstzulassung:</td>
                            <td>{kundenportalGarantie.erstzulassung}</td>
                        </tr>
                    </tbody>

                </table>
            </Grid>
        )
    }
    renderGarantieOptionen() {
        const { classes, kundenportalGarantie } = this.props;
        return (
            <Grid item xs={12}>
                <Typography color="primary" variant="h4">
                    Garantie Optionen
                </Typography>
                <hr className={classes.hr}></hr>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td>Garantievariante:</td>
                            <td>{kundenportalGarantie.garantievariante}</td>
                        </tr>
                        <tr>
                            <td>Mobilitätsgarantie:</td>
                            <td>{!!kundenportalGarantie.mobilitaetsgarantie ? 'Ja' : 'Nein'}</td>
                        </tr>
                        {/* <tr>
                            <td>Fahrzeug:</td>
                            <td>{kundenportalGarantie.platenumber}</td>
                        </tr> */}
                        <tr>
                            <td>Laufzeit bis zu:</td>
                            <td>{kundenportalGarantie.laufzeit}</td>
                        </tr>
                        {/* <tr>
                            <td>Die Garantiezusage erfolgt:</td>
                            <td>{!!kundenportalGarantie.freeOfCharge ? 'unentgeltlich (ist im Fahrzeugpreis enthalten)' : 'entgeltlich (gegen gesonderte/gesondert ausgewiesene Berechnung)'}</td>
                        </tr> */}
                        {
                            //gebraucht condition === 2
                            kundenportalGarantie.condition === 2 &&
                            <tr>
                                <td>Laufzeitbeginn = Tag der Wiederzulassung:</td>
                                <td>{!!kundenportalGarantie.wiederzulassung ? kundenportalGarantie.wiederzulassung : '-'}</td>
                            </tr>
                        }
                        {
                            //neuwagen condition === 1
                            kundenportalGarantie.condition === 1 &&
                            <tr>
                                <td>Laufzeitbeginn = Tag der Beendigung der Werksgarantie:</td>
                                <td>{!!kundenportalGarantie.endWerksgarantieDate ? kundenportalGarantie.endWerksgarantieDate : '-'}</td>
                            </tr>
                        }
                        <tr>
                            <td>Verkaufsdatum lt. Kaufvertrag:</td>
                            <td>{kundenportalGarantie.verkaufsdatum}</td>
                        </tr>
                    </tbody>

                </table>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    const { kundenportalGarantieId, kundenportalEmail, kundenportalLoginString, kundenportalLoginErrorMessage, kundenportalJwToken, kundenportalGarantie, kundenportalGarantieLoaded, kundenportalGarantieError } = state.app;
    return {
        kundenportalGarantieId: kundenportalGarantieId,
        kundenportalEmail: kundenportalEmail,
        kundenportalLoginString: kundenportalLoginString,
        kundenportalLoginErrorMessage: kundenportalLoginErrorMessage,
        kundenportalJwToken: kundenportalJwToken,
        kundenportalGarantie: kundenportalGarantie,
        kundenportalGarantieLoaded: kundenportalGarantieLoaded,
        kundenportalGarantieError: kundenportalGarantieError,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo: (path) => dispatch(push(path)),
        loadKundenportalGarantie: (kundenportalJwToken) => dispatch(loadKundenportalGarantie(kundenportalJwToken)),
        loadKundenportalGarantiePdf: (path, filename) => dispatch(loadKundenportalGarantiePdf(path, filename)),
    }
};

KundenVertragsdetails.propTypes = {
    classes: PropTypes.object.isRequired,
    goTo: PropTypes.func.isRequired,
    loadKundenportalGarantie: PropTypes.func.isRequired,
    loadKundenportalGarantiePdf: PropTypes.func.isRequired,
};



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(KundenVertragsdetails));
