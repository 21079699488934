import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import {IconButton, InputAdornment, makeStyles} from "@material-ui/core";
import { Field } from 'react-final-form';
import {useEffect, useMemo, useState} from "react";
import RemoveIcon from "../Assets/Svg/Remove";
import {useDropzone,ErrorCode} from 'react-dropzone'

const useStyles = makeStyles((theme) => ({
    '@global': {
        '@keyframes upload-progress': {
            '0%': {
                backgroundPosition: '0 0',
            },
            '100%': {
                backgroundPosition: '-70px 0',
            },
        },
    },
    root: {
        marginTop:'0 !important',
        marginBottom:20,
        '& input':{
            position: 'relative',
            zIndex: 2,
            width: '100%',
            height: '50px',
            padding:0,
            cursor:'pointer',
            margin: 0,
            opacity: 0,
            '&.Mui-disabled':{
                opacity: 0,
                cursor:'default'
            }
        },
        '&:after':{
            display:'none'
        },
        '&:before':{
            display:'none'
        }
    },
}));

const useLabelStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1,
        cursor:'pointer',
        height: '50px',
        padding: '0.375rem 0.75rem',
        paddingRight: '180px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 300,
        lineHeight: '36px',
        fontSize:18,
        color: '#495057',
        backgroundColor: '#fff',
        border: '1px solid #00468E',
        '&.Mui-disabled':{
            border: '1px solid #AAC2DA',
            color:'#BFBFBF',
            cursor:'default'
        },
        borderRadius: '6px',
        transform:"none",
        '&:after':{
            content: "'hochladen'",
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 3,
            display: 'block',
            height: '100%',
            padding: '0.375rem 0.75rem',
            lineHeight: '36px',
            color: '#495057',
            backgroundColor: '#e9ecef',
            borderLeft: 'inherit',
            borderRadius:'0px 6px 6px 0px',
        },
        '&.Mui-disabled:after':{
            backgroundColor: '#FBFBFB',
            color:'#BFBFBF'
        },
    },
}));

const useAdornmentStyles = makeStyles((theme) => ({
    root:{
        zIndex:20,
        position:'absolute',
        top:'25px',
        right:'130px'
    }
}));

const useButtonStyles = makeStyles((theme) => ({
    root:{
        '&:hover':{
            background:'none'
        },
        '&.Mui-disabled':{
            opacity:.5
        }
    }
}));

const useHelperStyles = makeStyles((theme) => ({
    root:{
        marginTop:'-10px'
    }
}));

function convertBytesToMbsOrKbs(filesize) {
    var size = '';

    if (filesize >= 1048576) {
        size = filesize / 1048576 + ' Megabyte';
    } else if (filesize >= 1024) {
        size = filesize / 1024 + ' Kilobyte';
    } else {
        size = filesize + ' Mytes';
    }

    return size;
}

const baseStyle = {
};

const activeStyle = {
    animation: 'upload-progress 2s linear infinite',
    backgroundSize: '150% 100%',
    backgroundImage: 'repeating-linear-gradient(-45deg, #fff, #fff 25px, rgba(0, 0, 0, 0.12) 25px, rgba(0, 0, 0, 0.12) 50px)'
};

const acceptStyle = {
    animation: 'upload-progress 2s linear infinite',
    backgroundSize: '150% 100%',
    backgroundImage: 'repeating-linear-gradient(-45deg, #fff, #fff 25px, rgba(0, 0, 0, 0.12) 25px, rgba(0, 0, 0, 0.12) 50px)'
};

const rejectStyle = {
    backgroundImage: 'repeating-linear-gradient(-45deg, #fff, #fff 25px, #E00B1E 25px, #E00B1E 50px)'
};

const TextFieldWrapper = ({input: {name, onChange, value, ...restInput}, meta,maxFileSize,disabled,acceptedFiles,style,label, ...rest}) => {
    const [showError,setShowError]  = useState(false);
    const {
        submitError,
        dirtySinceLastSubmit,
        error,
        touched,
        modified
    }                               = meta;
    const classes                   = useStyles();
    const labelClasses              = useLabelStyles();
    const adornmentClasses          = useAdornmentStyles();
    const buttonClasses             = useButtonStyles();
    const helperClasses             = useHelperStyles();
    const [files,setFiles]          = useState(value);
    const maxSize                   = maxFileSize ?? 10485760;
    const accept                    = {
        'image/jpeg': [".jpg",".jpeg"],
        'image/png': [".png"],
        'application/pdf':[".pdf"]
    };
    const filesMax                  = 1;
    const [errors,setErrors]        = useState([]);

    useEffect(() => {
        if(
            meta.error &&
            !showError &&
            !disabled &&
            !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified))
        ){
            setShowError(true);
            setErrors([meta.error]);
        }
    },[meta.error,showError,submitError,dirtySinceLastSubmit,error,touched,modified,disabled])



    const handleClickRemoveFile = () => {
        onChange([]);
        setFiles([]);
    }

    useEffect(() => {
        if(!!showError && !!errors && errors.length > 0){
            let timer = setTimeout(() => {
                setShowError(false);
                setErrors(undefined);
            },4000)

            return () => {
                clearTimeout(timer);
            };
        }
    },[errors,showError])

    const currentLabel = !!files && !!files.length ? (files[0].hasOwnProperty('name')) ? files[0].name : files[0].path : label

    const handleDropAccepted = (newFiles) => {
        setShowError(false);
        setErrors(undefined);
        setFiles(newFiles);
        onChange(newFiles);
    }

    const handleDropRejected = (fileRejections) => {
        setShowError(true);
        let message = [];

        fileRejections.forEach((rejectedFile) => {
            if(!!rejectedFile.errors){
                rejectedFile.errors.forEach((error) => {
                    switch(error.code){
                        case ErrorCode.FileInvalidType:
                            message[message.length] = 'ungültiger Dateityp (erlaubt: ' + Object.keys(accept).reduce((previousValue, currentValue, currentIndex, array) => previousValue.concat(',',accept[currentValue].join(',')),'')+')';
                            break;

                        case ErrorCode.FileTooLarge:
                            message[message.length] = 'Dateigröße überschritten (maximal: ' + convertBytesToMbsOrKbs(maxSize) + ')';
                            break;

                        case ErrorCode.FileTooSmall:
                            message[message.length] = 'Dateigröße unterschritten';
                            break;

                        case ErrorCode.TooManyFiles:
                            message[message.length] = 'maximale Anzahl überschritten';
                            break;

                        default:
                            message[message.length] = 'ungültige Datei';
                            break;
                    }

                });
            }

        });

        setErrors(message);

    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles:filesMax,
        maxSize:maxSize,
        disabled:disabled,
        accept:accept,
        onDropAccepted:handleDropAccepted,
        onDropRejected:handleDropRejected
    })

    const rootStyle = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div {...getRootProps()}>
            <TextField
                {...rest}
                disabled={disabled}
                name={name}
                label={currentLabel}
                helperText={showError ? Array.isArray(errors) ? errors.join(", ") : errors : undefined}
                FormHelperTextProps={{
                    classes:helperClasses
                }}
                error={showError}
                onChange={({ target }) => {setFiles(target.files);onChange(target.files)}}
                inputProps={{
                    ...restInput,
                    ...getInputProps({style:{display:'block',pointerEvents:'none'}})
                }}
                InputLabelProps={{
                    style:rootStyle,
                    classes:labelClasses
                }}
                InputProps={{
                    style,
                    classes,
                    required: false,
                    endAdornment: !!files && files.length > 0 ? <InputAdornment classes={adornmentClasses} position="end"><IconButton disabled={disabled} classes={buttonClasses} disableFocusRipple disableTouchRipple disableRipple aria-label="Datei entfernen" onClick={handleClickRemoveFile} onMouseDown={handleClickRemoveFile}><RemoveIcon /></IconButton></InputAdornment> : null,
                }}
            />

        </div>
    );
};

const requiredFunc = value => {return (!!value && Array.isArray(value) && value.length === 1  ? undefined : 'Bitte Datei hochladen.')};

const UploadField = (props) => {

    const {name,label,disabled,required} = props;

    const validate = !!required && !disabled ? requiredFunc : undefined


    return (
        <Field
            fullWidth
            name={name}
            type="file"
            label={label}
            required={required}
            validate={validate}
            disabled={disabled}
        >
            {props => {
                return <TextFieldWrapper {...props} />
            }}
        </Field>
    );
}



export default UploadField;
