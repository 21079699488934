import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AddIcon(props) {
    return (
        <SvgIcon viewBox="0 0 22 22" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g  transform="translate(1.000000, 1.000000)" stroke="currentColor" strokeWidth="1.5">
                    <g >
                        <path d="M10,5 L10,15" />
                        <path d="M5,10 L15,10" />
                        <path d="M20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 C15.523,0 20,4.477 20,10 Z" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}
