import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles'
import {Card,CardContent,CardActionArea} from '@material-ui/core';
import AddIcon from '../../Assets/Svg/Add';
import ContinueIcon from '../../Assets/Svg/Continue'
import GarantielisteIcon from '../../Assets/Svg/Garantieliste';
import DownloadsIcon from '../../Assets/Svg/Downloads';
import InfoIcon from '../../Assets/Svg/Info';
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import {Paths} from '../../Constants/Paths';
import MailIcon from '../../Assets/Svg/Mail';
import PhoneIcon from '../../Assets/Svg/Phone';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {hideFirstLoginWindow} from '../../Reducers/App';
import { motion,AnimatePresence } from "framer-motion"
import Approve from '../Layouts/Approve';
import EuroIcon from "../../Assets/Svg/Euro";
import GuaranteeIcon from "../../Assets/Svg/GuaranteeIcon";

const styles = theme => ({
    menuIcon:{
        backgroundColor:'#00468E',
        borderRadius:'100%',
        minHeight:`${theme.typography.round(86 /11.4)}em`,
        minWidth:`${theme.typography.round(86 /11)}em`,
        height:`${theme.typography.round(86 /11)}em`,
        width:`${theme.typography.round(86 /11)}em`,
        color:'#ffffff',
        margin:'0 auto',
        marginBottom:`${theme.typography.round(20 /11)}em`,
        '& svg':{
            width:'40px',
            height:'auto'
        }
    },
    cardContent:{
        padding:'30px 30px 40px 30px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalApprove: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow:'scroll'
    },
    paper: {
        backgroundColor:'#ffffff',
        width:'580px',
        maxWidth:'90%',
        padding:'68px 114px 60px 114px',
        borderRadius:'6px',
        position:'relative',
        '&:focus':{
            outline:'none'
        }
    },
    paperApproved: {
        backgroundColor:'#ffffff',
        width:'850px',
        maxWidth:'90%',
        padding:'68px 80px 60px 80px',
        borderRadius:'6px',
        position:'relative',
        '&:focus':{
            outline:'none'
        }
    },
    closemodal:{
        display:'block',
        borderRadius:'100%',
        backgroundColor:'#00468E',
        position:'absolute',
        width:'50px',
        border:0,
        height:'50px',
        zIndex:5,
        right:'-25px',
        top:'-25px',
        '&:focus':{
            outline:'none'
        },
        '&:before':{
            display:'block',
            height:'2px',
            width:'27px',
            backgroundColor:'#ffffff',
            content:"''",
            position:'absolute',
            top:'25px',
            left:'12px',
            transform:'rotate(-45deg)'
        },
        '&:after':{
            display:'block',
            height:'2px',
            width:'27px',
            backgroundColor:'#ffffff',
            content:"''",
            top:'25px',
            left:'12px',
            position:'absolute',
            transform:'rotate(45deg)'
        }
    }
});


class Home extends React.PureComponent {

    handleFirstLoginClose(){
        this.props.hideModalWindow();
    }

    onClickUserSettings(){
        this.props.hideModalWindow();
        this.props.goTo(Paths.EINSTELLUNGEN);
    }

    render() {

        const {classes,ansprechpartner,showFirstLoginWindow,is_hv,is_deactivated,account_approved,edit_garantie_id} = this.props;


        const mainItems = [
            {title:((!!is_deactivated) ? 'Die Anlage einer Garantie ist momentan nicht möglich.\nBitte wenden Sie sich an die Zentrale in Castrop-Rauxel.' : 'Garantie anlegen'),icon:<AddIcon />,linkTo:Paths.GARANTIE_ADD,adminOnly:false,disabled:false},
            //{title:'Garantieeingabe fortsetzen',icon:<ContinueIcon />,linkTo:Paths.GARANTIE_LISTE,adminOnly:false,disabled:edit_garantie_id === null},
            {title:'Garantieliste',icon:<GarantielisteIcon />,linkTo:Paths.GARANTIE_LISTE,adminOnly:false,disabled:false},
            {title:'Garantiebedingungen',icon:<GuaranteeIcon />,linkTo:Paths.GARANTIEBEDINGUNGENPDFS,adminOnly:false,disabled:false},
            {title:'Preislisten',icon:<EuroIcon />,linkTo:Paths.PREISLISTENPDFS,adminOnly:false,disabled:false},
            {title:'Formulare/Downloads',icon:<DownloadsIcon />,linkTo:Paths.DOWNLOADS,adminOnly:false,disabled:false},
            {title:'Händlerinformation',icon:<InfoIcon />,linkTo:Paths.INFO,adminOnly:true,disabled:false}
        ].filter((item,index) => (item.adminOnly === false || (is_hv)) && item.disabled === false);

        /* <Grid key={index} item xs={12} sm={index === 0 && !is_hv && edit_garantie_id === null ? 12 : 6}>*/

        return (
            <>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <Typography color="primary" style={{marginTop:20}} variant="h2">Ihre Optionen</Typography>
                    </Box>
                </Grid>
                <AnimatePresence

                >
                    {mainItems.map((item,index) => (
                         <Grid key={index} item xs={12} sm={index === 0 && !is_hv && false ? 12 : 6}>
                             <motion.div
                                 initial={{ opacity: 0,scale:.9,y:50 }}
                                 animate={{ opacity: 1,scale:1,y:0 }}
                                 exit={{ opacity: 0 }}
                                 key={index}
                                 transition={{delay: .1*(index+1) }}
                             >
                            <Card>
                                <CardActionArea onClick={() => this.props.goTo(item.linkTo)}>
                                    <CardContent className={classes.cardContent}>
                                        <Box textAlign="center">
                                            {item.icon && <Box display="flex" justifyContent="center" alignItems="center" className={classes.menuIcon}>
                                                {item.icon}
                                            </Box>}
                                            <Typography color="primary" style={{textOverflow:'ellipsis',overflow:'hidden'}} variant="subtitle1">{item.title.split('\n').map((text, index) => (
                                                <React.Fragment key={`${text}-${index}`}>
                                                    {text}
                                                    <br />
                                                </React.Fragment>
                                            ))}</Typography>
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                             </motion.div>
                        </Grid>
                    ))}
                </AnimatePresence>
                {!!ansprechpartner &&
                    <>
                    <Grid item xs={12}>
                        <Box textAlign="center">
                            <Typography color="primary" style={{marginTop:20}} variant="h2">Ihr persönlicher Ansprechpartner</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent className={classes.cardContent}>
                                <Grid alignItems="center" container spacing={5}>
                                    {!!ansprechpartner.image_path && <Grid style={{textAlign:'center'}} item xs={4}>
                                        <img style={{width:'100%',height:'auto',maxWidth:'140px'}} alt="Ihr Ansprechpartner" src={ansprechpartner.image_path} />
                                    </Grid>}
                                    <Grid item xs={(!!ansprechpartner.image_path ? 8 : 12)}>
                                        {!!ansprechpartner.name && <Typography color="primary" variant="subtitle1" style={{lineHeight:'normal'}}>{ansprechpartner.name}</Typography>}
                                        {!!ansprechpartner.phone && <Typography style={{lineHeight:'41px'}}><PhoneIcon style={{height:'17px',width:'17px',marginRight:'10px'}} /><a href={"tel:" + String(ansprechpartner.phone).replace(/[^0-9]/g, '')}>{ansprechpartner.phone}</a></Typography>}
                                        {!!ansprechpartner.email && <Typography style={{lineHeight:'41px'}}><MailIcon style={{height:'12px',width:'17px',marginRight:'10px'}} /><a href={"mailto:" + ansprechpartner.email} >{ansprechpartner.email}</a></Typography>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    </>
                }
            </Grid>
            {!!account_approved && <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showFirstLoginWindow}
                onClose={(e) => this.handleFirstLoginClose(e)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                >
                <Fade in={showFirstLoginWindow}>
                    <div className={classes.paper}>
                        <button className={classes.closemodal} aria-label="schließen" onClick={(e) => this.handleFirstLoginClose(e)} title="schließen" />
                        <Typography color="primary" id="transition-modal-title" style={{marginBottom:'1rem'}} variant="h2">Sehr geehrter Kunde, </Typography>
                        <Typography id="transition-modal-description" variant="body1">
                            wir freuen uns, Sie im neuen und verbesserten Händlerbereich begrüßen zu dürfen.
                            <br /><br />
                            Vielen Dank.
                        </Typography>
                    </div>
                </Fade>
            </Modal>}
            {!account_approved && <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalApprove}
                open={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                <Fade in={true}>
                    <div className={classes.paperApproved}>
                        <Approve />
                    </div>
                </Fade>
            </Modal>}

            </>
        );
    }
}

Home.propTypes = {
    classes:PropTypes.object.isRequired,
    goTo:PropTypes.func.isRequired,
    showFirstLoginWindow:PropTypes.bool.isRequired,
    hideModalWindow:PropTypes.func.isRequired,
    is_hv:PropTypes.bool.isRequired,
    is_deactivated:PropTypes.bool.isRequired,
    account_approved:PropTypes.bool.isRequired,
    edit_garantie_id:PropTypes.number
};


function mapStateToProps(state) {

    return {
        ansprechpartner:state.app.ansprechpartner,
        showFirstLoginWindow:state.app.showFirstLoginWindow,
        is_hv:state.app.auth.is_hv,
        edit_garantie_id:state.app.auth.edit_garantie_id,
        is_deactivated:state.app.auth.is_deactivated,
        account_approved:(!!state.app.auth.account_approved) ? true : false,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path)),
        hideModalWindow: () => dispatch(hideFirstLoginWindow())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Home));
