import { CancelToken } from 'axios'
import { setup } from 'axios-cache-adapter'
import { CANCEL } from 'redux-saga'
import { objectToFormData } from 'object-to-formdata';


class NewsApi {

    findNews(){
        const source = CancelToken.source();
        const request =  this.axiosInstance.get(
            '/news/category/4/news'
            ,{ cancelToken: source.token });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getNews(uid){
        const source = CancelToken.source();
        const request =  this.axiosInstance.get(
            `/news/${uid}`,
            { cancelToken: source.token });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getDownloads(){
        const source = CancelToken.source();
        const request =  this.axiosInstance.get(
            `/download`,
            { cancelToken: source.token });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getPreislistenUndGarantiebedingungen(){
        const source = CancelToken.source();
        const request =  this.axiosInstance.get(
            `/user/pricelistsAndGarantiebedingungen`,
            { cancelToken: source.token });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    submitContact(values){
        const source = CancelToken.source();
        const formData = objectToFormData(values);

        if(formData.has('acceptedDataPrivacyPolicy')){
           if(String(formData.get('acceptedDataPrivacyPolicy')) === 'true'){
               formData.set('acceptedDataPrivacyPolicy',1);
           }else{
               formData.set('acceptedDataPrivacyPolicy',0);
           }
        }

        const request =  this.axiosInstance.post(
            `/kontakt`,formData,
            {
                cancelToken: source.token,
                headers: {'Content-Type': 'multipart/form-data'}
            });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    constructor(options) {
        this.axiosInstance = setup({
            baseURL: options.baseURL,
            timeout: options.timeout,
            cache: {
                maxAge: 15 * 60 * 1000,
                debug: false,
                exclude: {
                    query: false
                }
            }
        });
    }

}


let instance;
const getNewsApiInstance = (options) => {
    if (!instance) {
        instance = new NewsApi(options);
    } else if (options) {
        throw Error('Instance is already instantiate with `options`')
    }
    return instance;
};


export default getNewsApiInstance;
