import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Settings(props) {
    return (
        <SvgIcon viewBox="0 0 22 19" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g  transform="translate(-18.000000, -383.000000)" stroke="currentColor" strokeWidth="1.5">
                    <g  transform="translate(19.000000, 381.000000)">
                        <g  transform="translate(0.000000, 3.000000)">
                            <path d="M8.125,14.6875 C8.125,15.895625 7.145625,16.875 5.9375,16.875 C4.729375,16.875 3.75,15.895625 3.75,14.6875 C3.75,13.479375 4.729375,12.5 5.9375,12.5 C7.145625,12.5 8.125,13.479375 8.125,14.6875 Z" ></path>
                            <path d="M8.125,2.1875 C8.125,3.395625 7.145625,4.375 5.9375,4.375 C4.729375,4.375 3.75,3.395625 3.75,2.1875 C3.75,0.979375 4.729375,0 5.9375,0 C7.145625,0 8.125,0.979375 8.125,2.1875 Z" ></path>
                            <path d="M8.5870625,2.5 L20.0001875,2.5" ></path>
                            <path d="M0,2.5 L3.075,2.5" ></path>
                            <path d="M8.5870625,15 L20.0001875,15" ></path>
                            <path d="M0,15 L3.075,15" ></path>
                            <path d="M17.5,9.0625 C17.5,10.270625 16.520625,11.25 15.3125,11.25 C14.104375,11.25 13.125,10.270625 13.125,9.0625 C13.125,7.854375 14.104375,6.875 15.3125,6.875 C16.520625,6.875 17.5,7.854375 17.5,9.0625 Z" ></path>
                            <path d="M17.9538125,8.75 L20.0000625,8.75" ></path>
                            <path d="M0,8.75 L12.849375,8.75" ></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};