import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '../../Helpers/DefaultTextField';
import FordwardIcon from '../../Assets/Svg/Forward';
import { Form,Field } from 'react-final-form';
import {withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import createDecorator from 'final-form-focus';
import MakeAsyncFunction from 'react-redux-promise-listener'
import { promiseListener } from '../../ConfigureStore';
import {actions} from '../../Reducers/App';
import { push } from 'connected-react-router';
import {Paths} from '../../Constants/Paths';

const required = value => (value ? undefined : 'Bitte angeben');
const focusOnError = createDecorator();


const styles = theme => ({
    cardContent: {
        padding:'96px 54px 33px 54px'
    },
    submitButton:{
        padding:0
    }
});

const LinkButton = withStyles(theme => ({
    root: {
        '&:hover':{
            backgroundColor:'transparent'
        }
    },
    label:{
        textTransform:'none',
        textDecoration:'underline'
    }

}))(Button);

class Forget extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handleSubmit      = this.handleSubmit.bind(this);
    }

    validate = values => {
        return this.props.validate ? this.props.validate(values) : {}
    };

    handleSubmit = values => {
        this.props.handleRefresh(values.username);
    };

    onClickBack = () => {
        this.props.goTo(Paths.LOGIN);
    };


    render() {

        const {classes} = this.props;

        const label = 'Passwort vergessen ?';

        return (
            <Grid container justifyContent="center" style={{marginTop:'0px'}} spacing={0}>
                <Grid item xs={12} sm={8}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <MakeAsyncFunction
                            listener={promiseListener}
                            start={actions.PW_REFRESH}
                            resolve={actions.PW_REFRESH_SUCCESS}
                            reject={actions.PW_REFRESH_ERROR}
                            >
                            {onSubmit => (
                            <Form
                                validate={this.validate}
                                onSubmit={onSubmit}
                                decorators={[focusOnError]}
                            >
                                {({submitError,handleSubmit, submitting,pristine, values }) => (
                                    <form id="kontaktForm" onSubmit={handleSubmit}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <Typography style={{textAlign:'center',textTransform:'uppercase',marginBottom:'11px',color:'#474748'}} variant="body1">User Login</Typography>
                                                <Typography color="primary" style={{textAlign:'center'}} variant="h1">{label}</Typography>
                                                <Typography color="primary" style={{marginTop:'2em',textAlign:'center',color:'#474748'}} >Zum Ändern Ihres Passworts geben Sie hier bitte Ihre Kundennummer ein. Daraufhin erhalten Sie eine E-Mail mit weiteren Informationen.<br /><br /><strong>Wichtiger Hinweis:</strong> Sollten Sie keine E-Mail erhalten, wurde Ihr Konto oder die hinterlegte E-Mail-Adresse nicht gefunden. In diesem Fall wenden Sie sich bitte direkt an die PRO GARANT Zentrale unter der Telefonnummer: 02305 9779-7</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        fullWidth
                                                        required
                                                        validate={required}
                                                        name="username"
                                                        component={TextField}
                                                        type="text"
                                                        autoComplete="username"
                                                        label="Kundennummer"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {submitError && <Grid item style={{textAlign:'center',color:'#ff0000'}} xs={12}>{submitError}</Grid>}
                                            <Grid item style={{textAlign:'right'}} xs={12}>
                                                <Button className={classes.submitButton} disableRipple color="primary" type="submit" disabled={submitting}>
                                                    Passwort zurücksetzen<FordwardIcon/>
                                                </Button>
                                            </Grid>
                                            <Grid item style={{textAlign:'right'}} xs={12}>
                                                <LinkButton onClick={(e) => this.onClickBack(e)} disableRipple disabled={submitting} type="button">
                                                    zurück zum Login
                                                </LinkButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                            )}
                            </MakeAsyncFunction>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

Forget.propTypes = {
    classes:PropTypes.object.isRequired,
    goTo:PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Forget));
