import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Contact(props) {
    return (
        <SvgIcon viewBox="0 0 26 20" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g  transform="translate(-17.000000, -336.000000)" stroke="currentColor" strokeWidth="1.5">
                    <g  transform="translate(18.000000, 337.000000)">
                        <g  transform="translate(0.000000, -0.000000)">
                            <polyline  points="19.2828827 7.04336127 12.4276468 10.4875444 0.256196127 4.74917465 0.276689085 0.258484507 24.2203194 0.256093662 24.0522771 17.926488 0.311527113 17.9811359 0.422189085 6.73050211"></polyline>
                            <path d="M20.920919,6.22053486 L20.2726585,6.54637289" ></path>
                            <path d="M24.1282718,4.60917359 L21.7739725,5.7919588" ></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};