import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { connect } from 'react-redux';
import theme from './Styles/Theme';
import { Route, Switch,Redirect } from 'react-router';
import {Paths} from './Constants/Paths';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CancelIcon from './Assets/Svg/Cancel';
import FordwardIcon from './Assets/Svg/Forward';
import MakeAsyncFunction from 'react-redux-promise-listener'
import {actions} from './Reducers/App';
import { Form,Field } from 'react-final-form'
import createDecorator from 'final-form-focus';
import FormControl from '@material-ui/core/FormControl';
import TextField from './Helpers/DefaultTextField';
import { promiseListener } from './ConfigureStore';
// import Announcement from './Components/Partials/Announcement';
import WifiIcon from '@material-ui/icons/Wifi';

import AsyncKundenLogin from './Components/Layouts/KundenLogin';
import AsyncKundenPortal from './Components/Layouts/KundenPortal';
import AsyncKundenVertragsdetails from './Components/Layouts/KundenVertragsdetails';
import AsyncOverview from "./Components/Layouts/Overview";
import AsyncDownloads from "./Components/Layouts/Downloads";
import AsyncGarantieUndPreislistenDownload from "./Components/Layouts/GarantieUndPreislistenDownload";
import AsyncSettings from "./Components/Layouts/Settings";
import AsyncKontakt from "./Components/Layouts/Kontakt";
import AsyncGarantieliste from "./Components/Layouts/Garantieliste";
import AsyncGarantie from "./Components/Layouts/Garantie";
import AsyncHaendlerinfo from "./Components/Layouts/Info";
import AsyncLogin from "./Components/Layouts/Login";
import AsyncForget from "./Components/Layouts/Forget";
import AsyncForgetSuccess from "./Components/Layouts/ForgetSuccess";
import AsyncReset from "./Components/Layouts/Reset";
import AsyncResetSuccess from "./Components/Layouts/ResetSuccess";
import AsyncUrteil from "./Components/Layouts/Urteil";
import AsyncUrteile from "./Components/Layouts/Urteile";
import AsyncMainMenu from "./Components/Templates/MainMenu";
import AsyncHeader from "./Components/Templates/Header";
import DownloadPdfKacheln from "./Components/Templates/Download/DownloadPdfKacheln";

const required = value => (value ? undefined : 'Bitte angeben');
const focusOnError = createDecorator();
const styles = theme => ({
    content: {
        flexGrow: 1,
        padding:'46px',
        [theme.breakpoints.down('sm')]: {
            padding:'16px',
        },
        paddingTop:'15px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex:`${theme.zIndex.snackbar+10} !important`,
        '&:focus,:focus-visible':{
          outline:'none'
        }
    },
    paper: {
        backgroundColor:'#ffffff',
        width:'580px',
        maxWidth:'90%',
        padding:'68px 114px 60px 114px',
        borderRadius:'6px',
        position:'relative',
        '&:focus':{
            outline:'none'
        }
    },
    offlineWrap:{
      backgroundColor:'rgba(0,0,0,.7)',
      color:'#ffffff',
      textAlign:'center',
      borderRadius:theme.shape.borderRadius,
      padding:theme.spacing(4)
    }
});


/*const AsyncOverview = asyncComponent(() => import("./Components/Layouts/Overview"));
const AsyncDownloads = asyncComponent(() => import("./Components/Layouts/Downloads"));
const AsyncSettings = asyncComponent(() => import("./Components/Layouts/Settings"));
const AsyncKontakt = asyncComponent(() => import("./Components/Layouts/Kontakt"));
const AsyncGarantieliste = asyncComponent(() => import("./Components/Layouts/Garantieliste"));
const AsyncGarantie = asyncComponent(() => import("./Components/Layouts/Garantie"));
const AsyncHaendlerinfo = asyncComponent(() => import("./Components/Layouts/Info"));
const AsyncLogin = asyncComponent(() => import("./Components/Layouts/Login"));
const AsyncForget = asyncComponent(() => import("./Components/Layouts/Forget"));
const AsyncForgetSuccess = asyncComponent(() => import("./Components/Layouts/ForgetSuccess"));
const AsyncReset = asyncComponent(() => import("./Components/Layouts/Reset"));
const AsyncResetSuccess = asyncComponent(() => import("./Components/Layouts/ResetSuccess"));
const AsyncUrteil = asyncComponent(() => import("./Components/Layouts/Urteil"));
const AsyncUrteile = asyncComponent(() => import("./Components/Layouts/Urteile"));
const AsyncMainMenu = asyncComponent(() => import("./Components/Templates/MainMenu"));
const AsyncHeader = asyncComponent(() => import("./Components/Templates/Header"));*/



class App extends React.PureComponent {


    render() {

        const { classes, isAuthenticated, is_deactivated, showRelogin, is_hv, isOnline, kundenportalEmailSuccess, kundenportalIsAuthenticated } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Box display="flex">
                    <CssBaseline />
                    {isAuthenticated && <AsyncMainMenu />}
                    <main className={classes.content}>
                        {isAuthenticated && <AsyncHeader/>}
                        <Switch>
                            <Route exact path={Paths.KUNDENPORTAL} render={props =>
                                !kundenportalEmailSuccess ? <AsyncKundenPortal /> : <Redirect to={Paths.KUNDENPORTAL_LOGIN} />
                            } />
                            <Route exact path={Paths.KUNDENPORTAL_LOGIN} render={props =>
                                {
                                    if (kundenportalIsAuthenticated) return <Redirect to={Paths.VERTRAGSDETAILS} />
                                    else if (!kundenportalEmailSuccess) return <Redirect to={Paths.KUNDENPORTAL} />
                                    else return <AsyncKundenLogin/>
                                }
                            } />
                            <Route exact path={Paths.VERTRAGSDETAILS} render={props =>
                                kundenportalIsAuthenticated ? <AsyncKundenVertragsdetails /> : <Redirect to={Paths.KUNDENPORTAL} />
                            } />




                            <Route exact path={Paths.HOME} render={props =>
                                isAuthenticated ? <AsyncOverview /> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route path={Paths.GARANTIE_ADD} render={props =>
                                isAuthenticated && !is_deactivated ? <AsyncGarantie /> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.GARANTIE_LISTE} render={props =>
                                isAuthenticated ? <AsyncGarantieliste /> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.DOWNLOADS} render={props =>
                                isAuthenticated ? <AsyncDownloads /> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.GARANTIEUNDPREISLISTEN_DOWNLOADS} render={props =>
                                isAuthenticated ? <AsyncGarantieUndPreislistenDownload match={props.match}/> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.PREISLISTENPDFS} render={props =>
                                isAuthenticated ? <DownloadPdfKacheln downloadType={'preislisten'}/> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.GARANTIEBEDINGUNGENPDFS} render={props =>
                                isAuthenticated ? <DownloadPdfKacheln downloadType={'garantiebedingungen'}/> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.KONTAKT} render={props =>
                                isAuthenticated ? <AsyncKontakt /> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.EINSTELLUNGEN} render={props =>
                                isAuthenticated ? <AsyncSettings /> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.INFO} render={props =>
                                isAuthenticated && is_hv ? <AsyncHaendlerinfo /> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.URTEILE} render={props =>
                                isAuthenticated ? <AsyncUrteile /> : <Redirect to={Paths.LOGIN} />
                            } /> />
                            <Route path={Paths.URTEIL+":newsUid(\\d+)"} render={props =>
                                isAuthenticated ? <AsyncUrteil newsUid={props.match.params.newsUid} /> : <Redirect to={Paths.LOGIN} />
                            } />
                            <Route exact path={Paths.LOGIN} render={props =>
                                !isAuthenticated ? <AsyncLogin /> : <Redirect to={Paths.HOME} />
                            } /> />
                            <Route exact path={Paths.FORGET} render={props =>
                                !isAuthenticated ? <AsyncForget /> : <Redirect to={Paths.HOME} />
                            } /> />
                            <Route exact path={Paths.FORGET_SUCCESS} render={props =>
                                !isAuthenticated ? <AsyncForgetSuccess /> : <Redirect to={Paths.HOME} />
                            } /> />
                            <Route exact path={Paths.RESET} render={props =>
                                !isAuthenticated ? <AsyncReset search={props.location.search} /> : <Redirect to={Paths.HOME} />
                            } /> />
                            <Route exact path={Paths.RESET_SUCCESS} render={props =>
                                !isAuthenticated ? <AsyncResetSuccess /> : <Redirect to={Paths.HOME} />
                            } /> />
                            <Route render={() => (<div></div>)} />
                        </Switch>
                    </main>
                </Box>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={showRelogin}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                >
                    <Fade in={showRelogin}>
                        <div className={classes.paper}>
                            <Typography color="primary" id="transition-modal-title" style={{marginBottom:'1rem'}} variant="h2">Ihre Anmeldung ist abgelaufen</Typography>
                            <Typography>Um sich erneut anzumelden geben Sie bitte Ihre Kundennummer und Ihr Passwort ein.</Typography>
                            <MakeAsyncFunction
                                listener={promiseListener}
                                start={actions.LOGIN}
                                resolve={actions.LOGIN_SUCCESS}
                                reject={actions.LOGIN_ERROR}
                            >
                                {onSubmit => (
                                    <Form
                                        validate={this.validate}
                                        onSubmit={onSubmit}
                                        decorators={[focusOnError]}
                                    >
                                        {({submitError,handleSubmit, submitting,pristine, values }) => (
                                            <form id="loginForm" onSubmit={handleSubmit}>
                                                <Grid container style={{marginTop:'1rem'}} spacing={5}>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name="username"
                                                                component={TextField}
                                                                type="text"
                                                                autoComplete="username"
                                                                label="Kundennummer"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                fullWidth
                                                                required
                                                                validate={required}
                                                                name="password"
                                                                autoComplete="current-password"
                                                                component={TextField}
                                                                type="password"
                                                                label="Passwort"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {submitError && <Grid item style={{textAlign:'center',color:'#ff0000'}} xs={12}>{submitError}</Grid>}
                                                    <Grid item xs={12} sm={6}>
                                                        <Button className={classes.cancelButton} disableRipple color="secondary" type="button" onClick={this.handlePwResetClose}>
                                                            <CancelIcon/>Abbrechen
                                                        </Button>
                                                    </Grid>
                                                    <Grid item style={{textAlign:'right'}} xs={6} sm={6}>
                                                        <Button className={classes.submitButton} disableRipple color="primary" type="submit" disabled={submitting}>
                                                            Anmelden<FordwardIcon/>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    </Form>
                                )}
                            </MakeAsyncFunction>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                  open={!isOnline}
                  disableAutoFocus={true}
                  className={classes.modal}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                >
                  <Box className={classes.offlineWrap}>
                    <Box style={{textAlign:'center',marginBottom:'16px'}}>
                      <Box style={{margin:'0 auto',backgroundColor:'#eeeeee',width:50,height:50,borderRadius:'100%',padding:'6px',display:"flex"}}>
                        <WifiIcon style={{width:40,height:40,color:'#000000'}}/>
                      </Box>
                    </Box>
                    <Typography variant="h4" style={{textTransform:'uppercase',marginBottom:'6px'}}>Keine Internetverbindung</Typography>
                    <Typography>Bitte überprüfen Sie Ihre Internetverbindung.</Typography>
                  </Box>
                </Modal>
            </ThemeProvider>
        );
    }
}

App.propTypes = {
    classes:PropTypes.object.isRequired,
    isAuthenticated:PropTypes.bool.isRequired,
    is_admin:PropTypes.bool.isRequired,
    is_hv:PropTypes.bool.isRequired,
    showRelogin:PropTypes.bool.isRequired,
    is_deactivated:PropTypes.bool.isRequired,
    kundenportalEmailSuccess: PropTypes.bool.isRequired,
    kundenportalIsAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const { isAuthenticated, showRelogin, kundenportalEmailSuccess, kundenportalIsAuthenticated } = state.app;
    return {
        isAuthenticated:isAuthenticated,
        kundenportalEmailSuccess: kundenportalEmailSuccess,
        kundenportalIsAuthenticated: kundenportalIsAuthenticated,
        is_deactivated:((!!state.app && !!state.app.auth && !!state.app.auth.is_deactivated )),
        showRelogin:showRelogin,
        is_admin:((!!state.app && !!state.app.auth && !!state.app.auth.is_admin )),
        is_hv:((!!state.app && !!state.app.auth && !!state.app.auth.is_hv )),
        isOnline:state.app.isOnline,
    }
}





export default connect(mapStateToProps)(withStyles(styles)(App));
