import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BadgeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 29" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g  transform="translate(-1347.000000, -31.000000)" stroke="#3F3F3F" strokeWidth="1.5">
                    <g  transform="translate(1347.000000, 31.000000)">
                        <path d="M21.2618,20.8431 C19.6478,18.7761 18.8208,16.2771 18.8208,13.7331 L18.8208,10.9601 C18.8208,6.2521 16.7108,2.3861 11.7008,2.2371 L11.7008,2.2301 L11.6898,2.2301 C11.6408,2.2301 11.5938,2.2321 11.5448,2.2331 C11.4958,2.2321 11.4488,2.2301 11.4008,2.2301 L11.3888,2.2301 L11.3888,2.2371 C6.3788,2.3861 4.2708,6.2521 4.2708,10.9601 L4.2708,13.7331 C4.2708,16.2771 3.4438,18.7761 1.8278,20.8431 C1.4398,21.3401 1.0628,21.7351 0.7498,21.9031 L0.7498,25.3941 L11.3888,25.3941 L11.7008,25.3941 L22.3398,25.3941 L22.3398,21.9031 C22.0278,21.7351 21.6518,21.3401 21.2618,20.8431 Z" ></path>
                        <path d="M11.3887,2.2366 L11.3887,2.2296 L11.4007,2.2296 C11.4497,2.2296 11.4957,2.2316 11.5447,2.2326 C11.5937,2.2316 11.6407,2.2296 11.6897,2.2296 L11.7007,2.2296 L11.7007,2.2366 C12.1997,2.2516 12.6597,2.3126 13.1017,2.3976 C13.1027,2.3696 13.1097,2.3426 13.1097,2.3146 C13.1097,1.4506 12.4097,0.7506 11.5447,0.7506 C10.6807,0.7506 9.9807,1.4506 9.9807,2.3146 C9.9807,2.3426 9.9877,2.3696 9.9897,2.3976 C10.4297,2.3126 10.8907,2.2516 11.3887,2.2366 Z" ></path>
                        <path d="M11.7012,25.3938 L11.3892,25.3938 L9.2902,25.3938 C9.3092,26.6238 10.3092,27.6168 11.5452,27.6168 C12.7802,27.6168 13.7802,26.6238 13.8012,25.3938 L11.7012,25.3938 Z" ></path>
                        <path d="M19.8174,21.6819 L21.8744,21.6819" ></path>
                        <path d="M16.253,21.6819 L17.303,21.6819" ></path>
                        <path d="M1.2159,21.6819 L13.7379,21.6819" ></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}