export const getInitialsFromString = (str) => {
    const exploded = String(str).trim().split(' ').map(string => string.trim());

    if(exploded.length >= 2){
        return String(exploded[0]).substr(0, 1).toUpperCase() + "" + String(exploded[1]).substr(0, 1).toUpperCase()
    }else{
        return String(exploded[0]).substr(0, 2).toUpperCase();
    }

};

export const getMehrzahlFromFahrzeugart = (val) => {
    switch(String(val).toLowerCase()){

        case "motorrad":
            return "Motorräder";

        case "reisemobil":
            return "Reisemobile";

        default:
            return val;
    }
};