import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {withStyles} from '@material-ui/core/styles'
import {Card,CardContent,CardActionArea} from '@material-ui/core';

const styles = theme => ({
    cardContent:{
        padding:'30px 30px 40px 30px'
    },
    icon:{
        backgroundColor:'#00468E',
        borderRadius:'100%',
        minHeight:`${theme.typography.round(86 /11)}em`,
        minWidth:`${theme.typography.round(86 /11)}em`,
        height:`${theme.typography.round(86 /11)}em`,
        width:`${theme.typography.round(86 /11)}em`,
        color:'#ffffff',
        margin:'0 auto',
        marginBottom:`${theme.typography.round(20 /11)}em`,
        '& svg':{
            width:'63px',
            height:'auto'
        }
    },
});


class InputCard extends React.PureComponent {


    handleClickItem(onChange){
        onChange(this.props.input.value);

        let event;
        if(typeof(Event) === 'function') {
            event = new Event('submit', { cancelable: true });
        }else{
            event = document.createEvent('Event');
            event.initEvent('submit', true, true);
        }

        document
            .getElementById('garantieForm')
            .dispatchEvent(event)
    }

    render() {

        const {classes,title,icon,input} = this.props;
        const {onChange} = input;

        return (
            <Card onClick={(e) => this.handleClickItem(onChange)}>
                <CardActionArea >
                    <CardContent className={classes.cardContent}>
                        <Box textAlign="center">
                            {!!icon && <Box display="flex" justifyContent="center" alignItems="center" className={classes.icon} >
                                {icon}
                            </Box>}
                            <Typography color="primary" style={{textOverflow:'ellipsis',overflow:'hidden'}} variant="subtitle1">{title}</Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
}

InputCard.propTypes = {
    classes:PropTypes.object.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    input:PropTypes.object.isRequired
};

export default withStyles(styles)(InputCard);

