import { CancelToken } from 'axios'
import { setup } from 'axios-cache-adapter'
import { CANCEL} from 'redux-saga'
import parseISO from 'date-fns/parseISO'


class Api {

    login(username,password){
        const source = CancelToken.source();
        const request =  this.axiosInstance.post(
            '/auth/login',
            {
                login:username,
                password:password
            }
            ,{ cancelToken: source.token });

        request[CANCEL] = () => source.cancel();
        return request;
    }
    kundenportalSendEmail(garnr, email) {
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            '/customer/auth/request',
            {
                garnr: garnr,
                email: email
            }
            , { cancelToken: source.token });

        request[CANCEL] = () => source.cancel();
        return request;
    }
    kundenportalLogin(login, otp) {
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            '/customer/auth/login',
            {
                login: login,
                otp: otp
            }
            , { cancelToken: source.token });

        request[CANCEL] = () => source.cancel();
        return request;
    }
    getKundenportalGarantie(token){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            '/customer/garantie',
            {
                cancelToken: source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    refresh(token){
        const source = CancelToken.source();
        const request =  this.axiosInstance.post(
            '/auth/refresh',
            {}
            ,{
                cancelToken: source.token,
                headers: { Authorization: `Bearer ${token}` }
            });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    passwordRefresh(username){
        const source = CancelToken.source();
        const request =  this.axiosInstance.post(
            '/auth/password/reset',
            {
                login:username
            }
            ,{
                cancelToken: source.token,
            });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    passwordReset(token,username,password,password_confirmation){
        const source = CancelToken.source();
        const request =  this.axiosInstance.post(
            '/auth/password/change',
            {
                token:token,
                login:username,
                password:password,
                password_confirmation:password_confirmation
            }
            ,{
                cancelToken: source.token,
            });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    logout(token){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            '/auth/logout',
            {},
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    info(token){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            '/auth/me',
            {

            },
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    updateUser(token,userData){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            '/user/edit',
            userData,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    updateUserApprove(token,userData){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            '/user/edit',
            Object.assign({},userData,{account_approved:true}),
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    updatePassword(token,passwordData){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            '/user/password',
            passwordData,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getGarantien(token){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            '/garantie',
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getPdfFromPath(token,path){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            path,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'arraybuffer',
                reponseEncoding: 'binary'
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getTarifeFromVehicleType(token,vehicleType){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/garantie/${vehicleType}`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getUserHv(token,postcode){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/hv/user/${postcode}`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getHvAuswertung(token){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/hv/auswertung`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    createGarantie(token,garantieType,isPlusTarif){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            `/garantie`,
            {
                garantieType:garantieType,
                plusTariff:isPlusTarif
            },
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getCondition(token,garantieId){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/garantie/${garantieId}/condition`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    setCondition(token,garantieId,conditionId,policyAccepted){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            `/garantie/${garantieId}/condition`,
            {
                condition:conditionId,
                policyAccepted:policyAccepted
            },
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    setInspektionsnachweise(token,garantieId,inspektionsnachweise){
        const source = CancelToken.source();
        let formData = new FormData();

        if(!!inspektionsnachweise.proofOfInspectionFilePath){
            formData.append('proofOfInspectionFilePath', inspektionsnachweise.proofOfInspectionFilePath);
        }

        if(!!inspektionsnachweise.additionalDocumentFilePath1){
            formData.append('additionalDocumentFilePath1', inspektionsnachweise.additionalDocumentFilePath1);
        }

        if(!!inspektionsnachweise.additionalDocumentFilePath2){
            formData.append('additionalDocumentFilePath2', inspektionsnachweise.additionalDocumentFilePath2);
        }

        if(!!inspektionsnachweise.additionalDocumentFilePath3){
            formData.append('additionalDocumentFilePath3', inspektionsnachweise.additionalDocumentFilePath3);
        }

        formData.append('inspectionProof', inspektionsnachweise.inspectionProof)

        const request = this.axiosInstance.post(
            `/garantie/${garantieId}/inspections`,
            formData,
            {
                cancelToken:source.token,
                headers: {
                    Authorization: `Bearer ${token}`,
                    //"Content-Type": "multipart/form-data",
                }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    setCustomer(token,garantieId,customerData){
        const source = CancelToken.source();
        const birthday = ((!!customerData.birthday && customerData.birthday instanceof Date) ? customerData.birthday : (!!customerData.birthday) ? parseISO(customerData.birthday) : null)

        const request = this.axiosInstance.post(
            `/garantie/${garantieId}/customer`,
                Object.assign({},customerData,{birthday:((!!birthday && birthday instanceof Date) ? birthday.getFullYear()+"-"+String(birthday.getMonth()+1).padStart(2,"0")+"-"+String(birthday.getDate()).padStart(2,"0") : null)}),
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getCustomerValidationRules(token){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/garantie/rules/customer`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    submit(token,garantieId,remarks){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            `/garantie/${garantieId}/submit`,
            {
                remarks:remarks
            },
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    setVehicle(token,garantieId,vehicleData){
        const source = CancelToken.source();
        const erstzulassung = ((!!vehicleData.erstzulassung && vehicleData.erstzulassung instanceof Date) ? vehicleData.erstzulassung : (!!vehicleData.erstzulassung) ? parseISO(vehicleData.erstzulassung) : null)
        const verkaufsdatum = ((!!vehicleData.verkaufsdatum && vehicleData.verkaufsdatum instanceof Date) ? vehicleData.verkaufsdatum : (!!vehicleData.verkaufsdatum) ? parseISO(vehicleData.verkaufsdatum) : null)

        const request = this.axiosInstance.post(
            `/garantie/${garantieId}/vehicle`,
            Object.assign({},vehicleData,{
                erstzulassung:((!!erstzulassung && erstzulassung instanceof Date) ? erstzulassung.getFullYear()+"-"+String(erstzulassung.getMonth()+1).padStart(2,"0")+"-"+String(erstzulassung.getDate()).padStart(2,"0") : null),
                verkaufsdatum:((!!verkaufsdatum && verkaufsdatum instanceof Date) ? verkaufsdatum.getFullYear()+"-"+String(verkaufsdatum.getMonth()+1).padStart(2,"0")+"-"+String(verkaufsdatum.getDate()).padStart(2,"0") : null),
                allrad:((!vehicleData.hasOwnProperty('allrad')) ? false : vehicleData.allrad)
            }),
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    setGarantie(token,garantieId,garantieData){
        const source = CancelToken.source();
        const request = this.axiosInstance.post(
            `/garantie/${garantieId}/garantie`,
            Object.assign({},garantieData,{
                mobilitaetsgarantie:((!garantieData.hasOwnProperty('mobilitaetsgarantie')) ? false : garantieData.mobilitaetsgarantie),
                alternativantrieb:((!garantieData.hasOwnProperty('alternativantrieb')) ? false : garantieData.alternativantrieb),
                wiederzulassung:((!!garantieData.wiederzulassung && garantieData.wiederzulassung instanceof Date) ? garantieData.wiederzulassung.getFullYear()+"-"+String(garantieData.wiederzulassung.getMonth()+1).padStart(2,"0")+"-"+String(garantieData.wiederzulassung.getDate()).padStart(2,"0") : null),
                endWerksgarantieDate:((!!garantieData.endWerksgarantieDate && garantieData.endWerksgarantieDate instanceof Date) ? garantieData.endWerksgarantieDate.getFullYear()+"-"+String(garantieData.endWerksgarantieDate.getMonth()+1).padStart(2,"0")+"-"+String(garantieData.endWerksgarantieDate.getDate()).padStart(2,"0") : null),
            }),
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getSummary(token,garantieId){
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/garantie/${garantieId}/summary`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getPreislistenUndGarantiebedingungen(token){
        const source = CancelToken.source();
        const request =  this.axiosInstance.get(
            `/user/pricelistsAndGarantiebedingungen`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            });

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getVehicleValidationRules(token,type,garantietyp){

        const vehicleCondition = ((parseInt(garantietyp,10) === 1) ? 'ANSCHLUSS_GARANTIE' : 'GEBRAUCHTWAGEN_GARANTIE');
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/garantie/rules/${type}/${vehicleCondition}/vehicle`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getDefaultVehicleValidationRules(token,type){

        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/garantie/rules/${type}/vehicle`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }


    getGarantieValidationRules(token,type,garantietyp){

        const vehicleCondition = ((parseInt(garantietyp,10) === 1) ? 'ANSCHLUSS_GARANTIE' : 'GEBRAUCHTWAGEN_GARANTIE');
        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/garantie/rules/${type}/${vehicleCondition}/garantie`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    getGarantieValidationRulesWithoutCondition(token,type){

        const source = CancelToken.source();
        const request = this.axiosInstance.get(
            `/garantie/rules/${type}/garantie`,
            {
                cancelToken:source.token,
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        request[CANCEL] = () => source.cancel();
        return request;
    }

    constructor(options) {
        this.axiosInstance = setup({
            baseURL: options.baseURL,
            timeout: options.timeout,
            cache: {
                maxAge: 15 * 60 * 1000,
                debug: false,
                exclude: {
                    query: false
                }
            }
        });

    }

}


let instance;
const getApiInstance = (options) => {
    if (!instance) {
        instance = new Api(options);
    } else if (options) {
        throw Error('Instance is already instantiate with `options`')
    }
    return instance;
};


export default getApiInstance;
