import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Mail(props) {
    return (
        <SvgIcon viewBox="0 0 19 13" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-506.000000, -855.000000)" stroke="#00468E" strokeWidth="1.5">
                    <g transform="translate(507.000000, 856.000000)">
                        <path d="M17,2.04025298 L17,9.57261905 C17,9.99003185 16.851378,10.3473571 16.5541295,10.6446057 C16.256881,10.9418542 15.8995557,11.0904762 15.4821429,11.0904762 L1.51785714,11.0904762 C1.10044434,11.0904762 0.743119046,10.9418542 0.445870536,10.6446057 C0.148622026,10.3473571 0,9.99003185 0,9.57261905 L0,2.04025298" ></path>
                        <path d="M17,1.51785714 C17,2.01748762 16.8450536,2.4949754 16.5351562,2.95033482 C16.2252589,3.40569424 15.839474,3.79464124 15.3777902,4.1171875 C12.9998021,5.7678654 11.5199062,6.79557089 10.938058,7.20033482 C10.8748137,7.24460588 10.7404214,7.34105208 10.5348772,7.48967634 C10.329333,7.63830059 10.1585758,7.75846308 10.0226004,7.85016741 C9.88662506,7.94187174 9.72219218,8.04464229 9.52929688,8.15848214 C9.33640157,8.272322 9.15457675,8.35770061 8.98381696,8.41462054 C8.81305718,8.47154046 8.65494864,8.5 8.50948661,8.5 L8.49051339,8.5 C8.34505136,8.5 8.18694282,8.47154046 8.01618304,8.41462054 C7.84542325,8.35770061 7.66359843,8.272322 7.47070312,8.15848214 C7.27780782,8.04464229 7.11337494,7.94187174 6.97739955,7.85016741 C6.84142417,7.75846308 6.67066695,7.63830059 6.46512277,7.48967634 C6.25957859,7.34105208 6.12518633,7.24460588 6.06194196,7.20033482 C5.48641825,6.79557089 4.65792951,6.21847473 3.57645089,5.46902902 C2.49497227,4.71958331 1.84672727,4.26897397 1.63169643,4.1171875 C1.23958137,3.85156117 0.869607394,3.48633045 0.521763393,3.02148437 C0.173919392,2.5566383 0,2.12500199 0,1.7265625 C0,1.23325646 0.131230086,0.822174263 0.393694196,0.493303571 C0.656158306,0.164432879 1.03087554,-1.77635684e-15 1.51785714,-1.77635684e-15 L15.4821429,-1.77635684e-15 C15.8932312,-1.77635684e-15 16.2489754,0.148622026 16.5493862,0.445870536 C16.8497969,0.743119046 17,1.10044434 17,1.51785714 Z" ></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};