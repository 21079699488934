import React from 'react';
import PropTypes from 'prop-types';
import {Typography,Box,Grid,CardContent,Card,Button,MenuItem,FormControl,Hidden} from '@material-ui/core';
import { Form,Field } from 'react-final-form'
import { connect } from 'react-redux';
import {Select} from 'final-form-material-ui';
import TextField from '../../../Helpers/DefaultTextField';
import Checkbox from '../../../Helpers/MaterialCheckbox';
import * as EmailValidator from 'email-validator';
import FordwardIcon from '../../../Assets/Svg/Forward';
import BackwardIcon from '../../../Assets/Svg/Backward';
import MakeAsyncFunction from 'react-redux-promise-listener'
import createDecorator from 'final-form-focus';
import {actions,backToContactForm} from '../../../Reducers/App';
import { promiseListener } from '../../../ConfigureStore';
import {withStyles} from '@material-ui/core/styles';
import { motion } from "framer-motion"

const required = value => (value ? undefined : 'Bitte angeben');
const requiredSelection = value => (value ? undefined : 'Bitte auswählen');
const requiredEmail = value => (value ? ((EmailValidator.validate(value) === true ) ? undefined : "ungültige E-Mail-Adresse") : "Bitte angeben");
const focusOnError = createDecorator();


const styles = theme => ({

});

class KontaktAdd extends React.Component {

    constructor(props) {
        super(props);
        this.handleBackToForm = this.handleBackToForm.bind(this);
    }

    validate = values => {
        return this.props.validate ? this.props.validate(values) : {}
    };

    handleBackToForm(){
        this.props.backToContactForm();
    }

    render() {

        const {values,contactFormSubmited} = this.props;

        const label = (<span>Ich habe die <a target="_blank" rel="noopener noreferrer" title="Datenschutzerklärung" href="https://www.progarant.de/datenschutz">Datenschutzerklärung</a> zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an <a rel="noopener noreferrer" href="mailto:service@progarant.de">service@progarant.de</a> widerrufen. Weitere Informationen finden Sie in unserer <a target="_blank" rel="noopener noreferrer" title="Datenschutzerklärung" href="https://www.progarant.de/datenschutz">Datenschutzerklärung</a></span>);

        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <Typography color="primary" style={{marginTop:20}} variant="h2">Kontakt</Typography>
                    </Box>
                </Grid>
                {!!contactFormSubmited && <Grid item xs={12}>
                    <Box style={{marginBottom:'67px'}}>
                        <Card>
                            <CardContent style={{padding:'26px 54px 42px 54px'}}>
                                <Typography color="primary" variant="h5">Vielen Dank,</Typography>
                                <Typography style={{marginTop:20}}  variant="body1">
                                    Ihre Anfrage wurde erfolgreich versandt. Sollten Sie weitere Hilfe benötigen, wenden Sie sich an den für Sie zuständigen Außendienstmitarbeiter oder unser Büro in Castrop-Rauxel - Tel: 02305-9779-7.</Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box>
                        <Grid container justifyContent="flex-start" spacing={5}>
                            <Grid item>
                                <Button disableRipple onClick={(e) => this.handleBackToForm()} color="primary"  >
                                    <BackwardIcon/>zurück zum Formular
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>}
                {!contactFormSubmited && <Grid item xs={12}>
                    <MakeAsyncFunction
                        listener={promiseListener}
                        start={actions.CONTACT_SUBMIT}
                        resolve={actions.CONTACT_SUBMIT_RESOLVE}
                        reject={actions.CONTACT_SUBMIT_REJECT}
                    >
                    {onSubmit => (<Form
                        initialValues={values}
                        validate={this.validate}
                        onSubmit={onSubmit}
                        decorators={[focusOnError]}
                    >
                        {({handleSubmit,submitError, submitting,form, values,pristine,validating}) => (
                            <form id="kontaktForm" onSubmit={handleSubmit}>
                                <Box style={{marginBottom:'67px'}}>
                                    <motion.div
                                        initial={{ opacity: 0,scale:.9,y:10 }}
                                        animate={{ opacity: 1,scale:1,y:0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{delay:0}}
                                    >
                                    <Card>
                                        <CardContent style={{padding:'26px 54px 42px 54px'}}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                    <FormControl fullWidth>
                                                        <Field
                                                            fullWidth
                                                            required
                                                            name="salutation"
                                                            component={Select}
                                                            validate={requiredSelection}
                                                            label="Anrede"
                                                            formControlProps={{ fullWidth: true }}
                                                        >
                                                            <MenuItem value="1">Herr</MenuItem>
                                                            <MenuItem value="2">Frau</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                        &nbsp;
                                                    </Grid>
                                                </Hidden>
                                                <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                    <FormControl fullWidth>
                                                        <Field
                                                            fullWidth
                                                            required
                                                            validate={required}
                                                            name="lastName"
                                                            component={TextField}
                                                            type="text"
                                                            label="Name"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                    <FormControl fullWidth>
                                                        <Field
                                                            fullWidth
                                                            validate={required}
                                                            required
                                                            name="firstName"
                                                            component={TextField}
                                                            type="text"
                                                            label="Vorname"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                    <FormControl fullWidth>
                                                        <Field
                                                            fullWidth
                                                            name="phone"
                                                            component={TextField}
                                                            type="text"
                                                            label="Telefon"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                                    <FormControl fullWidth>
                                                        <Field
                                                            fullWidth
                                                            name="fax"
                                                            component={TextField}
                                                            type="text"
                                                            label="Fax"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} style={{paddingBottom:0}}>
                                                    <FormControl fullWidth>
                                                        <Field
                                                            fullWidth
                                                            name="email"
                                                            required
                                                            validate={requiredEmail}
                                                            component={TextField}
                                                            type="text"
                                                            label="E-Mail"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} style={{paddingBottom:0}}>
                                                    <FormControl fullWidth>
                                                        <Field
                                                            fullWidth
                                                            name="message"
                                                            validate={required}
                                                            component={TextField}
                                                            multiline
                                                            rows={3}
                                                            rowsMax={10}
                                                            type="text"
                                                            label="Ihre Nachricht"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} style={{paddingBottom:0}}>
                                                    <Field
                                                        name="acceptedDataPrivacyPolicy"
                                                        component={Checkbox}
                                                        type="checkbox"
                                                        label={label}
                                                    />
                                                </Grid>
                                                <Grid style={{paddingTop:'75px'}} item xs={4}>
                                                    <Typography variant="body2">* Pflichtfeld</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    </motion.div>
                                </Box>
                                <Box>
                                    <Grid container justifyContent="flex-end" spacing={5}>
                                        <Grid item>
                                            <Button disableRipple color="primary" type="submit" disabled={submitting}>
                                                Abschicken<FordwardIcon/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Form>)}
                    </MakeAsyncFunction>
                </Grid>}
            </Grid>
        );
    }

}

KontaktAdd.propTypes = {
    classes:PropTypes.object.isRequired,
    values:PropTypes.object.isRequired,
    contactFormSubmited:PropTypes.bool.isRequired,
    backToContactForm:PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const {user,contactFormSubmited} = state.app;
    return {
        contactFormSubmited:contactFormSubmited,
        values:{
            email:user.email,
            phone:user.phone
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        backToContactForm:() => dispatch(backToContactForm())
    }
};


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(KontaktAdd));
