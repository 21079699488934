import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MagnifierIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g  transform="translate(-1305.000000, -33.000000)" stroke="currentColor" strokeWidth="1.5">
                    <g  transform="translate(1306.000000, 34.000000)">
                        <circle  cx="9.5" cy="9" r="9"></circle>
                        <path d="M15.5,16 L21.5,22"  strokeLinecap="round" strokeLinejoin="bevel"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}