import { put,takeLatest,call,select,takeLeading,fork,take,cancel,cancelled,delay} from 'redux-saga/effects'
import {actions} from '../Reducers/App';
import getApiInstance from '../Api/Api';
import getNewsApiInstance from '../Api/NewsApi';
import { FORM_ERROR } from 'final-form';
import { push } from 'connected-react-router';
import {Paths} from '../Constants/Paths';
import UrlSafeString from 'url-safe-string';
import FileSaver from 'file-saver';
import {LOCATION_CHANGE} from 'connected-react-router';
import 'url-search-params-polyfill';
import KEYUTIL from 'jsrsasign';
import jws from 'jsrsasign';
import b64utoutf8 from 'jsrsasign';
import {PublicCert} from '../Constants/PublicCert';
import {completeWizard} from '../Reducers/Form';
import LogRocket from 'logrocket';

const tagGenerator  = new UrlSafeString();
const loginExpiresGraceSeconds = 300;
const UNAUTHORIZED = 401;

const getToken = (state) => state.app.auth.api_token;
const getkundenportalJwToken = (state) => state.app.kundenportalJwToken;
const getPage = (state) => state.form.page;
const getApprovePage = (state) => state.form.approvepage;
const getVehicleType = (state) => state.app.garantie.fahrzeugart;
const getGarantieart = (state) => state.app.garantie.garantieart;
const getKondition = (state) => state.app.garantie.garantietyp;
const getTarife = (state) => state.app.garantie.tarife;
const getTmpId = (state) => ((state.app.garantie.garantie.hasOwnProperty(state.app.garantie.garantieart)) ? state.app.garantie.garantie[state.app.garantie.garantieart].tmp_id : null);
const getLoginExpires = (state) => state.app.auth.expires_in;
const getUserData = (state) => state.app.user;
const getKonditionAccept = (state) => state.app.garantie.tarifTypAccept;
const getCustomer = (state) => state.app.garantie.customer;
const getVehicle = (state) => state.app.garantie.vehicle;
const getInspektionsnachweise = (state) => state.app.garantie.inspektionsnachweise;
const getGarantie = (state) => Object.assign({},state.app.garantie.garantiedaten,{plusTariff:state.app.garantie.isPlusTariff});
const getRemarks = (state) => state.app.garantie.remarks;
const getConditions = (state) => state.app.garantie.conditions;
const getUserPostcode = (state) => state.app.user.postcode;
const getInspektionscheckliste = (state) => state.app.garantie.inspektionscheckliste;
const getUebergabeprotokoll = (state) => state.app.garantie.uebergabeprotokoll;
const getIsPlusTariff  = (state) => state.app.garantie.isPlusTariff;
const getIsMigrated = (state) => state.app.auth.is_migrated;

function* loadNews(){
    try {

        let news = [];

        /* Get News */
        const newsResponse = yield call([getNewsApiInstance(), getNewsApiInstance().findNews]);

        if(
            !!newsResponse &&
            !!newsResponse.data &&
            !!newsResponse.status &&
            newsResponse.status === 200 &&
            !!newsResponse.data.length

        ){
            news = newsResponse.data;
        }

        yield put({type: actions.NEWS_LOAD_SUCCESS,payload:{
            news:news
        }});

    } catch (e) {

        yield put({type: actions.NEWS_LOAD_ERROR});
    }

}

function* loadSingleNews(action){
    try {

        let news = {};

        /* Get News */
        const newsResponse = yield call([getNewsApiInstance(), getNewsApiInstance().getNews],action.payload.uid);

        if(
            !!newsResponse &&
            !!newsResponse.data &&
            !!newsResponse.status &&
            newsResponse.status === 200
        ){
            news = newsResponse.data;
        }

        yield put({type: actions.NEWS_SINGLE_LOAD_SUCCESS,payload:news});

    } catch (e) {
        yield put({type: actions.NEWS_SINGLE_LOAD_ERROR});
    }
}

function* initApp(){

    yield put({type: actions.INIT_SUCCEEDED});
}

function* loginRefresh(){
    try {
        while (true) {

            const loginExpires = yield select(getLoginExpires);
            const refreshDelay = Math.max(1800*1000,Math.min(3600*1000,(loginExpires-loginExpiresGraceSeconds)*1000));

            yield delay(refreshDelay);

            /* Get User Token */
            const token = yield select(getToken);

            /* Do Refresh */
            const refreshResponse = yield call([getApiInstance(), getApiInstance().refresh],token);

            if(
                !!refreshResponse &&
                !!refreshResponse.status &&
                refreshResponse.status === 200
            ){
                yield put({type: actions.LOGIN_REFRESH,payload:refreshResponse});
            }

        }

    } finally {
        if (yield cancelled())
            yield put({type:actions.LOGIN_REFRESH_STOPED});
    }
}

function* submitContact(action){
    try {

        /* Send Contact */
        const contactResponse = yield call([getNewsApiInstance(), getNewsApiInstance().submitContact], action.payload);

        if(
            !!contactResponse &&
            !!contactResponse.status &&
            contactResponse.status === 200
        ){
            yield put({type: actions.CONTACT_SUBMIT_RESOLVE,payload:{status:'success'}});
        }else{
            yield put({type: actions.CONTACT_SUBMIT_RESOLVE, payload:{[FORM_ERROR]: 'Kontaktformular konnten nicht versandt werden'}});
        }


    }catch (e){

        if(
            !!e.response &&
            !!e.response.status &&
            e.response.status === 422
        ) {
            yield put({
                type: actions.CONTACT_SUBMIT_RESOLVE,
                payload: Object.assign({}, {[FORM_ERROR]: 'Bitte füllen Sie das Formular vollständig aus'}, e.response.data)
            });
        }else{
            yield put({type:actions.CONTACT_SUBMIT_RESOLVE, payload:{[FORM_ERROR]: 'Kontaktformular konnten nicht versandt werden'}});
        }

    }
}

function* passwordRefresh(action){

    try {

        /* Refresh PW */
        const pwRefresh = yield call([getApiInstance(), getApiInstance().passwordRefresh], action.payload.username);

        if(
            !!pwRefresh &&
            !!pwRefresh.data &&
            !!pwRefresh.data.status &&
            pwRefresh.data.status === 'success'
        ){
            yield put({type: actions.PW_REFRESH_SUCCESS, payload:{status:'success',username:action.payload.username}});

            if(action.type !== actions.MODAL_USER_PWRESET_CONFIRM){
                yield put(push(Paths.FORGET_SUCCESS,{username:action.payload.username}));
            }

        }else{
            yield put({type: actions.PW_REFRESH_SUCCESS, payload:{[FORM_ERROR]: 'Passwort zurücksetzen fehlgeschlagen'}});
        }

    } catch (e) {
        if(
            !!e.response &&
            !!e.response.status &&
            e.response.status === 404
        ){
            yield put({type: actions.PW_REFRESH_SUCCESS, payload:{[FORM_ERROR]: 'Benutzername nicht gefunden'}});
        }else{
            yield put({type: actions.PW_REFRESH_SUCCESS, payload:{[FORM_ERROR]: 'Passwort zurücksetzen fehlgeschlagen'}});
        }

    }
}

function* passwordReset(action){
    try {

        /* Reset PW */
        const pwReset = yield call([getApiInstance(), getApiInstance().passwordReset],action.payload.token,action.payload.login,action.payload.password,action.payload.password_confirmation);

        if(
            !!pwReset &&
            !!pwReset.data &&
            !!pwReset.data.status &&
            pwReset.data.status === 'success'
        ){
            yield put({type: actions.PW_RESET_RESOLVE, payload:{status:'success',username:action.payload.username}});
            yield put(push(Paths.RESET_SUCCESS));

        }else{
            yield put({type: actions.PW_RESET_RESOLVE, payload:{[FORM_ERROR]: 'Passwort konnte nicht zurückgesetzt werden.'}});
        }

    } catch (e) {

        if(e.response.status === 422){
            yield put({type: actions.PW_RESET_RESOLVE, payload:Object.assign({},{[FORM_ERROR]: 'Passwort konnte nicht zurückgesetzt werden.'},e.response.data)});
        }else{
            yield put({type: actions.PW_RESET_RESOLVE, payload:{[FORM_ERROR]: 'Passwort konnte nicht zurückgesetzt werden. ('+e+')'}});
        }

    }
}

function* loadUserHv(){

    try {

        yield put({type: actions.LOAD_USER_HV});

        /* Get User Token */
        const token = yield select(getToken);

        /* Get PLZ */
        const plz = yield select(getUserPostcode);


        /* Refresh PW */
        const userHv = yield call([getApiInstance(), getApiInstance().getUserHv],token,plz);

        if(
            !!plz &&
            !!userHv &&
            !!userHv.data &&
            !!userHv.data.status &&
            userHv.data.status === 'success' &&
            !!userHv.data.hv_users &&
            (
                (Array.isArray(userHv.data.hv_users) && userHv.data.hv_users.length ) ||
                typeof userHv.data.hv_users === 'object'
            )
        ){
            yield put({type: actions.LOAD_USER_HV_SUCCESS, payload:((Array.isArray(userHv.data.hv_users)) ? userHv.data.hv_users[0] : userHv.data.hv_users)});

        }else{
            yield put({type: actions.LOAD_USER_HV_ERROR, payload:{[FORM_ERROR]: 'Ansprechpartner konnte nicht geladen werden'}});
        }

    } catch(e){
        yield put({type: actions.LOAD_USER_HV_ERROR, payload:{[FORM_ERROR]: 'Ansprechpartner konnte nicht geladen werden ('+e+')'}});
    }
}

function* loadUserInfo(){

    try {

        yield put({type: actions.LOAD_USER_INFO});

        /* Get User Token */
        const token = yield select(getToken);

        /* User Info */
        const userInfo = yield call([getApiInstance(), getApiInstance().info],token);


        if(
            !!userInfo &&
            !!userInfo.data &&
            !!userInfo.data.status &&
            userInfo.data.status === 'success'
        ){


            yield call([LogRocket,LogRocket.identify],'123456',{
              name: 'John Smith',
              email: 'johnsmith@gmail.com',
            });

            yield put({type: actions.LOAD_USER_INFO_SUCCESS, payload:{userInfo:userInfo.data}});

        }else{
            yield put({type: actions.LOAD_USER_INFO_ERROR, payload:{[FORM_ERROR]: 'Benutzerinformationen konnte nicht geladen werden'}});
        }

    } catch(e){
        yield put({type: actions.LOAD_USER_INFO_ERROR, payload:{[FORM_ERROR]: 'Benutzerinformationen konnte nicht geladen werden ('+e+')'}});
    }

}

function* checkEmail(action){
    let garnr = action.payload.kundenportalGarantieId;
    let email = action.payload.kundenportalEmail;

    try{
        const checkEmail = yield call([getApiInstance(), getApiInstance().kundenportalSendEmail], garnr, email);

        if (!!checkEmail && !!checkEmail.data && checkEmail.data.status === 'success') {
            yield put({ type: actions.CHECK_EMAIL_SUCCESS, payload: { kundenportalLoginString: checkEmail.data.login} });
        }
        else {
            yield put({ type: actions.CHECK_EMAIL_ERROR, payload: {} });
        }   
    }
    catch(e){
        yield put({ type: actions.CHECK_EMAIL_ERROR, payload: {} });
    }
}

function* loginKundenportal(action) {
    let loginString = action.payload.kundenportalLoginString
    let otp = action.payload.kundenportalOtp
    try {
        const login = yield call([getApiInstance(), getApiInstance().kundenportalLogin], loginString, otp);

        if (!!login && !!login.data && login.data.status === 'success') {
            yield put({ type: actions.LOGIN_KUNDENPORTAL_SUCCESS, payload: { kundenportalJwToken: login.data.api_token } });
        }
        else{
            yield put({ type: actions.LOGIN_KUNDENPORTAL_ERROR, payload: {} });
        }
    }
    catch (e) {
        yield put({ type: actions.LOGIN_KUNDENPORTAL_ERROR, payload: {} });
    }
}
function* loadKundenportalGarantie(action){
    let token = action.payload.kundenportalJwToken

    try {
        const garantie = yield call([getApiInstance(), getApiInstance().getKundenportalGarantie], token);

        if (!!garantie && !!garantie.data) {
            yield put({ type: actions.LOAD_KUNDENPORTAL_GARANTIE_SUCCESS, payload: { kundenportalGarantie: garantie.data } });
        }
        else {
            yield put({ type: actions.LOAD_KUNDENPORTAL_GARANTIE_ERROR, payload: {} });
        }
    }
    catch (e) {
        yield put({ type: actions.LOAD_KUNDENPORTAL_GARANTIE_ERROR, payload: {} });
    }
}
function* loadKundenportalGarantiePdf(action) {
    try {
        const token = yield select(getkundenportalJwToken);

        /* Load PDF */
        const pdfResponse = yield call([getApiInstance(), getApiInstance().getPdfFromPath], token, action.payload.path);

        if (
            !!pdfResponse &&
            !!pdfResponse.data &&
            !!pdfResponse.status &&
            pdfResponse.status === 200
        ) {
            const blob = new Blob([pdfResponse.data], { type: "application/pdf" });
            FileSaver.saveAs(blob, action.payload.filename);

            yield put({ type: actions.LOAD_KUNDENPORTAL_GARANTIE_PDF_SUCCESS, payload: {} });
        } else {
            yield put({ type: actions.LOAD_KUNDENPORTAL_GARANTIE_PDF_ERROR, payload: {} });
        }



    } catch (e) {
        yield put({ type: actions.LOAD_PDF_FROM_PATH_ERROR, payload: { [FORM_ERROR]: 'Garantie PDF konnten nicht geladen werden (' + e + ')' } });
    }
}

function* login(action){

    try {

        let username = action.payload.username;
        let password = action.payload.password;

        if(
            !!action.payload &&
            !!action.payload.jwToken
        ){

             const pubkey = KEYUTIL.getKey(PublicCert);
             const isValid = jws.JWS.verifyJWT(action.payload.jwToken,pubkey, {alg: ['RS256']});
             if(isValid === true){
                const payloadObj = jws.JWS.readSafeJSONString(b64utoutf8(action.payload.jwToken.split(".")[1]));
                 if(
                    !!payloadObj &&
                    !!payloadObj.username &&
                    !!payloadObj.password
                 ){

                     username = payloadObj.username;
                     password = payloadObj.password;

                 }
             }
        }

        /* Login User */
        const login = yield call([getApiInstance(), getApiInstance().login],username,password);

        if(
            !!login &&
            !!login.data &&
            login.data.status === 'success'
        ){

            yield put({type: actions.LOGIN_SUCCESS,payload:{data:login.data}});

            /* Load HV */
            yield call(loadUserInfo);

            /* Start Refresh Task */
            yield put({type:actions.LOGIN_REFRESH_STARTED});

            const loginRefreshTask = yield fork(loginRefresh);

            /* Cancel Task on LOGOUT Action */
            yield take(actions.LOGOUT);

            yield cancel(loginRefreshTask)

        }else{
            yield put({type: actions.LOGIN_SUCCESS,payload:{[FORM_ERROR]: 'Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihren Benutzername oder Ihr Passwort.'}});
        }

    } catch (e) {
        yield put({type: actions.LOGIN_SUCCESS, payload:{[FORM_ERROR]: 'Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihren Benutzername oder Ihr Passwort.'}});
    }
}

function* logout(action){
    try {

        /* Get User Token */
        const token = yield select(getToken);

        /* Logout User */
        yield fork([getApiInstance(), getApiInstance().logout],token);

        yield put({type: actions.LOGOUT_SUCCESS});

    } catch (e) {
        yield put({type: actions.LOGOUT_SUCCESS, payload:{[FORM_ERROR]: 'Abmeldung fehlgeschlagen'}});
    }
}



function* updateUser(action){
    try {

        /* Get User Token */
        const token = yield select(getToken);

        /* Get User Data */
        const userData = yield select(getUserData);

        /* Merged User Data */
        const updateUserData = Object.assign({},userData,action.payload);

        /* Update User */
        const response = yield call([getApiInstance(), getApiInstance().updateUser],token,updateUserData);

        if(
            !!response &&
            !!response.data &&
            response.status === 200
        ){
            yield put({type: actions.UPDATE_USER_SUCCESS,payload:{userdata:response.data}});
        }else{
            yield put({type: actions.UPDATE_USER_SUCCESS, payload:{[FORM_ERROR]: 'Benutzerdaten konnten nicht aktualisiert werden'}});
        }

    } catch (e) {

        if(e.response.status === 422){
            yield put({type: actions.UPDATE_USER_SUCCESS, payload:Object.assign({},{[FORM_ERROR]: 'Benutzerdaten konnten nicht aktualisiert werden'},e.response.data)});

        }else{
            yield put({type: actions.UPDATE_USER_SUCCESS, payload:{[FORM_ERROR]: 'Benutzerdaten konnten nicht aktualisiert werden ('+e+')'}});
        }

    }
}

function* loadGarantien(){
    try {

        /* Get User Token */
        const token = yield select(getToken);

        /* Load Garantien */
        const garantien = yield call([getApiInstance(), getApiInstance().getGarantien],token);

        if(
            !!garantien &&
            !!garantien.data &&
            garantien.data.status === 'success'
        ){
            yield put({type: actions.LOAD_GARANTIE_SUCCESS,payload:{'garantien':garantien.data.garantie}});
        }else{
            yield put({type: actions.LOAD_GARANTIE_ERROR, payload:{[FORM_ERROR]: 'Garantien konnten nicht geladen werden'}});
        }

    } catch (e) {
        yield put({type: actions.LOAD_GARANTIE_ERROR, payload:{[FORM_ERROR]: 'Garantien konnten nicht geladen werden ('+e+')'}});
    }
}

function* loadDownloads(){
    try {

        /* Load Downloads */
        const downloads = yield call([getNewsApiInstance(), getNewsApiInstance().getDownloads]);

        if(
            !!downloads &&
            !!downloads.status &&
            downloads.status === 200
        ){
            yield put({type: actions.LOAD_DOWNLOAD_SUCCESS,payload:{'downloads':downloads.data}});
        }else{
            yield put({type: actions.LOAD_DOWNLOAD_ERROR, payload:{[FORM_ERROR]: 'Downloads konnten nicht geladen werden'}});
        }

    } catch (e) {
        yield put({type: actions.LOAD_DOWNLOAD_ERROR, payload:{[FORM_ERROR]: 'Downloads konnten nicht geladen werden ('+e+')'}});
    }
}


function* loadPreislistenUndGarantiebedingungen(){

    const token = yield select(getToken);

    const tmpId = yield select(getTmpId);

    try {
        const data = yield call([getApiInstance(), getApiInstance().getPreislistenUndGarantiebedingungen],token,tmpId);

        if(
            !!data &&
            !!data.status &&
            data.status === 200
        ){
            yield put({type: actions.LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN_SUCCESS, payload:{'preislistenUndGarantiebedingungen': data.data}});
        }else{
            yield put({type: actions.LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN_ERROR, payload:{[FORM_ERROR]: 'Preislisten und Garantiebedingungen konnten nicht geladen werden'}});
        }

    } catch (e) {
        yield put({type: actions.LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN_ERROR, payload:{[FORM_ERROR]: 'Preislisten und Garantiebedingungen konnten nicht geladen werden ('+e+')'}});
    }
}


function* loadHvAuswertungen(){
    try {

        /* Get User Token */
        const token = yield select(getToken);

        /* Load Auswertungen */
        const auswertungen = yield call([getApiInstance(), getApiInstance().getHvAuswertung],token);

        if(
            !!auswertungen &&
            !!auswertungen.data &&
            auswertungen.data.status === 'success'
        ){
            yield put({type: actions.LOAD_HV_AUSWERTUNGEN_SUCCESS,payload:{'auswertungen':auswertungen.data.hv_auswertung}});
        }else{
            yield put({type: actions.LOAD_HV_AUSWERTUNGEN_ERROR, payload:{[FORM_ERROR]: 'HV Auswertungen konnten nicht geladen werden'}});
        }

    } catch (e){
        yield put({type: actions.LOAD_HV_AUSWERTUNGEN_ERROR, payload:{[FORM_ERROR]: 'HV Auswertungen konnten nicht geladen werden ('+e+')'}});
    }
}

function* loadPdfFromPath(action){
    try {

        /* Get User Token */
        const token = yield select(getToken);

        /* Load PDF */
        const pdfResponse = yield call([getApiInstance(), getApiInstance().getPdfFromPath],token,action.payload.path);

        if(
            !!pdfResponse &&
            !!pdfResponse.data &&
            !!pdfResponse.status &&
            pdfResponse.status === 200
        ){
            const blob = new Blob([pdfResponse.data], {type: "application/pdf"});
            FileSaver.saveAs(blob,action.payload.filename);

            yield put({type: actions.LOAD_PDF_FROM_PATH_SUCCESS,payload:{}});
        }else{
            yield put({type: actions.LOAD_PDF_FROM_PATH_ERROR, payload:{[FORM_ERROR]: 'Garantie PDF konnten nicht geladen werden'}});
        }



    } catch (e) {
        yield put({type: actions.LOAD_PDF_FROM_PATH_ERROR, payload:{[FORM_ERROR]: 'Garantie PDF konnten nicht geladen werden ('+e+')'}});
    }
}

function* openPdfFromPath(action){
    try {

        /* Get User Token */
        const token = yield select(getToken);

        /* Load PDF */
        const pdfResponse = yield call([getApiInstance(), getApiInstance().getPdfFromPath],token,action.payload.path);

        if(
            !!pdfResponse &&
            !!pdfResponse.data &&
            !!pdfResponse.status &&
            pdfResponse.status === 200
        ){

            const blob = new Blob([pdfResponse.data], {type: "application/pdf"});
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL);


            yield put({type: actions.OPEN_PDF_FROM_PATH_SUCCESS,payload:{}});
        }else{
            yield put({type: actions.OPEN_PDF_FROM_PATH_ERROR, payload:{[FORM_ERROR]: 'Garantie PDF konnten nicht geladen werden'}});
        }



    } catch (e) {
        yield put({type: actions.OPEN_PDF_FROM_PATH_ERROR, payload:{[FORM_ERROR]: 'Garantie PDF konnten nicht geladen werden ('+e+')'}});
    }

}


function* showNews(action){
    try {
        yield put(push(Paths.URTEIL+action.payload.uid+'/'+tagGenerator.generate(action.payload.title)));
    } catch (e) {
        yield put({type: actions.NEWS_SHOW_ERROR});
    }
}


function* getTarifeFromApi(fahrzeugart){

    const token = yield select(getToken);

    try {
        const tarife = yield call([getApiInstance(), getApiInstance().getTarifeFromVehicleType],token,fahrzeugart);
        if(
            !!tarife &&
            !!tarife.data &&
            !!tarife.data.availableGarantien
        ){
            return tarife.data;
        }else{
            return false;
        }

    } catch (e) {

        return false;
    }
}

function* getFahrzeugartTarife(action){

    try {

        const fahrzeugartTarife = yield* getTarifeFromApi(action.payload.fahrzeugartSelected);

        yield put({type: actions.FAHRZEUGART_TARIFE_LOAD_SUCCESS, payload:{'fahrzeugartTarife':fahrzeugartTarife || {}}});

    } catch (e) {

    }
}


function* loadTarife(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Vehicle Type */
    const currentFahrzeugart = yield select(getVehicleType);

    if(
        !!currentFahrzeugart
    ){

        try {

            yield put({type: actions.LOAD_TARIFE});

            /* Load Tarife */
            const tarife = yield call([getApiInstance(), getApiInstance().getTarifeFromVehicleType],token,currentFahrzeugart);

            if(
                !!tarife &&
                !!tarife.data &&
                !!tarife.data.availableGarantien
            ){
                yield put({type: actions.LOAD_TARIFE_SUCCESS,payload:{'tarife':tarife.data.availableGarantien}});
            }else{
                yield put({type: actions.LOAD_TARIFE_ERROR, payload:{[FORM_ERROR]: 'Tarife konnten nicht geladen werden'}});
            }

        }catch (e){

            if(
                !!e.response &&
                !!e.response.status &&
                e.response.status === UNAUTHORIZED
            ){
                yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
            }else{
                yield put({type: actions.LOAD_TARIFE_ERROR, payload:{[FORM_ERROR]: 'Tarife konnten nicht geladen werden ('+e+')'}});
            }

        }

    }

}

function* createGarantie(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Get Garantieart */
    const garantieType = yield select(getGarantieart);

    /* is Plus Tarif */
    const isPlusTarif = yield select(getIsPlusTariff);

    /* Get Garantie ID */
    let tmpId = yield select(getTmpId);

    /* Create Garantie */
    if(!tmpId){

        try {

            yield put({type: actions.CREATE_GARANTIE});

            /* Create Garantie */
            const garantie = yield call([getApiInstance(), getApiInstance().createGarantie],token,garantieType,isPlusTarif);

            if(
                !!garantie &&
                !!garantie.data &&
                !!garantie.data.status &&
                garantie.data.status === 'success'
            ){
                tmpId = garantie.data.tmp_id;
                yield put({type: actions.CREATE_GARANTIE_SUCCESS,payload:garantie.data});
            }else{
                yield put({type: actions.CREATE_GARANTIE_ERROR, payload:{[FORM_ERROR]: 'Garantie konnten nicht erstellt werden'}});
            }


        }catch (e){

            if(
                !!e.response &&
                !!e.response.status &&
                e.response.status === UNAUTHORIZED
            ){
                yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
            }else {
                yield put({
                    type: actions.CREATE_GARANTIE_ERROR,
                    payload: {[FORM_ERROR]: 'Garantie konnten nicht erstellt werden (' + e + ')'}
                });
            }
        }

    }

    return tmpId;
}

function* loadConditions(action){


    /* Get Garantie ID */
    const tmpId = yield call(createGarantie,action);

    /* Get User Token */
    const token = yield select(getToken);


    yield put({type: actions.LOAD_CONDITION});

    if(!!tmpId){

        try {

            /* Load Conditions */
            const conditionen = yield call([getApiInstance(), getApiInstance().getCondition],token,tmpId);

            if(
                !!conditionen &&
                !!conditionen.data &&
                !!conditionen.data.status &&
                conditionen.data.status === 'success'
            ){
                yield put({type: actions.LOAD_CONDITION_SUCCESS,payload:{'conditions':conditionen.data.conditions}});
            }else{
                yield put({type: actions.LOAD_CONDITION_ERROR, payload:{[FORM_ERROR]: 'Konditionen konnten nicht geladen werden'}});
            }

        }catch (e){
            if(
                !!e.response &&
                !!e.response.status &&
                e.response.status === UNAUTHORIZED
            ){
                yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
            }else{
                yield put({type: actions.LOAD_CONDITION_ERROR, payload:{[FORM_ERROR]: 'Konditionen konnten nicht geladen werden ('+e+')'}});

            }
        }

    }else{
        yield put({type: actions.LOAD_CONDITION_ERROR, payload:{[FORM_ERROR]: 'Konditionen konnten nicht geladen werden'}});

    }


}

function* loadCustomerValidationRules(action){

    /* Get User Token */
    const token = yield select(getToken);

    yield put({type: actions.LOAD_CUSTOMER_VALIDATION_RULES});

    try {

        /* Load Rules */
        const validationRules = yield call([getApiInstance(), getApiInstance().getCustomerValidationRules],token);

        if(
            !!validationRules.data &&
            !!validationRules.data.status &&
            validationRules.data.status === 'success' &&
            !!validationRules.data.rules
        ){
            yield put({type: actions.LOAD_CUSTOMER_VALIDATION_RULES_SUCCESS,payload:{'rules':validationRules.data.rules}});
        }else{
            yield put({type: actions.LOAD_CUSTOMER_VALIDATION_RULES_ERROR, payload:{[FORM_ERROR]: 'Kunden - Validierungsregeln konnten nicht geladen werden'}});
        }

    }catch(e){
        if(
            !!e.response &&
            !!e.response.status &&
            e.response.status === UNAUTHORIZED
        ){
            yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
        }else {
            yield put({
                type: actions.LOAD_CUSTOMER_VALIDATION_RULES_ERROR,
                payload: {[FORM_ERROR]: 'Kunden - Validierungsregeln konnten nicht geladen werden'}
            });
        }
    }

}

function* loadVehicleValidationRules(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Get Garantieart */
    const garantieType = yield select(getGarantieart);

    /* Get Kondition */
    const kondition = yield select(getKondition);

    yield put({type: actions.LOAD_VEHICLE_VALIDATION_RULES});

    try {

        /* Load Rules */
        const validationRules = yield call([getApiInstance(), getApiInstance().getVehicleValidationRules],token,garantieType,kondition);

        if(
            !!validationRules.data &&
            !!validationRules.data.status &&
            validationRules.data.status === 'success' &&
            !!validationRules.data.rules
        ){
            if (validationRules.data.chargedImpacts) {
                yield put({ type: actions.LOAD_VEHICLE_VALIDATION_RULES_SUCCESS, payload: { 
                    'rules': validationRules.data.rules, 
                    'chargedImpacts': validationRules.data.chargedImpacts } 
                });
            } 
            else yield put({type: actions.LOAD_VEHICLE_VALIDATION_RULES_SUCCESS,payload:{'rules':validationRules.data.rules}});
        }else{
            yield put({type: actions.LOAD_VEHICLE_VALIDATION_RULES_ERROR, payload:{[FORM_ERROR]: 'Fahrzeug - Validierungsregeln konnten nicht geladen werden'}});
        }

    }catch(e){
        if(
            !!e.response &&
            !!e.response.status &&
            e.response.status === UNAUTHORIZED
        ){
            yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
        }else {
            yield put({
                type: actions.LOAD_VEHICLE_VALIDATION_RULES_ERROR,
                payload: {[FORM_ERROR]: 'Fahrzeug - Validierungsregeln konnten nicht geladen werden'}
            });
        }
    }

}

function* loadDefaultVehicleValidationRules(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Get Garantieart */
    const garantieType = yield select(getGarantieart);

    yield put({type: actions.LOAD_VEHICLE_VALIDATION_RULES});

    try {

        /* Load Rules */
        const validationRules = yield call([getApiInstance(), getApiInstance().getDefaultVehicleValidationRules],token,garantieType);

        if(
            !!validationRules.data &&
            !!validationRules.data.status &&
            validationRules.data.status === 'success' &&
            !!validationRules.data.rules
        ){
            // yield put({type: actions.LOAD_VEHICLE_VALIDATION_RULES_SUCCESS,payload:{'rules':validationRules.data.rules}});
            if (validationRules.data.chargedImpacts) {
                yield put({
                    type: actions.LOAD_VEHICLE_VALIDATION_RULES_SUCCESS, payload: {
                        'rules': validationRules.data.rules,
                        'chargedImpacts': validationRules.data.chargedImpacts
                    }
                });
            }
            else yield put({ type: actions.LOAD_VEHICLE_VALIDATION_RULES_SUCCESS, payload: { 'rules': validationRules.data.rules } });
        }else{
            yield put({type: actions.LOAD_VEHICLE_VALIDATION_RULES_ERROR, payload:{[FORM_ERROR]: 'Fahrzeug - Validierungsregeln konnten nicht geladen werden'}});
        }

    }catch(e){
        if(
            !!e.response &&
            !!e.response.status &&
            e.response.status === UNAUTHORIZED
        ){
            yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
        }else{
            yield put({type: actions.LOAD_VEHICLE_VALIDATION_RULES_ERROR, payload:{[FORM_ERROR]: 'Fahrzeug - Validierungsregeln konnten nicht geladen werden'}});
        }
    }

}

function* loadGarantieValidationRules(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Get Garantieart */
    const garantieType = yield select(getGarantieart);

    /* Get Kondition */
    const kondition = yield select(getKondition);

    yield put({type: actions.LOAD_GARANTIE_VALIDATION_RULES});

    try {

        /* Load Rules */
        const validationRules = yield call([getApiInstance(), getApiInstance().getGarantieValidationRules],token,garantieType,kondition);

        if(
            !!validationRules.data &&
            !!validationRules.data.status &&
            validationRules.data.status === 'success' &&
            !!validationRules.data.rules
        ){
            yield put({type: actions.LOAD_GARANTIE_VALIDATION_RULES_SUCCESS,payload:{'rules':validationRules.data.rules}});
        }else{
            yield put({type: actions.LOAD_GARANTIE_VALIDATION_RULES_ERROR, payload:{[FORM_ERROR]: 'Garantie - Validierungsregeln konnten nicht geladen werden'}});
        }

    }catch(e){
        if(
            !!e.response &&
            !!e.response.status &&
            e.response.status === UNAUTHORIZED
        ){
            yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
        }else {
            yield put({
                type: actions.LOAD_GARANTIE_VALIDATION_RULES_ERROR,
                payload: {[FORM_ERROR]: 'Garantie - Validierungsregeln konnten nicht geladen werden'}
            });
        }
    }

}

function* loadDefaultGarantieValidationRules(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Get Garantieart */
    const garantieType = yield select(getGarantieart);

    yield put({type: actions.LOAD_GARANTIE_VALIDATION_RULES});

    try {

        /* Load Rules */
        const validationRules = yield call([getApiInstance(), getApiInstance().getGarantieValidationRulesWithoutCondition],token,garantieType);

        if(
            !!validationRules.data &&
            !!validationRules.data.status &&
            validationRules.data.status === 'success' &&
            !!validationRules.data.rules
        ){
            yield put({type: actions.LOAD_GARANTIE_VALIDATION_RULES_SUCCESS,payload:{'rules':validationRules.data.rules}});
        }else{
            yield put({type: actions.LOAD_GARANTIE_VALIDATION_RULES_ERROR, payload:{[FORM_ERROR]: 'Garantie - Validierungsregeln konnten nicht geladen werden'}});
        }

    }catch(e){
        if(
            !!e.response &&
            !!e.response.status &&
            e.response.status === UNAUTHORIZED
        ){
            yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
        }else {
            yield put({
                type: actions.LOAD_GARANTIE_VALIDATION_RULES_ERROR,
                payload: {[FORM_ERROR]: 'Garantie - Validierungsregeln konnten nicht geladen werden'}
            });
        }
    }

}

function* loadSummary(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Get Garantie ID */
    const tmpId = yield select(getTmpId);

    yield put({type: actions.LOAD_SUMMARY});

    try {

        /* Load Summary */
        const summary = yield call([getApiInstance(), getApiInstance().getSummary],token,tmpId);

        if(
            !!summary.data &&
            !!summary.data.status &&
            summary.data.status === 'success' &&
            !!summary.data.data
        ){
            yield put({type: actions.LOAD_SUMMARY_SUCCESS,payload:{'rules':summary.data.data}});
        }else{
            yield put({type: actions.LOAD_SUMMARY_ERROR, payload:{[FORM_ERROR]: 'Zusammenfassung konnte nicht geladen werden'}});
        }

    }catch(e){
        if(
            !!e.response &&
            !!e.response.status &&
            e.response.status === UNAUTHORIZED
        ){
            yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
        }else {
            yield put({
                type: actions.LOAD_SUMMARY_ERROR,
                payload: {[FORM_ERROR]: 'Zusammenfassung konnte nicht geladen werden'}
            });
        }
    }



}

function* garantieLoadWizard(action){

    /* Get Page */
    const page = yield select(getPage);

    /* Fahrzeugart */
    const fahrzeugart = yield select(getVehicleType);

    /* Garantieart */
    const garantieart = yield select(getGarantieart);

    /* Tarife */
    const tarife = yield select(getTarife);

    /* Gewählter Tarif */
    const selectedTarif = (!!tarife && !!fahrzeugart && !!tarife[fahrzeugart] ) ? tarife[fahrzeugart].reduce((result, item) => {
        if (item.identifier === garantieart) { result = item }
        return result
    }, {}) : {};

    switch(page){

        /* Garantieart */
        case 1:
            yield fork(loadTarife,action);
        break;

        /* Konditionen */
        case 2:
            yield fork(loadConditions,action);
        break;

        /* Garantienehmer */
        case 3:
            yield fork(loadCustomerValidationRules,action);
        break;

        /* Fahrzeugdaten */
        case 4:

            if(!!selectedTarif.dependsOnVehicleCondition){
                yield fork(loadVehicleValidationRules,action);
            }else{
                yield fork(loadDefaultVehicleValidationRules,action)
            };

        break;

        /* Garantie */
        case 5:

            if(!!selectedTarif.dependsOnVehicleCondition){
                yield fork(loadGarantieValidationRules,action);
            }else{
                yield fork(loadDefaultGarantieValidationRules,action);
            }

        break;

        /* Übersicht */
        case 7:
            yield fork(loadSummary,action);
        break;

        default:
            break;

    }

}

function* approveSubmitWizard(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Get Page */
    const page = yield select(getApprovePage);

    /* Get User Data */
    const userData = yield select(getUserData);

    /* Merged User Data */
    const updateUserData = Object.assign({},userData,action.payload);

    /* User is Migrated */
    const isMigrated = yield select(getIsMigrated);

    switch(page){

        /* Benutzerdaten aktualisieren */
        case 0:

            try {

                /* Update User */
                const response = yield call([getApiInstance(), getApiInstance().updateUser],token,updateUserData);

                if(
                    !!response &&
                    !!response.data &&
                    response.status === 200
                ){

                    yield put({type: actions.UPDATE_USER_SUCCESS,payload:{userdata:response.data}});

                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:((!!isMigrated) ? 1 : 2)});
                    //yield put({type: actions.APPROVE_TO,page:1,payload:{userdata:response.data}});
                }else{
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:0, payload:{[FORM_ERROR]: 'Benutzerdaten konnten nicht aktualisiert werden'}});
                }

            }catch (e) {

                if(e.response.status === 422){
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:0,payload:Object.assign({},{[FORM_ERROR]: 'Benutzerdaten konnten nicht aktualisiert werden'},e.response.data)});
                }else{
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:0,payload:{[FORM_ERROR]: 'Benutzerdaten konnten nicht aktualisiert werden ('+e+')'}});
                }

            }

            break;

            /* Passwort aktualisieren */
        case 1:

            try {

                const passwordData = {
                    old_password:action.payload.old_passwort,
                    new_password:action.payload.new_passwort,
                };

                /* Update Password */
                const response = yield call([getApiInstance(), getApiInstance().updatePassword],token,passwordData);

                if(
                    !!response &&
                    !!response.data &&
                    response.status === 200
                ){
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:2});
                }else{
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:1, payload:{[FORM_ERROR]: 'Passwort konnten nicht aktualisiert werden'}});
                }


            }catch(e){
                if(e.response.status === 422) {
                    yield put({
                        type: actions.APPROVE_PROMISE_RESOLVE,
                        page: 1,
                        payload: Object.assign({}, {[FORM_ERROR]: 'Passwort konnten nicht aktualisiert werden'}, e.response.data)
                    });
                }else if(e.response.status === 400){
                    yield put({
                        type: actions.APPROVE_PROMISE_RESOLVE,
                        page: 1,
                        payload: Object.assign({}, {[FORM_ERROR]: 'Passwort konnten nicht aktualisiert werden '+((!!e.response && !!e.response.data && !!e.response.data.errorMessage) ? '('+e.response.data.errorMessage+')' : '' )}, e.response.data)
                    });
                }else{
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:1,payload:{[FORM_ERROR]: 'Passwort konnten nicht aktualisiert werden ('+e+')'}});
                }
            }

            break;

            /* Vorgand abschließen */
        case 2:

            try {

                /* Update User */
                const response = yield call([getApiInstance(), getApiInstance().updateUserApprove],token,updateUserData);

                if(
                    !!response &&
                    !!response.data &&
                    response.status === 200
                ){

                    yield put({type: actions.APPROVE_COMPLETED,payload:{userdata:response.data}});

                }else{
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:2, payload:{[FORM_ERROR]: 'Vorgang konnten nicht abgeschlossen werden'}});
                }

            }catch(e){

                if(e.response.status === 422){
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:2,payload:Object.assign({},{[FORM_ERROR]: 'Vorgang konnten nicht abgeschlossen werden'},e.response.data)});

                }else{
                    yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:2,payload:{[FORM_ERROR]: 'Vorgang konnten nicht abgeschlossen werden ('+e+')'}});
                }
            }

            break;

        default:
            yield put({type: actions.APPROVE_PROMISE_RESOLVE,page:0});

    }

}

function* garantieSubmitWizard(action){

    /* Get User Token */
    const token = yield select(getToken);

    /* Get Page */
    const page = yield select(getPage);

    /* Fahrzeugart */
    const fahrzeugart = yield select(getVehicleType);

    /* Garantieart */
    const garantieart = yield select(getGarantieart);

    const tarife = yield select(getTarife);

    /* Garantietyp */
    const garantietyp = yield select(getKondition);

    /* Garantietyp Aktzeptiert */
    const tarifTypAccept = yield select(getKonditionAccept);

    /* TmpId */
    const tmpId = yield select(getTmpId);

    switch(page){

        case 0:
            yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:1});
        break;

        /* Garantieart */
        case 1:

            if(
                !!garantieart &&
                !!tarife[fahrzeugart] &&
                !!tarife[fahrzeugart].reduce((result, item) => {
                    if (item.identifier === garantieart) { result = true }
                        return result
                    }, false)
            ){

                const selectedTarif = tarife[fahrzeugart].reduce((result, item) => {
                    if (item.identifier === garantieart) { result = item }
                    return result
                }, {});


                const nextPage = ((!!selectedTarif.dependsOnVehicleCondition) ? (page+1) : (page+2));

                if(!selectedTarif.dependsOnVehicleCondition){
                    yield call(createGarantie,action);
                }

                yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:nextPage});
            }else{
                yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:page,payload:{[FORM_ERROR]:'Bitte Garantieart auswählen'}});
            }

        break;

        /* Konditionen */
        case 2:


            if(!garantietyp){
                yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:page,payload:{[FORM_ERROR]:'Bitte Garantieart auswählen'}});
            }else{

                const conditions = yield select(getConditions);

                const condition = (!!conditions && conditions.hasOwnProperty(garantieart)) ? conditions[garantieart].reduce((result, item) => {
                    if (parseInt(item.value,10) === parseInt(garantietyp,10)) { result = item }
                    return result
                }, {}) : {};


                if(
                    !!condition &&
                    condition.hasOwnProperty('policy') &&
                    parseInt(condition.policy.length,10) > 0 &&
                    !tarifTypAccept
                ){
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:page,payload:{[FORM_ERROR]:'Ihre Zustimmung zu den Bedingungen ist notwendig'}});
                }else{

                    try {

                        /* Get Garantie ID */
                        const tmpId = yield select(getTmpId);

                        /* Save Condition */
                        yield call([getApiInstance(), getApiInstance().setCondition],token,tmpId,garantietyp,tarifTypAccept);


                        yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:(page+1)});

                    }catch (e){
                        yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:page,payload:{[FORM_ERROR]:'Ihre Kondition konnte nicht erstellt werden'}});
                    }

                }

            }

        break;

            /* Garantienehmer */
        case 3:

            /* Garantienehmer */
            const customer = yield select(getCustomer);

            try {

                /* Save Customer */
                const customerResponse = yield call([getApiInstance(), getApiInstance().setCustomer],token,tmpId,customer);


                if(
                    !!customerResponse.data &&
                    !!customerResponse.data.status &&
                    customerResponse.data.status === 'success'
                ){

                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:(page+1)});

                }else{
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Garantienehmer konnte nicht gespeichert werden.'}});
                }


            }catch (e){
                if(
                    !!e.response &&
                    !!e.response.status &&
                    e.response.status === UNAUTHORIZED
                ){
                    yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
                }else if(
                    !!e.response &&
                    !!e.response.status &&
                    e.response.status === 409 &&
                    !!e.response.data &&
                    !!e.response.data.errors
                ){

                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:Object.assign({},{[FORM_ERROR]: 'Garantienehmer konnten nicht gespeichert werden'},{customer:e.response.data.errors})});

                }else{
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Garantienehmer konnte nicht gespeichert werden.'}});
                }
            }

        break;

            /* Fahrzeugdaten */
        case 4:

            /* Fahrzeug */
            const vehicle = yield select(getVehicle);

            yield put({type: actions.CREATE_VEHICLE});

            try {

                /* Save Vehicle */
                const vehicleResponse = yield call([getApiInstance(), getApiInstance().setVehicle],token,tmpId,vehicle);

                if(
                    !!vehicleResponse.data &&
                    !!vehicleResponse.data.status &&
                    vehicleResponse.data.status === 'success'
                ){
                    yield put({type: actions.CREATE_VEHICLE_SUCCESS,payload:{data:vehicleResponse.data.data,chargedImpacts:vehicleResponse.data.chargedImpacts}});
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:(page+1)});

                }else{
                    yield put({type: actions.CREATE_VEHICLE_ERROR,payload:{error:'Fahrzeugdaten konnte nicht gespeichert werden.'}});
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Fahrzeugdaten konnte nicht gespeichert werden.'}});
                }

            }catch (e){
                if(
                    !!e.response &&
                    !!e.response.status &&
                    e.response.status === UNAUTHORIZED
                ){
                    yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
                }else if(
                    !!e.response &&
                    e.response.status === 409 &&
                    !!e.response.data &&
                    !!e.response.data.errors
                ){

                    yield put({type: actions.CREATE_VEHICLE_ERROR,payload:{error:'Fahrzeugdaten konnte nicht gespeichert werden.'+e}});
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:Object.assign({},{[FORM_ERROR]: 'Fahrzeugdaten konnten nicht gespeichert werden'},{vehicle:e.response.data.errors})});

                }else{
                    yield put({type: actions.CREATE_VEHICLE_ERROR,payload:{error:'Fahrzeugdaten konnte nicht gespeichert werden.'+e}});
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Fahrzeugdaten konnte nicht gespeichert werden.'}});
                }
            }

        break;

        /* Garantiedaten */
        case 5:

            /* Garantie Daten */
            const garantieData = yield select(getGarantie);

            try {

                /* Save Garantie */
                const garantieResponse = yield call([getApiInstance(), getApiInstance().setGarantie],token,tmpId,garantieData);

                if(
                    !!garantieResponse.data &&
                    !!garantieResponse.data.status &&
                    garantieResponse.data.status === 'success'
                ){

                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:(page+1)});

                }else{
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Garantiedaten konnte nicht gespeichert werden.'}});
                }

            }catch (e){
                if(
                    !!e.response &&
                    !!e.response.status &&
                    e.response.status === UNAUTHORIZED
                ){
                    yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
                }else if(
                    !!e.response &&
                    !!e.response.status &&
                    e.response.status === 409 &&
                    !!e.response.data &&
                    !!e.response.data.errors
                ){

                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:Object.assign({},{[FORM_ERROR]: 'Garantiedaten konnten nicht gespeichert werden'},{garantiedaten:e.response.data.errors})});

                }else{
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Garantiedaten konnte nicht gespeichert werden.'}});
                }
            }

            break;

        /* Inspektionsnachweise */
        case 6:

            /* Garantie Daten */
            const inspektionsnachweiseData = yield select(getInspektionsnachweise);

            try {

                const inspektionsnachweise = Object.assign({},{
                    inspectionProof: Array.isArray(inspektionsnachweiseData.inspectionProof) && inspektionsnachweiseData.inspectionProof.length >= 1 ? inspektionsnachweiseData.inspectionProof[0] : inspektionsnachweiseData.inspectionProof,
                    ...(Array.isArray(inspektionsnachweiseData.proofOfInspectionFilePath) && inspektionsnachweiseData.proofOfInspectionFilePath.length >= 1 && { proofOfInspectionFilePath:inspektionsnachweiseData.proofOfInspectionFilePath[0]}),
                    ...(Array.isArray(inspektionsnachweiseData.additionalDocumentFilePath1) && inspektionsnachweiseData.additionalDocumentFilePath1.length >= 1 && { additionalDocumentFilePath1:inspektionsnachweiseData.additionalDocumentFilePath1[0]}),
                    ...(Array.isArray(inspektionsnachweiseData.additionalDocumentFilePath2) && inspektionsnachweiseData.additionalDocumentFilePath2.length >= 1 && { additionalDocumentFilePath2:inspektionsnachweiseData.additionalDocumentFilePath2[0]}),
                    ...(Array.isArray(inspektionsnachweiseData.additionalDocumentFilePath3) && inspektionsnachweiseData.additionalDocumentFilePath3.length >= 1 && { additionalDocumentFilePath3:inspektionsnachweiseData.additionalDocumentFilePath3[0]}),
                });

                console.log('----');
                console.log(inspektionsnachweise);

                /* Save Inspektionsnachweise */
                const inspektionsnachweiseResponse = yield call([getApiInstance(), getApiInstance().setInspektionsnachweise],token,tmpId,inspektionsnachweise);

                if(
                    !!inspektionsnachweiseResponse.data &&
                    !!inspektionsnachweiseResponse.data.status &&
                    inspektionsnachweiseResponse.data.status === 'success'
                ){

                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:(page+1)});

                }else{
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Inspektionsnachweise konnte nicht gespeichert werden.'}});
                }

            }catch (e){
                if(
                    !!e.response &&
                    !!e.response.status &&
                    e.response.status === UNAUTHORIZED
                ){
                    yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});

                }else if(
                    !!e.response &&
                    !!e.response.status &&
                    e.response.status === 409 &&
                    !!e.response.data &&
                    !!e.response.data.errors
                ){

                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:Object.assign({},{[FORM_ERROR]: 'Inspektionsnachweise konnten nicht gespeichert werden'},{garantiedaten:e.response.data.errors})});

                }else{

                    if (e.response.status === 412) {

                        yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Unzulässige Eingabe im Rahmen der Neufahrzeuganschlussgarantie'}});
                    } else
                        yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Inspektionsnachweise konnten nicht gespeichert werden. ('+ e.message +')'}});
                }
            }

            break;


        /* Zusammenfassung */
        case 7:

            const remarks = yield select(getRemarks);
            const inspektionscheckliste = yield select(getInspektionscheckliste);
            const uebergabeprotokoll = yield select(getUebergabeprotokoll);

            yield put({type: actions.SUBMIT_GARANTIE});

            try {

                /* Submit Garantie */
                const submitResponse = yield call([getApiInstance(), getApiInstance().submit],token,tmpId,remarks);

                if(
                    !!submitResponse.data &&
                    !!submitResponse.data.status &&
                    submitResponse.data.status === 'success'
                ){

                    yield put({type: actions.SUBMIT_GARANTIE_SUCCESS,payload:submitResponse.data});

                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE, page:(page+1),payload:submitResponse.data});

                    yield fork(loadGarantien);

                    /* Send Garantieabschluss to Browser */
                    if(
                        !!submitResponse.data.pdf &&
                        !!submitResponse.data.pdf.garantieabschluss
                    ){
                        /* Load PDF */
                        const pdfResponse = yield call([getApiInstance(), getApiInstance().getPdfFromPath],token,submitResponse.data.pdf.garantieabschluss);

                        if(
                            !!pdfResponse &&
                            !!pdfResponse.data &&
                            !!pdfResponse.status &&
                            pdfResponse.status === 200
                        ) {

                            const blob = new Blob([pdfResponse.data], {type: "application/pdf;charset=utf-8"});
                            FileSaver.saveAs(blob, submitResponse.data.gar_nr+".pdf");
                        }
                    }

                    /* Send Inspektionscheckliste to Browser */
                    if(
                        !!inspektionscheckliste &&
                        !!submitResponse.data.pdf &&
                        !!submitResponse.data.pdf.inspektionscheckliste
                    ){
                        /* Load PDF */
                        const checkpdfResponse = yield call([getApiInstance(), getApiInstance().getPdfFromPath],token,submitResponse.data.pdf.inspektionscheckliste);

                        if(
                            !!checkpdfResponse &&
                            !!checkpdfResponse.data &&
                            !!checkpdfResponse.status &&
                            checkpdfResponse.status === 200
                        ) {

                            const checkblob = new Blob([checkpdfResponse.data], {type: "application/pdf;charset=utf-8"});
                            FileSaver.saveAs(checkblob, 'Inspektionscheckliste.pdf');
                        }
                    }

                    /* Send Uebergabeprotokoll to Browser */
                    if(
                        !!uebergabeprotokoll &&
                        !!submitResponse.data.pdf &&
                        !!submitResponse.data.pdf.uebergabeprotokoll
                    ){
                        /* Load PDF */
                        const protokollpdfResponse = yield call([getApiInstance(), getApiInstance().getPdfFromPath],token,submitResponse.data.pdf.uebergabeprotokoll);

                        if(
                            !!protokollpdfResponse &&
                            !!protokollpdfResponse.data &&
                            !!protokollpdfResponse.status &&
                            protokollpdfResponse.status === 200
                        ) {

                            const protoblob = new Blob([protokollpdfResponse.data], {type: "application/pdf;charset=utf-8"});
                            FileSaver.saveAs(protoblob, 'uebergabeprotokoll.pdf');
                        }
                    }

                }else{
                    yield put({type: actions.SUBMIT_GARANTIE_ERROR,payload:submitResponse.data});
                    yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:page,payload:{[FORM_ERROR]:'Garantiedaten konnte nicht gespeichert werden.'}});
                }

            }catch (e){
                if(
                    !!e.response &&
                    !!e.response.status &&
                    e.response.status === UNAUTHORIZED
                ){
                    yield put({type: actions.AUTH_UNAUTHORIZED, payload:{error:e,action:action}});
                }else {
                    yield put({type: actions.SUBMIT_GARANTIE_ERROR, payload: e});
                    yield put({
                        type: actions.GARANTIE_PROMISE_RESOLVE,
                        page: page,
                        payload: {[FORM_ERROR]: 'Garantie konnte nicht eingereicht werden.'}
                    });
                }
            }

            break;

        default:
            yield put({type: actions.GARANTIE_PROMISE_RESOLVE,page:0});

    }


}

function* locationChange(action){
    const {payload} = action;
    const {location} = payload;
    const {search} = location;
    const params = new URLSearchParams(search);

    if(
        !!location.pathname &&
        location.pathname === '/' &&
        params.has('jwtToken')
    ){

        yield put({
            type: actions.JWTOKEN_RECEIVE,
            payload: {jwtToken:params.get('jwtToken')}
        });

    }else if(
        !!location.pathname &&
        location.pathname === '/' &&
        params.has('resetForm') &&
        parseInt(params.get('resetForm'),10) === 1
    ){
        yield put(completeWizard());
    }
}

function* appSaga() {
    
    yield takeLatest(actions.CHECK_EMAIL, checkEmail);
    yield takeLatest(actions.LOGIN_KUNDENPORTAL, loginKundenportal);
    yield takeLatest(actions.LOAD_KUNDENPORTAL_GARANTIE, loadKundenportalGarantie);
    yield takeLatest(actions.LOAD_KUNDENPORTAL_GARANTIE_PDF, loadKundenportalGarantiePdf);
    yield takeLatest(actions.INIT, initApp);
    yield takeLatest(actions.LOGIN, login);
    yield takeLatest(actions.LOGOUT, logout);
    yield takeLatest(actions.NEWS_LOAD, loadNews);
    yield takeLatest(actions.NEWS_SHOW, showNews);
    yield takeLatest(actions.NEWS_SINGLE_LOAD, loadSingleNews);
    yield takeLatest(actions.LOAD_GARANTIE, loadGarantien);
    yield takeLatest(actions.LOAD_PDF_FROM_PATH, loadPdfFromPath);
    yield takeLatest(actions.OPEN_PDF_FROM_PATH, openPdfFromPath);
    yield takeLeading(actions.GARANTIE_WIZARD_SHOW,garantieLoadWizard);
    yield takeLeading(actions.GARANTIE_PROMISE_START,garantieSubmitWizard);
    yield takeLatest(actions.PW_REFRESH, passwordRefresh);
    yield takeLatest(actions.LOAD_HV_AUSWERTUNGEN, loadHvAuswertungen);
    yield takeLatest(actions.UPDATE_USER, updateUser);
    yield takeLatest(actions.CONTACT_SUBMIT,submitContact);
    yield takeLatest(actions.PW_RESET,passwordReset);
    yield takeLatest(actions.MODAL_USER_PWRESET_CONFIRM,passwordRefresh);
    yield takeLatest(actions.LOAD_DOWNLOAD, loadDownloads);
    yield takeLatest(actions.LOAD_PREISLISTEN_UND_GARANTIEBEDINGUNGEN, loadPreislistenUndGarantiebedingungen);
    yield takeLatest(LOCATION_CHANGE,locationChange);
    yield takeLeading(actions.APPROVE_PROMISE_START,approveSubmitWizard);
    yield takeLeading(actions.FAHRZEUGART_TARIFE_LOAD,getFahrzeugartTarife);
}

export default appSaga;
