import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const CheckboxWrapper = ({input: {checked, name, onChange, ...restInput},label,meta,...rest}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (meta.touched || meta.submitError);

    return (
        <FormControl error={!!showError} component="fieldset">
            <FormControlLabel
                label={label}
                control={<Checkbox
                    {...rest}
                    name={name}
                    color="primary"
                    disableRipple
                    value="1"
                    style={{alignSelf:'flex-start',paddingTop:0}}
                    inputProps={restInput}
                    onChange={onChange}
                    checked={checked}
                />}>
            </FormControlLabel>
            <FormHelperText>{showError ? meta.error || meta.submitError : undefined}</FormHelperText>
        </FormControl>
)};

export default CheckboxWrapper;
