import {actions as appActions} from './App';

// Actions
export const actions = {
    UPDATE_FORM_STATE:'newGarantie/UPDATE_FORM_STATE',
    NEXT_WIZARD:'newGarantie/NEXT_WIZARD',
    GOTO_WIZARD:'newGarantie/GOTO_WIZARD',
    PREV_WIZARD:'newGarantie/PREV_WIZARD',
    CANCEL_WIZARD:'newGarantie/CANCEL_WIZARD',
    COMPLETED_WIZARD:'newGarantie/COMPLETED_WIZARD',
};

const initialState = {
    page:0,
    approvepage:0,
    approveWasSubmited:false,
    lastApprovePage:[],
    lastPage:[],
    wasSubmited:false,
    values:{},
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case actions.UPDATE_FORM_STATE:
            return {
                ...state,
                [action.form]: action.payload
            };

        case actions.NEXT_WIZARD:
            return Object.assign({}, state, {
                values: action.values,
                page:(state.page+1),
                lastPage:((state.lastPage[state.lastPage.length-1] !== state.page) ? state.lastPage.concat([state.page]) : state.lastPage)
            });

        case appActions.GARANTIE_PROMISE_RESOLVE:
            return Object.assign({}, state, {
                page:action.page,
                lastPage:((state.lastPage[state.lastPage.length-1] !== state.page && action.page !== state.page) ? state.lastPage.concat([state.page]) : state.lastPage),
                wasSubmited:true,
            });

        case appActions.APPROVE_PROMISE_RESOLVE:
            return Object.assign({}, state, {
                approvepage:action.page,
                lastApprovePage:((state.lastApprovePage[state.lastApprovePage.length-1] !== state.approvepage && action.page !== state.approvepage) ? state.lastApprovePage.concat([state.approvepage]) : state.lastApprovePage),
                approveWasSubmited:true,
            });

        case appActions.APPROVE_TO:
            return Object.assign({}, state, {
                approvepage:action.page,
                lastApprovePage:((state.lastApprovePage[state.lastApprovePage.length-1] !== state.approvepage && action.page !== state.approvepage) ? state.lastApprovePage.concat([state.approvepage]) : state.lastApprovePage),
                approveWasSubmited:false,
            });

        case actions.GOTO_WIZARD:
            return Object.assign({}, state, {
                page:action.page,
                lastPage:((state.lastPage[state.lastPage.length-1] !== state.page && action.page !== state.page) ? state.lastPage.concat([state.page]) : state.lastPage),
                wasSubmited:false,
            });

        case actions.PREV_WIZARD:
            return Object.assign({}, state, {
                page: Math.max(state.lastPage[state.lastPage.length-1],0),
                lastPage:state.lastPage.splice(0,state.lastPage.length-1),
                wasSubmited:false
            });

        case actions.COMPLETED_WIZARD:
        case actions.CANCEL_WIZARD:
            return Object.assign({}, state, {
                page:0,
                lastPage:[],
                values:{},
                wasSubmited:false
            });


        default:
            return state
    }
}

// Action Creators
export const updateFormState = (form, state) => ({
    type:actions.UPDATE_FORM_STATE,
    form,
    payload: state
});

export const nextWizard = (values) => ({
    type:actions.NEXT_WIZARD,
    values:values
});

export const prevWizard = () => ({
    type:actions.PREV_WIZARD
});

export const gotoWizard = (page) => ({
    type:actions.GOTO_WIZARD,
    page:page
});

export const cancelWizard = () => ({
    type:actions.CANCEL_WIZARD
});

export const completeWizard = () => ({
    type:actions.COMPLETED_WIZARD
});


// Selectors
export const getFormState = (state, form) =>
(state && state.form && state.form[form]) || {}
