import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GuaranteeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 310.75 310.75" {...props}>
            <path d="M155.375,0C69.598,0,0,69.598,0,155.375s69.598,155.375,155.375,155.375s155.375-69.598,155.375-155.375
        S241.152,0,155.375,0z M155.375,275.75c-66.334,0-120.375-54.041-120.375-120.375S89.041,35,155.375,35
        S275.75,89.041,275.75,155.375S221.709,275.75,155.375,275.75z"/>
            <path d="M208.827,101.923L127.923,182.827l-25.75-25.75l-24.498,24.498l50.248,50.248l105.402-105.402L208.827,101.923z"/>
        </SvgIcon>
    );
}
