import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Garantieliste(props) {
    return (
        <SvgIcon viewBox="0 0 27 23" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g  transform="translate(-17.000000, -243.000000)" stroke="currentColor" strokeWidth="1.5">
                    <g  transform="translate(18.000000, 244.000000)">
                        <g >
                            <path d="M10.1818182,2.04545455 L25.1818182,2.04545455" ></path>
                            <path d="M10.1818182,10.9090909 L25.1818182,10.9090909" ></path>
                            <path d="M10.1818182,19.0909091 L25.1818182,19.0909091" ></path>
                            <path d="M4.77272727,2.38636364 C4.77272727,3.70431818 3.70431818,4.77272727 2.38636364,4.77272727 C1.06840909,4.77272727 0,3.70431818 0,2.38636364 C0,1.06840909 1.06840909,0 2.38636364,0 C3.70431818,0 4.77272727,1.06840909 4.77272727,2.38636364 Z" ></path>
                            <path d="M4.77272727,10.5681818 C4.77272727,11.8861364 3.70431818,12.9545455 2.38636364,12.9545455 C1.06840909,12.9545455 0,11.8861364 0,10.5681818 C0,9.25022727 1.06840909,8.18181818 2.38636364,8.18181818 C3.70431818,8.18181818 4.77272727,9.25022727 4.77272727,10.5681818 Z" ></path>
                            <path d="M4.77272727,18.75 C4.77272727,20.0679545 3.70431818,21.1363636 2.38636364,21.1363636 C1.06840909,21.1363636 0,20.0679545 0,18.75 C0,17.4320455 1.06840909,16.3636364 2.38636364,16.3636364 C3.70431818,16.3636364 4.77272727,17.4320455 4.77272727,18.75 Z" ></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};