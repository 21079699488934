import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ProgarantLogo from '../../Assets/Png/logo-progarant.png';
import LogoutIcon from '../../Assets/Svg/Logout';
import ContactIcon from '../../Assets/Svg/Contact';
import SettingsIcon from '../../Assets/Svg/Settings';
import OverviewIcon from '../../Assets/Svg/Overview';
import DownloadsIcon from '../../Assets/Svg/Downloads';
import UrteileIcon from '../../Assets/Svg/Urteile'
import InfoIcon from '../../Assets/Svg/Info';
import AddIcon from '../../Assets/Svg/Add';
import ContinueIcon from '../../Assets/Svg/Continue';
import GarantielisteIcon from '../../Assets/Svg/Garantieliste';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {Paths} from '../../Constants/Paths';
import {openSpeedDial,closeSpeedDial} from '../../Reducers/App';
import { logout } from '../../Reducers/App';
import EuroIcon from "../../Assets/Svg/Euro";
import GuaranteeIcon from "../../Assets/Svg/GuaranteeIcon";

const drawerWidth = 210;

const styles = theme => ({
    drawerPaper: {
        width: drawerWidth,
        backgroundColor:'#003165',
        color:'#ffffff'
    },
    drawerIcon:{
        color:'#ffffff',
        minWidth:'40px'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    listItemActive:{
        backgroundColor:'#00468E'
    },
    speedDial: {
        position: 'fixed',
        zIndex:'10',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
});


class MainMenu extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            speedDialHidden:false
        };

        this.onClickMenuItem            = this.onClickMenuItem.bind(this);

        /* Speed Dial specific */
        this.handleSpeedDialItemClick   = this.handleSpeedDialItemClick.bind(this);
    }

    onClickMenuItem(item){
        if(!!item.linkTo){
            if(item.linkTo === Paths.LOGIN){
                this.props.handleLogout();
            }else {
                this.props.goTo(item.linkTo);
            }
        }
    }

    handleSpeedDialItemClick = (item) => {
        if(!!item.linkTo){
            if(item.linkTo === Paths.LOGIN){
                this.props.handleLogout();
            }else{
                this.props.goTo(item.linkTo);
            }
        }
    };

    handleDrawerToggle() {
        this.setState(state => ({
            mobileOpen:!state.mobileOpen
        }));
    }

    render() {

        const {classes,pathname,speedDialOpen,handleSpeedDialClose,handleSpeedDialOpen,is_hv,is_deactivated,edit_garantie_id} = this.props;
        const menuItemsTop = [
            {title:'Übersicht',icon:<OverviewIcon />,linkTo:Paths.HOME,adminOnly:false,activationRequire:false,disabled:false},
            {title:'Garantie anlegen',icon:<AddIcon />,linkTo:Paths.GARANTIE_ADD,adminOnly:false,activationRequire:true,disabled:false},
            {title:'Preislisten',icon:<EuroIcon />,linkTo:Paths.PREISLISTENPDFS,adminOnly:false,activationRequire:true,disabled:false},
            {title:'Garantiebedingungen',icon:<GuaranteeIcon />,linkTo:Paths.GARANTIEBEDINGUNGENPDFS,adminOnly:false,activationRequire:true,disabled:false},
            //{title:'Garantieeingabe fortsetzen',icon:<ContinueIcon />,linkTo:Paths.GARANTIE_ADD,adminOnly:false,activationRequire:true,disabled:edit_garantie_id === null},
            {title:'Garantieliste',icon:<GarantielisteIcon />,linkTo:Paths.GARANTIE_LISTE,adminOnly:false,activationRequire:false,disabled:false},
            {title:'Downloads',icon:<DownloadsIcon />,linkTo:Paths.DOWNLOADS,adminOnly:false,activationRequire:false,disabled:false},
            {title:'Allgemeine Infos',icon:<UrteileIcon />,prefixLinkTo:Paths.URTEIL,linkTo:Paths.URTEILE,adminOnly:false,activationRequire:false,disabled:false},
            {title:'Kontakt',icon:<ContactIcon />,linkTo:Paths.KONTAKT,adminOnly:false,activationRequire:false,disabled:false},
            {title:'Einstellungen',icon:<SettingsIcon />,linkTo:Paths.EINSTELLUNGEN,adminOnly:false,activationRequire:false,disabled:false},
            {title:'Händlerinformation',icon:<InfoIcon />,linkTo:Paths.INFO,adminOnly:true,activationRequire:false,disabled:false},
            {title:'Log-Out',icon:<LogoutIcon />,linkTo:Paths.LOGIN,adminOnly:false,activationRequire:false,disabled:false}
        ].filter((item,index) => (item.adminOnly === false || (is_hv))).filter((item,index) => (item.activationRequire === false || (!is_deactivated))).filter((item) => !item.disabled);

        const drawer = (
            <Box display="flex" style={{height:'100%'}} flexDirection="row" alignContent="space-between" flexWrap="wrap">
               <Box style={{paddingTop:'18px',width:'100%'}}>
                    <div style={{textAlign:'center'}}><img onClick={() => this.props.goTo(Paths.HOME)} style={{cursor:'pointer'}} src={ProgarantLogo} alt="Logo Pro-Garant"/></div>
                    <List style={{paddingTop:'50px'}}>
                        {menuItemsTop.map((item, index) => {
                            const isActive = (pathname === item.linkTo || (!!item.prefixLinkTo && (String(pathname).startsWith(item.prefixLinkTo,0))));
                            return (
                            <ListItem className={isActive ? classes.listItemActive : null} button onClick={(e) => this.onClickMenuItem(item)} key={index}>
                                {item.icon && <ListItemIcon className={classes.drawerIcon}>{item.icon}</ListItemIcon>}
                                <ListItemText primaryTypographyProps={{variant:'body2'}} primary={item.title} />
                            </ListItem>
                        )})}
                    </List>
                </Box>
                <Box style={{width:'100%'}}>
                    <a href="https://www.progarant.de/" style={{color:'#ffffff',paddingBottom:'1em',textDecoration:'none',textAlign:'center',display:'block'}} rel="noopener noreferrer" target="_blank" title="zur Webseite">zur Webseite</a>
                </Box>
            </Box>
        );


        return (
            <>
            <nav className={classes.drawer} aria-label="Hauptmenu">
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <Hidden smUp implementation="css">
                <SpeedDial
                    ariaLabel="SpeedDial"
                    className={classes.speedDial}
                    hidden={false}
                    icon={<SpeedDialIcon />}
                    onBlur={handleSpeedDialClose}
                    onClick={handleSpeedDialOpen}
                    onClose={handleSpeedDialClose}
                    FabProps={{
                        disableRipple:true
                    }}

                    open={speedDialOpen}
                >
                    {menuItemsTop.length && menuItemsTop.reverse().map(action => (
                        <SpeedDialAction
                            key={action.title}
                            icon={action.icon}
                            tooltipTitle={action.title}
                            tooltipOpen
                            onClick={() => this.handleSpeedDialItemClick(action)}
                        />
                    ))}
                </SpeedDial>
            </Hidden>
            </>
        );

    }
};


MainMenu.propTypes = {
    classes:PropTypes.object.isRequired,
    goTo:PropTypes.func.isRequired,
    pathname:PropTypes.string.isRequired,
    speedDialOpen:PropTypes.bool.isRequired,
    handleSpeedDialClose:PropTypes.func.isRequired,
    handleSpeedDialOpen:PropTypes.func.isRequired,
    handleLogout:PropTypes.func.isRequired,
    is_hv:PropTypes.bool.isRequired,
    is_deactivated:PropTypes.bool.isRequired,
    edit_garantie_id:PropTypes.number
};

function mapStateToProps(state) {
    const {speedDialOpen} = state.app;

    return {
        pathname: state.router.location.pathname,
        speedDialOpen:speedDialOpen,
        is_hv:state.app.auth.is_hv,
        edit_garantie_id:state.app.auth.edit_garantie_id,
        is_deactivated:state.app.auth.is_deactivated,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path)),
        handleSpeedDialOpen: () => dispatch(openSpeedDial()),
        handleSpeedDialClose: () => dispatch(closeSpeedDial()),
        handleLogout: () => dispatch(logout())
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(MainMenu));
