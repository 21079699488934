import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Logout(props) {
    return (
        <SvgIcon viewBox="0 0 22 20" {...props}>
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g  transform="translate(-15.000000, -1029.000000)" stroke="#FFFFFF" strokeWidth="1.5">
                    <g  transform="translate(16.000000, 1030.000000)">
                        <g >
                            <g  transform="translate(0.000000, 5.000000)">
                                <path d="M5.86206897,9.51724138 L5.86206897,-1.51724138"  strokeLinecap="square" transform="translate(5.862069, 4.000000) rotate(-90.000000) translate(-5.862069, -4.000000) "></path>
                                <polyline  strokeLinecap="round" strokeLinejoin="round" points="6.89655172 0.333333333 11.7241379 4.03302469 6.89655172 7.8"></polyline>
                            </g>
                            <path d="M3.44827586,1.33333333 C3.44827586,0.596953667 4.06581414,0 4.82758621,0 L18.6206897,0 C19.3824617,0 20,0.596953667 20,1.33333333 L20,16.6666667 C20,17.4030463 19.3824617,18 18.6206897,18 L4.82758621,18 C4.06581414,18 3.44827586,17.4030463 3.44827586,16.6666667"  strokeLinecap="round" strokeLinejoin="round"></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};