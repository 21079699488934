import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles'
import {Card,CardContent,CardActionArea} from '@material-ui/core';
import { motion, AnimatePresence } from "framer-motion"
import PkwIcon from "../../../Assets/Svg/Pkw";
import PkwElektroIcon from "../../../Assets/Svg/Pkw-elektro";
import ReisemobilIcon from "../../../Assets/Svg/Reisemobil";
import MotorradIcon from "../../../Assets/Svg/Motorrad";
import PropTypes from "prop-types";
import {push} from "connected-react-router";
import {hideFirstLoginWindow} from "../../../Reducers/App";
import {connect} from "react-redux";
import NewsLatest from "../News/NewsLatest";

const styles = theme => ({
    menuIcon:{
        backgroundColor:'#00468E',
        borderRadius:'100%',
        minHeight:`${theme.typography.round(86 /11.4)}em`,
        minWidth:`${theme.typography.round(86 /11)}em`,
        height:`${theme.typography.round(86 /11)}em`,
        width:`${theme.typography.round(86 /11)}em`,
        color:'#ffffff',
        margin:'0 auto',
        marginBottom:`${theme.typography.round(20 /11)}em`,
        '& svg':{
            width:'40px',
            height:'auto'
        }
    },
    cardContent:{
        padding:'30px 30px 40px 30px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalApprove: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow:'scroll'
    },
    paper: {
        backgroundColor:'#ffffff',
        width:'580px',
        maxWidth:'90%',
        padding:'68px 114px 60px 114px',
        borderRadius:'6px',
        position:'relative',
        '&:focus':{
            outline:'none'
        }
    },
    paperApproved: {
        backgroundColor:'#ffffff',
        width:'850px',
        maxWidth:'90%',
        padding:'68px 80px 60px 80px',
        borderRadius:'6px',
        position:'relative',
        '&:focus':{
            outline:'none'
        }
    },
    closemodal:{
        display:'block',
        borderRadius:'100%',
        backgroundColor:'#00468E',
        position:'absolute',
        width:'50px',
        border:0,
        height:'50px',
        zIndex:5,
        right:'-25px',
        top:'-25px',
        '&:focus':{
            outline:'none'
        },
        '&:before':{
            display:'block',
            height:'2px',
            width:'27px',
            backgroundColor:'#ffffff',
            content:"''",
            position:'absolute',
            top:'25px',
            left:'12px',
            transform:'rotate(-45deg)'
        },
        '&:after':{
            display:'block',
            height:'2px',
            width:'27px',
            backgroundColor:'#ffffff',
            content:"''",
            top:'25px',
            left:'12px',
            position:'absolute',
            transform:'rotate(45deg)'
        }
    }
});


class DownloadPdfKacheln extends React.PureComponent {

    render() {
        const {classes, downloadType} = this.props;

        const title = downloadType === 'preislisten' ? 'Preislisten' : 'Garantiebedingungen';

        const mainItems = [
            {title:'PKW - Verbrenner/Hybrid', value: "PKW", icon: <PkwIcon />, linkTo:'/garantiebundpreislistenDownloads/' + downloadType + '/PKW'},
            {title:'PKW - Elektro', value: "pkw-elektro", icon: <PkwElektroIcon />, linkTo:'/garantiebundpreislistenDownloads/' + downloadType + '/pkw-elektro'},
            {title:'Reisemobil', value: "Reisemobil", icon: <ReisemobilIcon />, linkTo:'/garantiebundpreislistenDownloads/' + downloadType + '/Reisemobil'},
            {title:'Motorrad', value: "Motorrad", icon: <MotorradIcon />, linkTo:'/garantiebundpreislistenDownloads/' + downloadType + '/Motorrad'}
        ];

        return (
            <Grid container spacing={5}>
                <Grid item xs={12} lg={8}>
                    <Box textAlign="center">
                        <Typography color="primary" style={{marginTop:20, marginBottom:25}} variant="h2">{title}</Typography>
                    </Box>
                    <Grid container spacing={5}>
                        <AnimatePresence>
                        {mainItems.map((item,index) => (
                            <Grid key={index} item xs={12} sm={6}>
                                <motion.div
                                    initial={{ opacity: 0,scale:.9,y:50 }}
                                    animate={{ opacity: 1,scale:1,y:0 }}
                                    exit={{ opacity: 0 }}
                                    key={index}
                                    transition={{delay: .1*(index+1) }}
                                >
                                    <Card>
                                        <CardActionArea onClick={() => this.props.goTo(item.linkTo)}>
                                            <CardContent className={classes.cardContent}>
                                                <Box textAlign="center">
                                                    {item.icon && <Box display="flex" justifyContent="center" alignItems="center" className={classes.menuIcon}>
                                                        {item.icon}
                                                    </Box>}
                                                    <Typography color="primary" style={{textOverflow:'ellipsis',overflow:'hidden'}} variant="subtitle1">
                                                        {item.title.split('\n').map((text, index) => (
                                                            <React.Fragment key={`${text}-${index}`}>
                                                                {text}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </motion.div>
                            </Grid>
                        ))}
                    </AnimatePresence>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <NewsLatest />
                </Grid>

            </Grid>
        );
    }
}


DownloadPdfKacheln.propTypes = {
    goTo:PropTypes.func.isRequired,
    downloadType: PropTypes.string.isRequired,
};

function mapStateToProps(state) {

    return { }
}

const mapDispatchToProps = dispatch => {
    return {
        goTo:(path) => dispatch(push(path)),
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(DownloadPdfKacheln));
