import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PkwIcon(props) {
    return (
        <SvgIcon viewBox="0 0 67 31" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)">
                    <path d="M3.71882353,24.4896471 L0.785558824,24.4896471 C0.365058824,24.4896471 0.0204705882,24.1450588 0.0204705882,23.7245588 L0.0204705882,16.4557941 C0.0204705882,16.4285 0.0221764706,16.4012059 0.0247352941,16.3747647 C0.0238823529,16.3167647 0.0204705882,16.2596176 0.0204705882,16.2007647 C0.0204705882,11.9045 3.50302941,8.42194118 7.79929412,8.42194118 C9.00876471,8.42194118 55.8531471,9.48129412 55.8531471,9.48129412 C57.9914706,9.68941176 59.8901176,10.7180588 61.2309412,12.2473824 L61.2317941,12.2473824 C61.4177353,12.4333235 61.6087941,12.6806765 61.7998529,12.9723824 C62.1060588,13.4099412 62.3679118,13.8799118 62.5845588,14.3737647 C63.6140588,16.4634706 64.5488824,19.2355294 64.8380294,20.1234412 C64.9054118,20.3298529 64.9557353,20.5405294 64.9881471,20.7554706 C65.0222647,20.9712647 65.0580882,21.2433529 65.0580882,21.4292941 C65.0580882,22.6745882 64.1949118,24.3054118 62.7125,24.4819706 C62.6604706,24.4879412 62.6075882,24.4896471 62.5564118,24.4896471 L58.4477941,24.4896471 L58.1074706,24.4256765" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <polyline stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" points="15.5780324 24.4896471 21.0624441 24.4896471 46.1414735 24.4896471"></polyline>
                    <path d="M46.6928147,22.7999706 C46.6928147,25.9865588 49.2763735,28.5701176 52.4638147,28.5701176 C55.6504029,28.5701176 58.2339618,25.9865588 58.2339618,22.7999706 C58.2339618,19.6133824 55.6504029,17.0298235 52.4638147,17.0298235 C49.2763735,17.0298235 46.6928147,19.6133824 46.6928147,22.7999706 Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M50.1804059,22.7999706 C50.1804059,24.0614706 51.2022294,25.0832941 52.4637294,25.0832941 C53.7243765,25.0832941 54.7470529,24.0614706 54.7470529,22.7999706 C54.7470529,21.5384706 53.7243765,20.5166471 52.4637294,20.5166471 C51.2022294,20.5166471 50.1804059,21.5384706 50.1804059,22.7999706 Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M3.78339118,22.7999706 C3.78339118,25.9865588 6.36695,28.5701176 9.55439118,28.5701176 C12.7409794,28.5701176 15.3236853,25.9865588 15.3236853,22.7999706 C15.3236853,19.6133824 12.7409794,17.0298235 9.55439118,17.0298235 C6.36695,17.0298235 3.78339118,19.6133824 3.78339118,22.7999706 Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M7.27098235,22.7999706 C7.27098235,24.0614706 8.29280588,25.0832941 9.55430588,25.0832941 C10.8149529,25.0832941 11.8376294,24.0614706 11.8376294,22.7999706 C11.8376294,21.5384706 10.8149529,20.5166471 9.55430588,20.5166471 C8.29280588,20.5166471 7.27098235,21.5384706 7.27098235,22.7999706 Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M43.8258235,4.44467647 C39.8860882,2.41126471 36.1092647,0.197029412 31.9017059,0.133058824 C20.7435294,-0.0358235294 15.2599706,-1.20605882 7.99035294,8.35797059" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M51.7946824,9.44197353 C51.7946824,9.44197353 49.8713,7.93397353 46.8843,6.14876765"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M21.5719059,0.260402941 C21.5719059,0.260402941 16.2162882,20.0264618 27.0554647,24.4899029"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M43.8883441,9.31454412 C43.8883441,9.31454412 46.31155,19.0065147 42.6131971,24.4261029"  stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M24.7662559,13.0123853 L28.3247265,13.0123853 C28.7486382,13.0123853 29.0957853,12.6652382 29.0957853,12.2413265 L29.0957853,12.1261794 C29.0957853,11.7014147 28.7486382,11.3551206 28.3247265,11.3551206 L24.7662559,11.3551206 C24.3423441,11.3551206 23.9951971,11.7014147 23.9951971,12.1261794 L23.9951971,12.2413265 C23.9951971,12.6652382 24.3423441,13.0123853 24.7662559,13.0123853" fill="#FFFFFF"></path>
                    <path d="M0.126320588,14.9261294 C0.560467647,12.2913941 2.31752647,10.1044529 4.69296765,9.06812941 C4.92667353,9.59183529 5.05802647,10.1709824 5.05802647,10.7808353 C5.05802647,13.1051 3.17387941,14.9892471 0.849614706,14.9892471 C0.603114706,14.9892471 0.360879412,14.9662176 0.126320588,14.9261294 Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                    <path d="M61.2311971,12.2478088 L61.23205,12.2478088 C61.4179912,12.43375 61.6081971,12.68025 61.8001088,12.9728088 C62.1054618,13.4103676 62.3681676,13.8794853 62.5848147,14.3733382 C62.7750206,14.7597206 62.9618147,15.1699853 63.1426382,15.5870735 C62.9549912,15.6126618 62.7647853,15.6263088 62.5703147,15.6263088 C60.2469029,15.6263088 58.3627559,13.7421618 58.3627559,11.41875 C58.3627559,11.0016618 58.4241676,10.5999265 58.5367559,10.2195147 C59.5705206,10.7048382 60.4865794,11.3991324 61.2311971,12.2478088 Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round"></path>
                </g>
            </g>
        </SvgIcon>
    );
}
